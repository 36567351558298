import { useSelector, useDispatch } from 'react-redux';

const selectConfiguration = (state) => ({
  parameters: state.configuration.parameters,
  loading: state.configuration.loading,
  error: state.configuration.error
});

const useConfiguration = () => {
  const dispatch = useDispatch();
  // ATTENTION : laisser l'argument de useSelector sous forme de fonction nommée !
  // (pour que la référence reçue par useSelector ne change pas à chaque fois avec une fonction anonyme)
  return useSelector(selectConfiguration);
};

export { useConfiguration };
