import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import {
  StyledH1VuesEC,
  StyledP,
} from "../../styledComponents/StyledTextEspaceClient";
import {
  StyledDivWrapperVues,
  StyledHrEspaceClient,
} from "../../styledComponents/StyledDivEspaceClient";
import {
  StyledDivMsgEchec,
  StyledDivMsgSucces,
} from "../../styledComponents/StyledDivBackoffice";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "../../components/ErrorBoundary";
import {
  getAllContacts,
  getAllFamilleArticle,
  getAllOffresEC,
  getOneContact,
  getOneOffreIdEC,
} from "../../../slice/EspaceClientReducer";
import { ListeOffre } from "./ListeOffre";
import { DetailsOffre } from "./DetailsOffre";
import { TooltipEC } from "../../components/espaceClient/TooltipEC";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { getAllSecteurs } from "../../../slice/SecteurReducer";
import { getAllFonctions } from "../../../slice/FonctionReducer";
import { getAllPays } from "../../../slice/PaysReducer";
import { PersonIcon } from "../../components/espaceClient/PersonIcon";

export const VueOffre = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { clientId } = useAuthentification();

  const { message } = useSelector((state) => state.message);

  //SERVICES
  const [isOffreByIdSet, setIsOffreByIdSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue") || "all"
  );

  const {
    offreById,
    familleArticleList,
    detailsContact,
    contactList,
  } = useSelector((state) => state.espaceClient);
  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect(() => {
    const offrePrixId = params.get("offre");

    if (!offrePrixId) {
      setIsOffreByIdSet(false);
      return;
    }

    const contactId =
      localStorage.getItem("selectedValue") !== "all"
        ? localStorage.getItem("selectedValue")
        : "%25";

    dispatch(getOneOffreIdEC({ clientId, offrePrixId }));
    dispatch(getAllFamilleArticle({ clientId }));
    dispatch(getOneContact({ clientId, contactId }));

    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());
  }, [dispatch, params, selectedValue]);

  useEffect(() => {
    if (clientId !== null) {
      const contactId =
        localStorage.getItem("selectedValue") !== "all"
          ? localStorage.getItem("selectedValue")
          : "%25";

      dispatch(getAllContacts( { clientId }));
      dispatch(getOneContact({ clientId, contactId }));
    }
  }, [dispatch, selectedValue]);

  useEffect(() => {
    if (!offreById) {
      setIsOffreByIdSet(false);
      return;
    }
    setIsOffreByIdSet(true);
  }, [offreById]);

  useEffect(() => {
    if (clientId) {
      const contactId =
        localStorage.getItem("selectedValue") &&
        localStorage.getItem("selectedValue") !== "all"
          ? localStorage.getItem("selectedValue")
          : "%25";
      const obj = { clientId, contactId };

      dispatch(getAllOffresEC(obj));
    }
  }, [dispatch, selectedValue]);

  useEffect(() => {
    const paramOffre = params.get("offre");

    if (!paramOffre) {
      setIsOffreByIdSet(false);
    }
  }, []);

  // Fonction de gestion du changement de sélection
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem("selectedValue", event.target.value);
  };

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    offreById,
    isOffreByIdSet,
    familleArticleList,
    secteursList,
    fonctionsList,
    paysList,
    detailsContact,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsOffreByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isOffreByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarOffres}</StyledH1VuesEC>
      <StyledHrEspaceClient></StyledHrEspaceClient>
      <StyledP>{t.descrVueOffre}</StyledP>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <FormControl style={{ marginBottom: "0.875rem", width: "300px" }}>
          <InputLabel id="avatar-select-label">{t.jeSuis}</InputLabel>
          <Select
            labelId="avatar-select-label"
            id="avatar-select"
            label={t.jeSuis}
            value={selectedValue}
            onChange={handleSelectChange}
            startAdornment={<PersonIcon />}
          >
            <MenuItem value="all">{t.toutLeMonde}</MenuItem>
            {contactList
              ? contactList.map((contact, index) => (
                  <MenuItem key={index} value={contact.contactId}>
                    {contact.contact}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </div>

      <div>
        {displayError()}
        <ListeOffre
          state={stateUsedForRender}
          actions={actionUsedForRender}
          style={{
            boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
          }}
        />
        <TooltipEC texte={t.infoBulleOffre} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <DetailsOffre
          t={t}
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </ErrorBoundary>
  );
};
