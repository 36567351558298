import { useSelector, useDispatch } from "react-redux";
import { setLangAsync } from "../../../slice/i18nReducer";

const selectI18n = (state) => ({
  translations: state.i18n.translations,
  lang: state.i18n.lang,
  supportedLangs: state.i18n.supportedLangs,
  error: state.i18n.error,
  loading: state.i18n.loading
});

export const useTranslations = () => {
  const dispatch = useDispatch();
  // ATTENTION : laisser l'argument de useSelector sous forme de fonction nommée !
  // (pour que la référence reçue par useSelector ne change pas à chaque fois avec une fonction anonyme)
  const { translations, lang, supportedLangs, error, loading} = useSelector(selectI18n);
  const setLang = (lang) => dispatch(setLangAsync(lang));
  return {
    t: translations,
    lang,
    supportedLangs,
    error,
    loading,
    setLang,
  };
};

export const translationsSpec = (string, t) => t[string];
