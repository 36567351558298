import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { ChildFormikPropsActionType } from "../../constantes/actionType/ChildFormikProps";

export const setIsChildFormikValid = createAsyncThunk(
  ChildFormikPropsActionType.IS_CHILD_FORMIK_VALID,
  async (payload, thunkAPI) => {
    try {
      return payload;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const setChildFormikValuesWhenValidate = createAsyncThunk(
  ChildFormikPropsActionType.SET_CHILD_FORMIK_VALUES_WHEN_VALIDATE,
  async (payload, thunkAPI) => {
    try {
      return payload;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  isChildFormikValid: true,
  formikValuesGetByMenuClick: {},
  reference: "",
};

const childFormikPropsSlice = createSlice({
  name: ChildFormikPropsActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setIsChildFormikValid.fulfilled, (state, action) => {
        state.isChildFormikValid = action.payload;
      })
      .addCase(setChildFormikValuesWhenValidate.fulfilled, (state, action) => {
        state.formikValuesGetByMenuClick = action.payload;
        state.reference = action.payload;
      });
  },
});

const { reducer } = childFormikPropsSlice;
export default reducer;
