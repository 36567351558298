export const AuthentificationActionType = Object.freeze({
    END_POINT: 'authentification',
    CONNEXION: 'authentification/connexion',
    RAFRAICHISSEMENT: 'authentification/rafraichissement',
    DECONNEXION: 'authentification/deconnexion',
    CONNEXION_EC: 'authentification/connexionEC',
    RAFRAICHISSEMENT_EC: 'authentification/rafraichissementEC',
    DECONNEXION_EC: 'authentification/deconnexionEC',
    UPDATE_TOKEN_EC: 'authentification/updateTokenEC'
})
