/*
 * L'API renvoie pour certaines requêtes un résultat JSON encodé en base 64.
 * Cette fonction décode l'objet est effectue des transformations sur certaines propriétés.
 *
 * La fonction transforme les propriétés dont la valeur est un objet (pas un tableau)
 * et dont le nom n'est pas «horsConfigurateur» et «affichage».
 *
 * Pour ces entrées, elle recherche la première propriété dont la valeur est une chaine non vide.
 *
 * Exemple n°1 : pour l'entrée suivante, on récupérera la valeur "0" :
 *       { "valueHeure": "", "valueNum": "0" } ==> "0"
 * Exemple n°2 : pour l'entrée suivante, on récupérera la valeur "Verticale" :
 *       { "valueHeure": "Verticale", "valueNum": "0" } => "Verticale"
 */
export const serviceCleaner = (service) => {
  if(service) {
    service = JSON.parse(atob(service));
    return Object.fromEntries(
      Object.entries(service).map(([cle, valeur]) => {
        // pour les entrées «horsConfigurateur» et «affichage»,
        // renvoyer l'entrée sans changement
        if (cle === "horsConfigurateur" || cle === "affichage") {
          return [cle, valeur];
        }
        // pour les objets qui ne sont pas des tableaux,
        // renvoyer la première valeur de propriété qui n'est pas vide
        if (typeof valeur === "object" && !Array.isArray(valeur)) {
          const res = [
            cle,
            Object.entries(valeur).find(
              (e) => e[1].length !== 0 && e[1] !== ""
            )[1]
          ];
          return res;
        }
        // Pour les autres cas : :
        // - type string
        // - tableau contenant des objets
        // - tableau vide
        // renvoyer l'entrée sans changement
        return [cle, valeur];
      })
    );
  }
};

export const langForService = (ev, thunkAPI) => {
  // FIXME langForService à simplifier
  // lorsqu'on sélectionne une autre langue avec le langSwitcher,
  // le paramètre «ev» est un évenement click avec son target qui vaut «Object { value: "FR", name: undefined }»
  return ev?.target?.value || thunkAPI.getState().i18n.lang;
};

export const langJsonFormatter = (lang, thunkAPI, reponse) => {
  const resolvedLang = langForService(lang, thunkAPI);
  const data = reponse.data
    .sort((curr, next) => {
      return curr[resolvedLang].localeCompare(next[resolvedLang]);
    })
    .map((element) => {
      return {
        code: element.IDSylob,
        reference: element.cle,
        name: element[resolvedLang]?element[resolvedLang]:element['FR'],
      };
    });
  return data;
};
