import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

export const CommandeIcon = ({ styles, activePage }) => {

  const currentColor = activePage === ClientRoutes.VUE_COMMANDE ? "#EC8812" : "#2a375c";

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M109.995 126.006c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z"
          fill={currentColor}
          opacity="1"

        ></path>
        <path
          d="m470.925 211.53-14.469-28.939c-5.116-10.232-15.397-16.587-26.833-16.587h-83.637V96.007c0-16.541-13.457-29.999-29.999-29.999H29.999C13.457 66.008 0 79.465 0 96.007v259.989c0 16.541 13.457 29.999 29.999 29.999h29.999c0 15.354 5.805 29.375 15.326 39.998H10c-5.523 0-10 4.478-10 10s4.477 10 10 10h491.98c5.523 0 10-4.478 10-10s-4.477-10-10-10h-25.326c9.522-10.624 15.326-24.644 15.326-39.998 0-.57-.027-1.137-.043-1.706 11.661-4.116 20.042-15.241 20.042-28.293v-55.278c0-11.669 2.311-.775-41.054-89.188zm-15.124 14.471 29.999 59.998h-99.816v-59.998h69.817zM19.999 305.998h19.999v19.999H19.999v-19.999zm99.996 119.995c-22.055 0-39.998-17.944-39.998-39.998 0-2.724.27-5.405.81-8.012 3.761-18.534 20.242-31.987 39.188-31.987s35.428 13.452 39.188 31.987l.009.042c.532 2.564.801 5.246.801 7.97 0 22.055-17.943 39.998-39.998 39.998zm0-99.996c-25.621 0-48.232 16.413-56.568 39.998H29.999c-5.514 0-10-4.486-10-10v-10h29.999c5.523 0 10-4.478 10-10v-39.998c0-5.522-4.477-10-10-10H19.999V96.007c0-5.514 4.486-10 10-10h285.988c5.514 0 10 4.486 10 10v269.989H176.558c-8.351-23.598-30.953-39.999-56.563-39.999zm44.671 99.996c9.522-10.624 15.326-24.644 15.326-39.998h191.992c0 15.354 5.805 29.375 15.326 39.998H164.666zm267.316 0c-22.055 0-39.998-17.944-39.998-39.998 0-2.724.27-5.405.81-8.012 3.761-18.534 20.242-31.987 39.188-31.987s35.428 13.452 39.188 31.987l.009.042c.532 2.564.801 5.246.801 7.97.001 22.055-17.943 39.998-39.998 39.998zm59.998-69.997c0 3.326-1.639 6.269-4.144 8.089-8.871-22.569-30.941-38.087-55.854-38.087-25.621 0-48.232 16.413-56.568 39.998h-29.429V186.003h83.637a9.949 9.949 0 0 1 8.945 5.531l7.234 14.468h-69.816c-5.523 0-10 4.478-10 10v79.997c0 5.522 4.477 10 10 10H491.98v49.997z"
          fill={currentColor}
          opacity="1"

        ></path>
        <path
          d="M275.989 126.006H149.994c-5.523 0-10 4.478-10 10s4.477 10 10 10h125.995c5.523 0 10-4.478 10-10s-4.478-10-10-10zM275.989 166.004h-85.997c-5.523 0-10 4.478-10 10s4.477 10 10 10h85.997c5.523 0 10-4.478 10-10s-4.478-10-10-10zM431.982 365.996c-11.028 0-19.999 8.972-19.999 19.999 0 11.028 8.972 19.999 19.999 19.999 11.028 0 19.999-8.972 19.999-19.999 0-11.028-8.971-19.999-19.999-19.999zM119.995 365.996c-11.028 0-19.999 8.972-19.999 19.999 0 11.028 8.972 19.999 19.999 19.999 11.028 0 19.999-8.972 19.999-19.999 0-11.028-8.971-19.999-19.999-19.999z"
          fill={currentColor}
          opacity="1"

        ></path>
      </g>
    </svg>
  );
};
