import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";

import { useTranslations } from '../components/hook/useTranslations.js';
import { useAuthentification } from '../../../redux/vue/components/hook/useAuthentification.js';
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import { GuestRoutes } from "../../../constantes/routes/GuestRoutes";
import {
  StyledContentActus,
  StyledContentCard,
  StyledDivBoutonCialProximite,
  StyledDivTitleCard,
  StyledDivTitleCardReverse,
  StyledDivTitleSubCard,
  StyledH1VuesEC,
  StyledH2VuesEC,
  StyledTitleActus,
} from "../styledComponents/StyledTextEspaceClient";
import {
  getAllArticlesEC,
  getAllCommandesEC,
  getAllContacts,
  getAllOffresEC,
  getDetailsClient,
  getOneContact
} from "../../slice/EspaceClientReducer";
import { importAll, importAllSpec } from "../../../helper/ImportAll";
import {
  StyledDivCardWrapper,
  StyledDivCardWrapperRow,
  StyledHrEspaceClient,
} from "../styledComponents/StyledDivEspaceClient";
import { ServiceIconCard } from "../components/espaceClient/ServiceIconCard";
import { IconContactCard } from "../components/espaceClient/IconContactCard";
import { ClientRoutes } from "../../../constantes/routes/ClientRoutes";
import { SeeMoreIconEC } from "../components/SeeMoreIconEC";
import { BoutonAccueilEC, BoutonCialProx, BoutonWithoutArrow, } from "../components/espaceClient/BoutonAccueilEC";
import { LocalStorage } from "../../../constantes/globalName/LocalStorage";
import { FONCTION, NOM, PAYS, PRENOM, SECTEUR, SOCIETE, } from "../enum/champs/ChampsCommunFormulairesEnum";
import { ADRESSE_MAIL, CODE_POSTAL, TELEPHONE, } from "../../../constantes/symbols/SymbolsCommon";
import { PersonIcon } from "../components/espaceClient/PersonIcon";
import { getAllSecteurs } from "../../slice/SecteurReducer";
import { getAllFonctions } from "../../slice/FonctionReducer";
import { getAllPays } from "../../slice/PaysReducer";
import { StyledButtonContinuer } from "../styledComponents/StyledButton";
import { StyledCheckRoundedIcon } from "../styledComponents/StyledIcon";
import { StyledCardContainer } from "../styledComponents/StyledCardContainer";
import CloseIcon from "@mui/icons-material/Close";
import { AccueilMobileEC } from "./AccueilMobileEC";
import { EC_COLORS } from "./common/EcColor";
import CommandeCard from '../components/espaceClient/CommandeCard';
import OffreCard from '../components/espaceClient/OffreCard';
import ArticleCard from '../components/espaceClient/ArticleCard';
import NcCard from '../components/espaceClient/NcCard';
import CommercialCard from '../components/espaceClient/CommercialCard';

const LIMITE_LARGEUR_ECRAN_MOBILE = 1366;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};


export const AccueilEC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const { clientId } = useAuthentification();
  const query = useQuery();
  const dispatch = useDispatch();

  const setIdentite = () => {
    const url = window.location.href;
    const regex = new RegExp("moovp*");
    const site = regex.test(url);
    // TODO marque blanche : titre codé en dur
    const title = site === true ? "Moovprotect" : "RC Modèles";
    document.title = title + " | Demande de prix";
    return site;
  };

  const [booleanIdentite, setBooleanIdentite] = useState(setIdentite());
  const [upToDateArticlesList, setUpToDateArticlesList] = useState([]);
  const [upToDateCommandesList, setUpToDateCommandesList] = useState([]);
  const [upToDateOffresList, setUpToDateOffresList] = useState([]);
  const [upToDateClientDetails, setUpToDateClientDetails] = useState({});

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openWaitingModal, setOpenWaitingModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultTitle, setResultTitle] = useState("");
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [messageToConfirm, setMessageToConfirm] = useState("");
  const [prenomContact, setPrenomContact] = useState("");
  const [raisonSociale, setRaisonSociale] = useState("");
  const [isMobileFormatAccueil, setIsMobileFormatAccueil] = useState(
    window.innerWidth < LIMITE_LARGEUR_ECRAN_MOBILE
  );

  const [imageSrc, setImageSrc] = useState("");

  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue") || "all"
  );

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const {
    articleList,
    commandeList,
    offreList,
    detailsClient,
    contactList,
    detailsContact,
  } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    setUpToDateReferenceByIdList,
    upToDateReferenceByIdList,
    setSelectedPage,
  ] = useOutletContext();

  useEffect(() => {
    if (clientId === null)
      return;
    const contactId =
      localStorage.getItem("selectedValue") !== "all"
        ? localStorage.getItem("selectedValue")
        : "%25";

    dispatch(getAllSecteurs());
    dispatch(getAllFonctions());
    dispatch(getAllPays());

    dispatch(getAllArticlesEC({ clientId }));
    dispatch(getAllCommandesEC({ clientId, contactId }));
    dispatch(getAllOffresEC({ clientId, contactId }));
    dispatch(getDetailsClient({ clientId }));
    dispatch(getAllContacts({ clientId }));
    dispatch(getOneContact({ clientId, contactId }));

  }, [dispatch, selectedValue]);

  useEffect(() => {
    if (articleList && articleList.length > 0) {
      setUpToDateArticlesList(articleList.slice(0, 3)); // Prendre les 3 premiers articles
    }
  }, [articleList]);

  useEffect(() => {
    if (commandeList && commandeList.length > 0) {
      setUpToDateCommandesList(commandeList.slice(0, 2)); // Prendre les 2 premières commandes
    }
  }, [commandeList]);

  useEffect(() => {
    if (offreList && offreList.length > 0) {
      setUpToDateOffresList(offreList.slice(0, 2)); // Prendre les 2 premières offres
    }
  }, [offreList]);

  useEffect(() => {
    if (detailsClient) {
      setUpToDateClientDetails(detailsClient);
    }
  }, [detailsClient]);

  useEffect(() => {
    if (contactList) {
      const contact = contactList.find(
        (item) => item.contactId === selectedValue
      );
      const raisonSociale = contactList.find(
        (item) => item.clientRaisonSociale
      );

      if (contact && contact.prenom !== "" && selectedValue !== "all") {
        setPrenomContact(contact.prenom + ",");
      } else {
        setPrenomContact("");
      }

      if (raisonSociale) {
        setRaisonSociale(contactList[ 0 ].clientRaisonSociale);
      } else {
        setRaisonSociale("");
      }
    }
  }, [contactList, selectedValue]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileFormatAccueil(window.innerWidth < LIMITE_LARGEUR_ECRAN_MOBILE);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fonction de gestion du changement de sélection
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem("selectedValue", event.target.value);
  };

  const handleConfigurer = () => {
    if (detailsContact[ 0 ] && selectedValue !== "all") {
      // Fonction pour mapper sur fonctionList et rechercher la valeur correspondante
      const fonction = fonctionsList.find(
        (item) => item.name === detailsContact[ 0 ].fonction
      );

      const secteur = secteursList.find(
        (item) =>
          item.reference === detailsContact[ "infosClient" ].codeSecteurActivite
      );

      const pays = paysList.find(
        (item) => item.reference === detailsContact[ "infosClient" ].codePays
      );

      localStorage.setItem(
        LocalStorage.INFORMATIONS_CLIENT,
        btoa(
          JSON.stringify({
            [ SOCIETE.description ]: detailsContact[ "infosClient" ].raisonSociale,
            [ PAYS.description ]: pays,
            [ CODE_POSTAL.description ]: detailsContact[ "infosClient" ].codePostal,
            [ SECTEUR.description ]: secteur,
            [ NOM.description ]: detailsContact[ 0 ].nom,
            [ PRENOM.description ]: detailsContact[ 0 ].prenom,
            [ FONCTION.description ]: fonction,
            [ ADRESSE_MAIL.description ]: detailsContact[ 0 ].email,
            [ TELEPHONE.description ]: detailsContact[ 0 ].telephone,
          })
        )
      );
    }

    const url = StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR);
    window.open(url, "_blank");
  };


  useEffect(() => {
    if (
      detailsClient &&
      detailsClient.commercialPrenom &&
      detailsClient.commercialNom
    ) {
      const prenom = detailsClient.commercialPrenom;
      const nom = detailsClient.commercialNom;

      // On prend les initiales du prénom et du nom pour générer le nom de fichier
      const initials = prenom.charAt(0) + nom.charAt(0);

      // Générer le nom de l'image en fonction du format (Desktop ou Mobile)
      const format = window.innerWidth > 768 ? "Desktop" : "Mobile";
      const imageName = `${initials}_${format}.png`;

      // Importer toutes les images
      const images = importAll();

      // Vérifier si l'image spécifique existe, sinon utiliser l'image par défaut
      if (images[ imageName ]) {
        setImageSrc(images[ imageName ]);
      } else {
        const defaultImage = `DEFAULT_${format}.png`;
        setImageSrc(images[ defaultImage ]);
      }
    }
  }, [detailsClient, window.innerWidth]);


  const handleEquipe = () => {
    navigate(ClientRoutes.VUE_CONTACT);
  };


  const afficherBouton = (texte, action, backgroundColor) => {
    return (
      <BoutonAccueilEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const afficherBoutonWithoutArrow = (texte, action, isFirstButton) => {
    return (
      <BoutonCialProx
        texte={texte}
        action={action}
        isFirstButton={isFirstButton}
      />
    );
  };

  return (
    <div style={{ padding: "16px" }}>
      <div>
        <StyledH1VuesEC>
          {prenomContact} {t.genH1BienvenueEspaceClient}
        </StyledH1VuesEC>
        <StyledHrEspaceClient></StyledHrEspaceClient>
      </div>
      {/* raison sociale placée a droite de la page */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledH2VuesEC>{raisonSociale}</StyledH2VuesEC>
      </div>

      {isMobileFormatAccueil ? (
        <AccueilMobileEC
          t={t}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <StyledCardContainer
            id="card-container"
            className={styles.cardContainer}
            style={{
              flex: 1,
              display: "flex",
              gap: "1.5rem",
              flexWrap: "wrap",
            }}
          >
            <StyledDivCardWrapper>
              <CommercialCard styles={styles} detailsClient={detailsClient} detailsContact={detailsContact}
                imageSrc={imageSrc}
                setOpenWaitingModal={setOpenWaitingModal}
                setResultMessage={setResultMessage}
                setResultTitle={setResultTitle}
                setOpenResultModal={setOpenResultModal}
                setActionToConfirm={setActionToConfirm}
                setMessageToConfirm={setMessageToConfirm}
                setOpenConfirmationModal={setOpenConfirmationModal}
                isMobile={false}
              />

              <CommandeCard styles={styles} upToDateCommandesList={upToDateCommandesList} isMobile={false}/>

              <OffreCard styles={styles} upToDateOffresList={upToDateOffresList} isMobile={false}/>

            </StyledDivCardWrapper>

            <StyledDivCardWrapper style={{ flex: "1.75" }}>
              <StyledDivCardWrapperRow>
                <Card
                  id="publicite-card"
                  className={styles.publiciteCard}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1.5,
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    background:
                      "linear-gradient(270deg, rgba(42, 55, 92, 0.20) 0.01%, #2A375C 93.17%), url(" +
                      importAllSpec(false)[ "LandingPageImage.jpg" ] +
                      ") lightgray 50% / cover no-repeat",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: "none", padding: "24px" }}>
                    <StyledDivTitleCardReverse>
                      {t.genTitleCardActus}
                    </StyledDivTitleCardReverse>

                    <StyledTitleActus>{t.ESPACE_CLIENT_PUB_2}</StyledTitleActus>

                    <StyledContentActus>
                      {t.ESPACE_CLIENT_PUB_3}
                    </StyledContentActus>
                  </CardContent>
                </Card>
                <Card
                  id="whoIam-card"
                  className={styles.whoIamCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    border: "#E7EAFF solid",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <StyledDivTitleCard>{t.jePersonnalise}</StyledDivTitleCard>

                    <FormControl fullWidth style={{ marginBottom: "0.875rem" }}>
                      <InputLabel id="avatar-select-label">
                        {t.jeSuis}
                      </InputLabel>
                      <Select
                        labelId="avatar-select-label"
                        id="avatar-select"
                        label={t.jeSuis}
                        value={selectedValue}
                        onChange={handleSelectChange}
                        startAdornment={<PersonIcon/>}
                      >
                        <MenuItem value="all">{t.toutLeMonde}</MenuItem>
                        {contactList
                          ? contactList.map((contact, index) => (
                            <MenuItem key={index} value={contact.contactId}>
                              {contact.contact}
                            </MenuItem>
                          ))
                          : null}
                      </Select>
                    </FormControl>

                    <StyledContentCard>
                      ... {t.descrJePersonnalise}
                    </StyledContentCard>
                  </CardContent>
                </Card>
              </StyledDivCardWrapperRow>

              <ArticleCard styles={styles} upToDateArticlesList={upToDateArticlesList}/>

              <StyledDivCardWrapperRow className={styles.cardContainer}>

                <NcCard styles={styles}/>

                <Card
                  id="contact-card"
                  className={styles.equipeCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <div
                      style={{
                        height: 0,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <IconContactCard/>
                    </div>
                    <StyledDivTitleCard>
                      {t.genTitleCardEquipe}
                    </StyledDivTitleCard>
                    <StyledContentCard>{t.genDescrEquipe}</StyledContentCard>
                    {afficherBouton(t.genAffEquipe, handleEquipe)}
                  </CardContent>
                </Card>
                <Card
                  id="service-card"
                  className={styles.serviceCard}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent style={{ flex: 1, padding: "24px" }}>
                    <div
                      style={{
                        height: 0,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <ServiceIconCard/>
                    </div>
                    <StyledDivTitleCard>
                      {t.genTitleCardService}
                    </StyledDivTitleCard>
                    <StyledContentCard>{t.genDescrServices}</StyledContentCard>
                  </CardContent>
                </Card>
              </StyledDivCardWrapperRow>
              <Card
                id="configurateur-card"
                className={styles.jeConfigureCard}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
                  backgroundColor: EC_COLORS.DEEP_BLUE,
                  flexWrap: "wrap",
                  borderRadius: "8px",
                }}
              >
                <img
                  className={styles.mobileImageConfigure}
                  src={importAll()[ "RCModeles-Configurateur.png" ]}
                  alt="RC Modèles Configurateur"
                />
                <CardContent
                  style={{ flex: "1", minWidth: "300px", padding: "24px" }}
                >
                  <StyledDivTitleCardReverse>
                    {t.genTitleJeConfigure}
                  </StyledDivTitleCardReverse>

                  <StyledContentActus
                    style={{ fontSize: "1.25rem", color: "white" }}
                  >
                    {t.genDescrJeConfigure}
                  </StyledContentActus>

                  {afficherBouton(
                    t.genAffJeConfigure,
                    handleConfigurer,
                    "dark"
                  )}
                </CardContent>
              </Card>
            </StyledDivCardWrapper>
          </StyledCardContainer>
        </div>
      )}

      {/* Modale de confirmation d'envoi de mail */}
      <Modal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box sx={style}>
          <StyledDivTitleCard
            id="confirm-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {t.descrConfirmationEnvoi}
          </StyledDivTitleCard>
          <StyledContentCard
            id="confirm-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            {messageToConfirm}
          </StyledContentCard>
          <Box mt={3} display="flex" justifyContent="space-between">
            <IconButton
              onClick={() => setOpenConfirmationModal(false)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon/>
            </IconButton>

            <BoutonWithoutArrow
              texte={"Annuler"}
              action={() => setOpenConfirmationModal(false)}
            />
            <StyledButtonContinuer
              variant="contained"
              style={{ backgroundColor: EC_COLORS.DARK_BLUE }}
              onClick={() => {
                if (actionToConfirm) actionToConfirm(); // Appelle la méthode stockée
                setOpenConfirmationModal(false);
              }}
            >
              {t.comfirmer}
            </StyledButtonContinuer>
          </Box>
        </Box>
      </Modal>
      {/* Modale d'attente */}
      <Modal
        open={openWaitingModal}
        onClose={() => setOpenWaitingModal(false)}
        aria-labelledby="waiting-modal-title"
        aria-describedby="waiting-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={() => setOpenWaitingModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
          <StyledDivTitleCard
            id="waiting-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            {t.envoiEnCours}...
          </StyledDivTitleCard>
          <Typography
            id="waiting-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            <CircularProgress/>
          </Typography>
        </Box>
      </Modal>

      {/* Modale de résultat */}
      <Modal
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        aria-labelledby="result-modal-title"
        aria-describedby="result-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={() => setOpenResultModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>

          <StyledDivTitleCard
            id="waiting-modal-title"
            variant="h6"
            component="h2"
          >
            {resultTitle}
          </StyledDivTitleCard>
          <StyledContentCard id="result-modal-description">
            <div style={{ textAlign: "center", marginBottom: "1.25rem" }}>
              <StyledCheckRoundedIcon
                sx={{
                  backgroundColor: EC_COLORS.DEEP_GREEN,
                  borderRadius: "50%",
                  width: "5.375rem",
                  height: "5.375rem",
                }}
              />
            </div>
            {resultMessage}
          </StyledContentCard>
        </Box>
      </Modal>
    </div>
  );
};
