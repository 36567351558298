export const EspaceClient = Object.freeze({
    GET_ALL_ARTICLES:        "json/getArticlesEC/",
    GET_ALL_COMMANDES:       "json/getCommandesEC/",
    GET_ALL_OFFRES:          "json/getOffresEC/",
    GET_ALL_FAMILLE_ARTICLE: "json/getAllFamilleArticlesEC/",
    GET_ALL_TYPES_COMMANDE:  "json/getAllTypesCommandesEC/",
    GET_ALL_CLIENTS:         "json/getContactsEC/",
    GET_DETAILS_CLIENT:      "json/getContactsEC/",
    GET_ALL_NCL:             "json/getNCEC/",
    GET_ETAT_NC:             "json/getAllEtatNCEC/",
    SEND_MAIL_EC:            "json/sendMailEC/",
    SEND_STATS_TO_FPS:       "json/sendStatsToFPS/",
  });

