import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { StyledTextfieldOrange } from "../styledComponents/StyledTextfieldBackoffice";
import { StyledDivChampsModifierParent } from "../styledComponents/StyledDivBackoffice";
import { StyledDivChampsModifier } from "../styledComponents/StyledGridBackoffice";
import { genererFormulaire } from "./generateFormulary/CasesGeneration";
import { formulaireRole } from "../../../constantes/champsFormulaires/FormAjouterUtilisateur";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllRole } from "../../slice/UtilisateurReducer";
import { anchorCheck } from "../../../helper/Anchors";

export const Formulaire = ({
  handleInteractionWithBdd,
  initialValues,
  formType,
  schemaDeValidation,
  outletContext: { styles, t },
  from = null,
}) => {
  const dispatch = useDispatch();
  const { roleList } = useSelector((state) => state.utilisateur);
  const [utdRoleList, setUtdRoleList] = useState();
  const [user, setUser] = useState(initialValues);

  useEffect(() => {
    if (!from) {
      return;
    }
    dispatch(getAllRole());
  }, [dispatch]);

  useEffect(() => {
    if (!roleList || !from) {
      return;
    }
    setUtdRoleList(roleList);
    setUser(
      Object.assign({}, user, {
        role: roleList.find((el) => el.name === user.label)?.code,
      })
    );
  }, [roleList]);

  const formik = useFormik({
    initialValues: { ...user },
    validationSchema: schemaDeValidation(t, roleList),
    enableReinitialize: true,
    onSubmit: handleInteractionWithBdd,
  });

  useEffect(() => {
    anchorCheck(formik.errors);
  }, [formik.isSubmitting]);

  const afficherChamps = () => {
    return formType?.map((champ, cle) => {
      let value = Object.entries(formik.values).find(
        (element) => element[0] === champ.ref
      );
      if (value === undefined) value = [""];
      return (
        <Grid item key={cle}>
          <StyledTextfieldOrange
            id={champ.ref}
            name={champ.ref}
            label={champ.nom}
            value={value[1]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={champ.type}
            error={Boolean(
              formik.touched[champ.ref] && formik.errors[champ.ref]
            )}
            helperText={
              formik.touched[champ.ref] ? formik.errors[champ.ref] : null
            }
          />
        </Grid>
      );
    });
  };

  if ((!utdRoleList || !formik.values) && from) {
    return null;
  }

  return (
    <form id="utilisateurFormulaire" onSubmit={formik.handleSubmit}>
      <StyledDivChampsModifierParent>
        <StyledDivChampsModifier container>
          {afficherChamps()}
          {["Modifier", "Créer"].includes(from)
            ? utdRoleList
              ? genererFormulaire(formulaireRole(utdRoleList), formik)
              : null
            : null}
        </StyledDivChampsModifier>
      </StyledDivChampsModifierParent>
    </form>
  );
};
