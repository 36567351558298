import { useEffect } from "react";
import {
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { StyledDivRecapitulatifEC } from "../../../styledComponents/StyledDiv";
import {
  StyledPaperBodyEC,
} from "../../../styledComponents/StyledPaper";
import {
  getAllCommandesByArticleIdEC,
} from "../../../../slice/EspaceClientReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledDivMsgEchec,
  StyledDivMsgSucces,
} from "../../../styledComponents/StyledDivBackoffice";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ListeCommandeByArticle } from "./ListeCommandeByArticle";

export const CommandesArticle = ({ clientId, articleId }) => {

  const useQuery = () => new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const { commandesByArtIdList } = useSelector((state) => state.espaceClient);
  const { message } = useSelector((state) => state.message);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
  ] = useOutletContext();

  useEffect(() => {
    if (clientId !== null)
      dispatch(getAllCommandesByArticleIdEC({ clientId: clientId, articleId: articleId }));
  }, [dispatch]);

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: commandesByArtIdList || [],
    styles,
    t,
    articleId,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateArticleByIdList,
  };

  return (
    <div>
      <StyledPaperBodyEC elevation={0}>
        <StyledDivRecapitulatifEC>
          <div>
            {displayError()}
            <ListeCommandeByArticle
              state={stateUsedForRender}
              actions={actionUsedForRender}
            />
          </div>
        </StyledDivRecapitulatifEC>
      </StyledPaperBodyEC>
    </div>
  );
};
