import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { FonctionsActionType } from "../../constantes/actionType/FonctionsActionType";
import fonctionsService from "../service/FonctionsService";
import { langJsonFormatter } from "../../helper/ServiceCleaner";
import { ApiStatus } from '../../constantes/ApiStatus';

export const getAllFonctions = createAsyncThunk(
  FonctionsActionType.GET_ALL_FONCTIONS,
  async (lang, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const fonctionsList = state.fonctions.fonctionsList;
      // optimisation : ne pas renvoyer de requête si list déjà récupérée
      if (fonctionsList.length > 0)
        return fonctionsList;

      const reponse = await fonctionsService.getAllFonctions();
      return langJsonFormatter(lang, thunkAPI, reponse);
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  fonctionsList: [],
  apiStatus: ApiStatus.IDLE,
  apiError: null
};

const fonctionsSlice = createSlice({
  name: FonctionsActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFonctions.pending, (state, _) => {
        state.apiStatus = ApiStatus.IDLE;
        state.apiError = null;
      })
      .addCase(getAllFonctions.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.SUCCESS;
        state.apiError = null;
        state.fonctionsList = action.payload;
      })
      .addCase(getAllFonctions.rejected, (state, action) => {
        state.apiStatus = ApiStatus.ERROR;
        state.fonctionsList = action.payload;
      });
  },
});

const { reducer } = fonctionsSlice;
export default reducer;
