import {
  Badge,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledDivArrowDownIcon,
  StyledDivArrows,
  StyledDivArrowUpIcon,
  StyledDivSearchBar,
} from "../../../styledComponents/StyledDivBackoffice";
import {
  StyledTextfieldSearchDdpRose,
} from "../../../styledComponents/StyledTextfieldBackoffice";
import { ClientRoutes } from "../../../../../constantes/routes/ClientRoutes";

import { EC_COLORS, getBgColorFromStatus } from "../../common/EcColor";

export const CommandeByArtItem = ({
  id,
  ligne,
  marque,
  quantiteLivr,
  quantiteCde,
  prixUnitaireHT,
  totalHT,
  livraisonPrevue,
  commandeCode,
  commandeRefClient,
  designation,
  etatLivraison,
  commandeId,
  state: { t },
  searchBarAction: {
    setSearchBarContentNumeroLigne,
    setSearchBarContentMarque,
    setSearchBarContentQuantite,
    setSearchBarContentTotalHT,
    setSearchBarContentPrixUnitaire,
    setSearchBarContentLivrPrev,
    setSearchBarContentCodeCommande,
    setSearchBarContentReferenceCommande,
    setSearchBarContentDesignation,
    setSearchBarContentEtatLivraison,
    setSearchBarContentQuantiteCde,
  },
  sortAction: {
    setSortedCodeOffre,
    setSortedNumeroLigne,
    setSortedMarque,
    setSortedQuantite,
    setSortedTotalHT,
    setSortedPrixUnitaire,
    setSortedLivrPrev,
    setSortedCodeCommande,
    setSortedReferenceCommande,
    setSortedDesignation,
    setSortedEtatLivraison,
    setSortedQuantiteCde,
  },
}) => {
  const navigate = useNavigate();
  const columnSize = 1;
  const [isForceSendEnded, setIsForceSendEnded] = useState(true);
  const { accessTokenEC } = useSelector((state) => state.authentification);
  const { articleById } = useSelector((state) => state.espaceClient);
  const { forceSend } = useSelector((state) => state.formulaire);
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  const noClassNameMobileAndTablette =
    "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight";
  const alwaysDisplayed = "hasBorderRight";

  useEffect(() => {
    if (forceSend === undefined) {
      setIsForceSendEnded(true);
    } else {
      setIsForceSendEnded(forceSend);
    }
  }, [forceSend]);

  const handleChange = (event, setterSearchBar) => {
    setterSearchBar(event.target.value);
  };

  const displaySearchBar = (setValue) => {
    if (setValue) {
      return (
        <div>
          <StyledDivSearchBar>
            <StyledTextfieldSearchDdpRose
              variant="outlined"
              size="small"
              onChange={(event) => handleChange(event, setValue)}
              InputProps={{
                endAdornment: (
                  <SearchIcon md={{ color: EC_COLORS.VIOLET, fontSize: 10 }} />
                ),
              }}
            />
          </StyledDivSearchBar>
        </div>
      );
    }
  };

  const displaySortArrows = (setSortedValue) => {
    if (setSortedValue) {
      return (
        <Stack alignItems="center" justifyContent="center">
          <StyledDivArrows>
            <StyledDivArrowUpIcon
              fontSize="small"
              onClick={() => setSortedValue("up")}
            />
            <StyledDivArrowDownIcon
              fontSize="small"
              onClick={() => setSortedValue("down")}
            />
          </StyledDivArrows>
        </Stack>
      );
    }
  };

  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    md,
    sm,
    xs,
    style
  ) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " columnHeaderEC"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
          marginLeft={name === "Action" ? "-0.75rem" : "0"}
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
            {displaySortArrows(sortStateSetter)}
          </Stack>
          <Stack>{displaySearchBar(searchBarStateSetter)}</Stack>
        </Stack>
      </Grid>
    );
  };

  const displayItem = (name, md, sm, style, texte) => {
    let displayName = name;

    // Vérifier et remplacer les valeurs spécifiques
    if (name === "RC") {
      displayName = "RC Modèles";
    } else if (name === "MP") {
      displayName = "Moovprotect";
    } else if (texte) {
      displayName = name + " " + texte;
    }

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: displayName }}
        />
      </Grid>
    );
  };

  const displayCommandeCode = (commandeId, name, md, sm, style) => {
    // Définir l'URL de redirection pour la commande
    const navigateToCommande = () => {
      if (commandeId) {
        navigate(ClientRoutes.VUE_COMMANDE + "?commande=" + commandeId);
      }
    };

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {commandeId ? (
          // Si commandeId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={navigateToCommande} // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si commandeId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  const displayCommandeReference = (commandeId, name, md, sm, style) => {
    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={8}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Grid>
    );
  };




  const displayEtatLivrEtFact = (name, md, sm, xs, style, t) => {
    // Vérifier si le mot name est "Attente", "Commandée" ou "Refusée"
    const reponse =
      name === "Non commencée"
        ? t.nonCommencee
        : name === "Terminée"
        ? t.terminee
        : name === "Partielle"
        ? t.partielle
        : name;

    return (
      <Grid
        item
        md={md ? md : columnSize}
        sm={sm ? sm : columnSize}
        xs={xs ? xs : columnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: getBgColorFromStatus(name),
            color: "white",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            paddingLeft: "0.4rem",
            paddingRight: "0.4rem",
            borderRadius: "0.25rem",
            fontSize: "0.75rem",
          }}
        >
          {reponse}
        </Badge>
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : { height: "100px" }}
    >
      {id === 0
        ? displayItemHeader(
            ligne,
            setSearchBarContentNumeroLigne,
            setSortedNumeroLigne,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayItem(ligne, 1, 1, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            commandeCode,
            setSearchBarContentCodeCommande,
            setSortedCodeCommande,
            1,
            1,
            8,
            alwaysDisplayed
          )
        : displayCommandeCode(commandeId, commandeCode, 1, 1, alwaysDisplayed)}

      {id === 0
        ? displayItemHeader(
            commandeRefClient,
            setSearchBarContentReferenceCommande,
            setSortedReferenceCommande,
            1,
            1,
            8,
            alwaysDisplayed
          )
        : displayCommandeReference(commandeId, commandeRefClient, 1, 1, alwaysDisplayed)}

      {id === 0
        ? displayItemHeader(
            designation,
            setSearchBarContentDesignation,
            setSortedDesignation,
            2,
            2,
            4,
            noClassNameMobile
          )
        : displayItem(designation, 2, 2, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            marque,
            setSearchBarContentMarque,
            setSortedMarque,
            1,
            1,
            4,
            noClassNameMobile
          )
        : displayItem(marque, 1, 1, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            quantiteCde,
            setSearchBarContentQuantiteCde,
            setSortedQuantiteCde,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayItem(quantiteCde, 1, 1, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
            quantiteLivr,
            setSearchBarContentQuantite,
            setSortedQuantite,
            1,
            1,
            null,
            noClassNameMobile
          )
        : displayItem(quantiteLivr, 1, 1, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            prixUnitaireHT,
            setSearchBarContentPrixUnitaire,
            setSortedPrixUnitaire,
            1,
            1,
            8,
            noClassNameMobileAndTablette
          )
        : displayItem(
            prixUnitaireHT,
            1,
            1,
            noClassNameMobileAndTablette,
            "€"
          )}
      {id === 0
        ? displayItemHeader(
            totalHT,
            setSearchBarContentTotalHT,
            setSortedTotalHT,
            1,
            1,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(totalHT, 1, 1, noClassNameMobileAndTablette, "€")}
      {id === 0
        ? displayItemHeader(
            livraisonPrevue,
            setSearchBarContentLivrPrev,
            setSortedLivrPrev,
            1,
            1,
            4,
            noClassNameMobileAndTablette
          )
        : displayItem(livraisonPrevue, 1, 1, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            etatLivraison,
            setSearchBarContentEtatLivraison,
            setSortedEtatLivraison,
            1,
            1,
            4,
            ""
          )
        : displayEtatLivrEtFact(etatLivraison, 1, 1, 4, "", t)}
    </Grid>
  );
};
