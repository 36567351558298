import { configureStore } from '@reduxjs/toolkit';

import { setStore } from './storeProvider';
import configReducer from "../redux/slice/ConfigReducer";
import i18nReducer from '../redux/slice/i18nReducer';
import utilisateurReducer from '../redux/slice/UtilisateurReducer';
import authentificationReducer from '../redux/slice/AuthentificationReducer';
import messageReducer from '../redux/slice/MessageReducer';
import machinesReducer from '../redux/slice/MachinesReducer';
import secteursReducer from '../redux/slice/SecteurReducer';
import fonctionReducer from '../redux/slice/FonctionReducer';
import paysReducer from '../redux/slice/PaysReducer';
import formulaireReducer from '../redux/slice/FormulaireReducer';
import agressionsReducer from '../redux/slice/AgressionsReducer';
import childFormikPropsReducer from '../redux/slice/ChildFormikPropsReducer';
import espaceClientReducer from '../redux/slice/EspaceClientReducer';


const reducer = {
    configuration: configReducer,
    i18n: i18nReducer,
    utilisateur: utilisateurReducer,
    authentification: authentificationReducer,
    message: messageReducer,
    machines: machinesReducer,
    secteurs: secteursReducer,
    fonctions: fonctionReducer,
    pays: paysReducer,
    formulaire: formulaireReducer,
    agressions: agressionsReducer,
    childFormikProps: childFormikPropsReducer,
    reference: formulaireReducer,
    traductions: i18nReducer,
    espaceClient: espaceClientReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: true, //redux devTool extension
});

setStore(store);

export default store;
