import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "@mui/material";
import { getAllUtilisateur } from "../slice/UtilisateurReducer";
import { CompteItem } from "./CompteItem";
import {
  StyledDivEntete,
  StyledDivLignesTab,
  StyledDivPagination,
} from "./styledComponents/StyledDivBackoffice";
import { StyledGridTab } from "./styledComponents/StyledGridBackoffice";
import { setMessage } from "../slice/MessageReducer";

/**
 * Pourquoi mettre des 'key' partout ? ->
 * https://stackoverflow.com/a/43892905/2325522
 *
 * @returns ListeComptes
 */
export const ListeComptes = ({ searchBarContent, actions }) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { utilisateurList } = useSelector((state) => state.utilisateur);
  const [filteredList, setFilteredList] = useState();
  const [sortedNom, setSortedNom] = useState("up");
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  //Passer [] comme second argument à useEffect permet de n'appeler la
  //fonction que lors de la construction de la page mount & unmount
  useEffect(() => {
    dispatch(getAllUtilisateur())
      .unwrap()
      .then(() => {});
  }, []);

  useEffect(() => {
    if (!utilisateurList) {
      return;
    }
    setFilteredList(
      utilisateurList.filter((item) => {
        const searchedValue = searchBarContent;
        if (searchedValue === undefined) {
          return item;
        } else {
          if (
            item.prenom.includes(searchedValue) ||
            item.nom.includes(searchedValue) ||
            item.email.includes(searchedValue)
          ) {
            return item;
          } else {
            return null;
          }
        }
      })
    );
  }, [searchBarContent, utilisateurList]);

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...utilisateurList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...utilisateurList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (!utilisateurList) {
      return;
    }
    defineSortedList(sortedNom, "nom");
  }, [sortedNom, utilisateurList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeCompte = pagination.currentData.map((item) => {
    return (
      <div className="lignesTab" key={item.id}>
        <CompteItem {...item} modale={actions} />
      </div>
    );
  });

  return (
    <div>
      <StyledGridTab container>
        <StyledDivEntete>
          <CompteItem
            key={0}
            id={0}
            prenom={"Prénom"}
            nom={"Nom"}
            email={"Adresse mail"}
            label={"Poste"}
            action={"Action"}
            modale={actions}
            sortAction={{
              setSortedNom,
            }}
          />
        </StyledDivEntete>
        <StyledDivLignesTab>{afficherListeCompte}</StyledDivLignesTab>
      </StyledGridTab>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};
