/**
 * Cette fonction s'assure que le chemin passé en argument commence
 * et se termine par une barre oblique
 */
export const StringToRouteInNavigate = (path) => {
  if (! path.startsWith('/'))
    path = '/' + path;
  if (! path.endsWith('/'))
    path = path + '/';
  return path;
}
