import React, { useEffect, useRef, useState } from "react";

import { HeaderInternaute } from "../../redux/vue/HeaderInternaute";
import { FooterInternaute } from "../../redux/vue/FooterInternaute";
import { useTranslations } from "../../redux/vue/components/hook/useTranslations";
import { GetStyle } from "../../helper/Styles";

const EcranErreurCritique = ({children}) => {

  const [booleanIdentite, setBooleanIdentite] = useState(false);
  const styles = GetStyle(booleanIdentite);
  const [isMobileFormat, setIsMobileFormat] = useState(
    window.innerWidth >= 768
  );

  const { t } = useTranslations();
    return (<>
    <HeaderInternaute
        booleanIdentite={booleanIdentite}
        setBooleanIdentite={setBooleanIdentite}
        styles={styles}
        isMobileFormat={isMobileFormat}
        t={t}
    />
    <div>Erreur critique !</div>
    {children}
    <FooterInternaute styles={styles} />
    </>);
}

export default EcranErreurCritique;
