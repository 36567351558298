import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { errorHandlerMessageProvider } from '../../helper/ErrorHandlerMessageProvider';
import { fetchJsonFromServer } from '../../helper/fetchUtil.js';

// Ne pas enlever le «/» en début de chemin (pour l'espace client)
const CONFIG_PATH = '/config.json';

const PARAM_LIST = {
  URL_BACKEND : { type: 'string' },
  URL_TRANSLATION: { type: 'string' },
};


const loadConfiguration = createAsyncThunk(
  'configuration/loadConfiguration',
  async (lang, thunkAPI) => {
    try {
      const parameters = await fetchJsonFromServer(CONFIG_PATH);

      // contrôler les paramètres
      for (const [paramName, paramConfig] of Object.entries(PARAM_LIST)) {
        const paramValue = parameters[paramName];
        if (paramValue === undefined)
          throw new Error(`Paramètre «${paramName}» absent du fichier «${CONFIG_PATH}»`);
        if (typeof(paramValue) != paramConfig.type)
          throw new Error(`Le paramètre «${paramName}» n'est pas de type ${paramConfig.type}`);
      }

      return parameters;
    } catch (error) {
      console.log('Load config error :', error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {
  parameters: {},
  loading: true,
  error: null
};


const configReducer = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadConfiguration.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.parameters = null;
      })
      .addCase(loadConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.parameters = action.payload;
      })
      .addCase(loadConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.parameters = null;
      })
  }
});

export { configReducer, loadConfiguration  };
export default configReducer.reducer;
