import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { Icon } from "@iconify/react";
import gearsIcon from "@iconify/icons-et/gears";
import envelopeLight from "@iconify/icons-ph/envelope-light";
import numberSymbol16Regular from "@iconify/icons-fluent/number-symbol-16-regular";

import { translationsSpec, useTranslations } from "./components/hook/useTranslations";

//CSS
import {
  StyledDivAccueilParent,
  StyledDivButton,
  StyledDivH1Accueil,
  StyledDivHome,
  StyledDivPhotoAccueil,
} from "./styledComponents/StyledDiv";
import { importAllSpec } from "../../helper/ImportAll";
import { StyledPAccueil } from "./styledComponents/StyledP";
import { StyledPaperBodyAccueil } from "./styledComponents/StyledPaper";
import { StyledButtonHome } from "./styledComponents/StyledButton";
import { StyledH2Home } from "./styledComponents/StyledH";

export const Accueil = () => {
  const [styles, t, booleanIdentite] = useOutletContext();
  const { loading: i18nLoading } = useTranslations();
  const navigate = useNavigate();
  const cssButton = styles.cssButton;
  const cssFontFamily = styles.cssFontFamily;
  const cssH2Home = styles.cssH2Home;

  const handleConfigurer = () => {
    navigate(StringToRouteInNavigate(GuestRoutes.CONFIGURATEUR));
  };

  const handleNousContacter = () => {
    navigate(StringToRouteInNavigate(GuestRoutes.NOUS_CONTACTER));
  };

  const handleRechercher = () => {
    navigate(StringToRouteInNavigate(GuestRoutes.RECHERCHER));
  };

  return i18nLoading ? (
    <p>Loading...</p>
  ) : (
    <StyledPaperBodyAccueil elevation={0}>
      <StyledDivAccueilParent>

        <StyledDivHome>
          <StyledDivH1Accueil>
            {booleanIdentite ? (
              <h1>
                {translationsSpec("genAccueilH1RealisezVosDemandes/MP", t)}
              </h1>
            ) : (
              <h1>
                {translationsSpec("genAccueilH1RealisezVosDemandes/RC", t)}
              </h1>
            )}
          </StyledDivH1Accueil>

          <StyledH2Home className={cssH2Home}>
            {t.genAccueilH2ConfigurezVosProtections}
          </StyledH2Home>
          <StyledPAccueil className={cssFontFamily}>
            {t.genAccueilBodyVisualisezEnTemps}
          </StyledPAccueil>
          <StyledDivButton>
            <StyledButtonHome
              variant="contained"
              className={cssButton}
              onClick={handleConfigurer}
              endIcon={<Icon icon={gearsIcon} color="white" height="25"/>}
            >
              {t.genAccueilBouttonConfigurer}
            </StyledButtonHome>
          </StyledDivButton>

          <StyledH2Home className={cssH2Home}>
            {t.genAccueilH2ReactualisezUneOffre}
          </StyledH2Home>
          <StyledPAccueil className={cssFontFamily}>
            {t.genAccueilBodySaisissezLaReference}
          </StyledPAccueil>
          <StyledDivButton>
            <StyledButtonHome
              variant="contained"
              className={cssButton}
              onClick={handleRechercher}
              endIcon={
                <Icon icon={numberSymbol16Regular} color="white" height="25"/>
              }
            >
              {t.genAccueilBouttonRechercher}
            </StyledButtonHome>
          </StyledDivButton>

          <StyledH2Home className={cssH2Home}>
            {t.genAccueilH2DeposezUneDemande}
          </StyledH2Home>
          <StyledPAccueil className={cssFontFamily}>
            {t.genAccueilBodyCompletezLesElements}
          </StyledPAccueil>
          <StyledDivButton>
            <StyledButtonHome
              variant="contained"
              className={cssButton}
              onClick={handleNousContacter}
              endIcon={<Icon icon={envelopeLight} color="white" height="25"/>}
            >
              {t.genAccueilBouttonNousContacter}
            </StyledButtonHome>
          </StyledDivButton>
        </StyledDivHome>
      </StyledDivAccueilParent>

      <StyledDivPhotoAccueil
        style={{
          backgroundImage:
            "url(" +
            importAllSpec(booleanIdentite)[ "LandingPageImage.jpg" ] +
            ")",
        }}
      />
    </StyledPaperBodyAccueil>
  );
};
