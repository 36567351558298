/*Routes*/
import { useNavigate } from "react-router-dom";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { StringToRouteInNavigate } from "../../helper/StringParser";

/*Styled element*/
import { StyledDivFooter } from "./styledComponents/StyledDiv";
import { StyledPaperFooter } from "./styledComponents/StyledPaper";
import { useTranslations } from "./components/hook/useTranslations";

export const FooterInternaute = () => {

    const navigate = useNavigate();
    const { t } = useTranslations();
    const handlePolitiqueClick = () => {
        navigate(StringToRouteInNavigate(GuestRoutes.POLITIQUE_DE_CONFIDENTIALITE))
    };
    const handleMentionsClick = () => {
        navigate(StringToRouteInNavigate(GuestRoutes.MENTIONS_LEGALES))
    };

    return (
        <StyledPaperFooter elevation={0}>
            <StyledDivFooter>
                <p><span onClick={handlePolitiqueClick}>{t.politiqueConfidentialite} </span> - <span onClick={handleMentionsClick}> {t.mentionsLegales}</span></p>
                <p>Copyright {new Date().getFullYear()} <a href="https://gibraltaz.fr">Gibraltaz</a></p>
            </StyledDivFooter>
        </StyledPaperFooter>
    )
}
