import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { UtilisateurActionType } from "../../constantes/actionType/UtilisateurActionType";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import utilisateursService from "../service/UtilisateurService";

export const getAllUtilisateur = createAsyncThunk(
  UtilisateurActionType.GET_ALL,
  async (thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = (await utilisateursService.getAll(accessToken)).data;
      return { reponse };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllRole = createAsyncThunk(
  UtilisateurActionType.GET_ALL_ROLE,
  async (thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = (await utilisateursService.getAllRole(accessToken)).data;
      return { reponse };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOwnUtilisateur = createAsyncThunk(
  UtilisateurActionType.GET_OWN,
  async (id, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = (await utilisateursService.getOwn(id, accessToken)).data;
      return { reponse };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const addOneUtilisateur = createAsyncThunk(
  UtilisateurActionType.INSCRIPTION,
  async (formData, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await utilisateursService.addOne(formData, accessToken);
      return reponse.data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const modifyOneUtilisateur = createAsyncThunk(
  UtilisateurActionType.MODIFY_ONE,
  async (formData, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await utilisateursService.modifyOne(
        formData,
        accessToken
      );
      return reponse.data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const deleteOneUtilisateur = createAsyncThunk(
  UtilisateurActionType.DELETE_ONE,
  async (formData, thunkAPI) => {
    try {
      const accessToken = {
        [LocalStorage.ACCESS_TOKEN]: localStorage.getItem(
          LocalStorage.ACCESS_TOKEN
        ),
      };
      const reponse = await utilisateursService.deleteOne(
        formData,
        accessToken
      );
      return reponse.data;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const initialState = {};

const utilisateurSlice = createSlice({
  name: UtilisateurActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllUtilisateur.fulfilled, (state, action) => {
        state.utilisateurList = action.payload.reponse.data;
      })
      .addCase(getAllUtilisateur.rejected, (state, action) => {
        state.utilisateurList = null;
      })
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.roleList = action.payload.reponse.data.map((el) => {
          return {
            code: el.id,
            name: el.label,
          };
        });
      })
      .addCase(getAllRole.rejected, (state, action) => {
        state.roleList = null;
      })
      .addCase(getOwnUtilisateur.fulfilled, (state, action) => {
        state.currentUtilisateur = action.payload.reponse.data;
      })
      .addCase(getOwnUtilisateur.rejected, (state, action) => {
        state.currentUtilisateur = null;
      })
      .addCase(addOneUtilisateur.fulfilled, (state, action) => {})
      .addCase(addOneUtilisateur.rejected, (state, action) => {})
      .addCase(modifyOneUtilisateur.fulfilled, (state, action) => {})
      .addCase(modifyOneUtilisateur.rejected, (state, action) => {})
      .addCase(deleteOneUtilisateur.fulfilled, (state, action) => {})
      .addCase(deleteOneUtilisateur.rejected, (state, action) => {});
  },
});

const { reducer } = utilisateurSlice;
export default reducer;
