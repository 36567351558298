import { Component } from "react";

// TODO migration React : classe non migrable en fonction :
// Les hooks React (useState, useEffect, etc.) ne supportent pas getDerivedStateFromError ou componentDidCatch.
// Il faudra gérer l'erreur au niveau des enfants en encapsulant avec try-catch.
// Envisager d'utiliser le hook useErrorBoundary (bibliothèques comme @sentry/react).
//

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div>
          <p>Certaines données sont manquantes pour afficher le récapitulatif et le schéma technique.</p>

          {error.message && <span>Voici l'erreur associée: {error.message}</span>}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
