import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { Grid } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

//CSS
import { DessinTechnique } from "../../components/DessinTechnique";
import {
  StyledDivButtonToRecap,
  StyledDivFiligranneRecap,
  StyledDivRecapGene,
  StyledDivRecapInt,
  StyledDivRecapitulatif,
  StyledDivSectionRecap,
  StyledDivSectionRecapProtecType,
  StyledDivSubtitlesRecap,
  StyledDivUnderlineH1,
  StyledDivSectionsRowRecap,
  StyledDivSectionsRowReverseRecap,
  StyledDivH1Resume,
  StyledDivDessinRecap,
  StyledDivDessinRecapIframe,
  StyledDivDessinRecapIframeScaleS08,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonPrec,
  StyledButtonRecap,
} from "../../styledComponents/StyledButton";
import { StyledPaperBody } from "../../styledComponents/StyledPaper";
import { StyledSpanSubtitlesRecap } from "../../styledComponents/StyledSpan";
import { importAllSpec } from "../../../../helper/ImportAll";
import {
  sendFormulaire,
  setAllInfoByRefNull,
} from "../../../slice/FormulaireReducer";
import { setChildFormikValuesWhenValidate } from "../../../slice/ChildFormikPropsReducer";
import {
  formulaireAccessoiresS08,
  formulaireAggressions,
  formulaireCommentaire,
  formulaireCoordonnees,
  formulaireDimensionsS08,
  formulaireExtremitesRecapS08,
  formulaireFichiers,
  formulaireFichiersRecapitulatif,
  formulaireFormeS08,
  formulaireInformationsMachine,
  formulaireQuantités,
  formulaireRecapitulatifMatiere,
  formulaireRecapitulatifTypeProtectionS08,
} from "../../ChampsFormulaires/S08/recapitulatifS08/ChampsRecapitulatifS08";
import { dataCleanerS08 } from "../../../../helper/DataCleanerS08";
import { useTranslations } from "../../components/hook/useTranslations";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { FormeFaceS08 } from "../../DessinTechnique/S08/FormeFaceS08";
import { Loading } from "../../components/Loading";
import { anchorEtape } from "../../../../helper/Anchors";
import {
  ARTICLE,
  CONFIGURATEUR,
  DEMANDE_DE_PRIX,
  OFFRE_DE_PRIX,
} from "../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { useLocation } from "react-router-dom";
import {
  genererChampRecapitulatifHorsConfigurateur,
  isGroupDataAvailable,
} from "../../components/generateFormulary/methods/ResumeGenerate";

export const ResumeS08 = ({
  state: {
    booleanIdentite,
    etape,
    formik,
    styles,
    styles: {
      cssButton,
      cssButtonPrec,
      sousTitreRecap,
      underlineH1,
      cssTypeProtec,
      cssCenter,
      iconeRecap,
    },
    pdfHeaderRef,
    pdfBodyRef,
    t,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateAgressionsList,
    upToDatePaysList,
    upToDateSecteursList,
    upToDateFonctionsList,
    reference,
  },
  action: {
    dispatch,
    setIsPdfInCreation,
    setPdfToDownload,
    setEtape,
    goTo,
    setUpToDateReferenceByIdList,
    setReference,
    handleRetour,
  },
  isFrom,
  nom
}) => {
  const from = [DEMANDE_DE_PRIX, OFFRE_DE_PRIX, ARTICLE].includes(isFrom);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { lang } = useTranslations();
  const [iframe, setIframe] = useState();

  const [formulaire, setFormulaire] = useState(
    dataCleanerS08(formik.values ?? formik)
  );

  useEffect(() => {
    if ([ARTICLE].includes(isFrom)) {
      const updatedHorsConfigurateur = JSON.parse(
        JSON.stringify(formulaire.horsConfigurateur)
      );
      for (let key in updatedHorsConfigurateur) {
        if (updatedHorsConfigurateur[key].libelle) {
          switch (lang) {
            case "FR":
              updatedHorsConfigurateur[key].libelle =
                updatedHorsConfigurateur[key].FR ||
                updatedHorsConfigurateur[key].libelle;
              break;
            case "EN":
              updatedHorsConfigurateur[key].libelle =
                updatedHorsConfigurateur[key].EN ||
                updatedHorsConfigurateur[key].libelle;
              break;
            case "DE":
              updatedHorsConfigurateur[key].libelle =
                updatedHorsConfigurateur[key].DE ||
                updatedHorsConfigurateur[key].libelle;
              break;
            default:
              break;
          }
        }
      }
      setFormulaire((prevFormulaire) => ({
        ...prevFormulaire,
        horsConfigurateur: updatedHorsConfigurateur,
      }));
    }
  }, [lang]);

  useEffect(() => {
    setIframe(parseInt(query.get("iframe")) === 1);
  }, []);

  useEffect(() => {
    setReference((booleanIdentite ? "MP" : "RC") + Date.now());
  }, [booleanIdentite]);

  const exportToPDF = async () => {
    setIsPdfInCreation(true);
    document.getElementById("validDDP").remove();
    const contentHeader = pdfHeaderRef.current;
    const contentBody = pdfBodyRef.current;
    const pdf = new jsPDF("p", "mm", "a4",true);
    var svgElements = contentBody.querySelectorAll("svg");
    svgElements.forEach((item) => {
      item.setAttribute("width", item.getBoundingClientRect().width);
      item.setAttribute("height", item.getBoundingClientRect().height);
      item.style.width = null;
      item.style.height = null;
    });
    const dataHeader = await html2canvas(contentHeader);
    const dataBody = await html2canvas(contentBody);
    const imgHeader = dataHeader.toDataURL("image/png",0.8);
    const imgBody = dataBody.toDataURL("image/png",0.8);
    const imgHeaderProps = pdf.getImageProperties(imgHeader);
    const imgBodyProps = pdf.getImageProperties(imgBody);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeaderHeight =
      (imgHeaderProps.height * pdfWidth) / imgHeaderProps.width;
    const pdfBodyHeight = (imgBodyProps.height * pdfWidth) / imgBodyProps.width;

    const pageHeight = 295;
    const nbPageForBody = Math.floor(pdfBodyHeight / pageHeight);
    const sizeAddedByHeaderTotal = pdfHeaderHeight * nbPageForBody;
    const padding = 25;
    var totalHeight = sizeAddedByHeaderTotal + pdfBodyHeight;

    pdf.addImage(imgHeader, "PNG", 0, 0, pdfWidth, pdfHeaderHeight);
    pdf.addImage(imgBody, "PNG", 0, pdfHeaderHeight, pdfWidth, pdfBodyHeight);
    var position = pdfHeaderHeight;

    while (totalHeight >= 0) {
      totalHeight -= pageHeight - padding;
      position -= pageHeight - pdfHeaderHeight - padding;
      pdf.addPage();
      pdf.addImage(imgBody, "PNG", 0, position, pdfWidth, pdfBodyHeight);
      pdf.addImage(imgHeader, "PNG", 0, 0, pdfWidth, pdfHeaderHeight);
    }
    pdf.deletePage(pdf.internal.getNumberOfPages());
    setIsPdfInCreation(false);
    return pdf;
  };

  const handleSubmit = async () => {
    anchorEtape(t.genConfigurateurH2Recapitulatif);
    formulaire.timestamp = reference;
    formulaire.marque = booleanIdentite ? "MP" : "RC";
    formulaire.langue = lang;
    formulaire.type = "S08";

    const pdf = await exportToPDF();
    setPdfToDownload(pdf);
    formulaire.pdf = Object.assign(
      {
        name: "Recapitulatif_Configurateur_" + formulaire.marque + ".pdf",
        base64: pdf.output("datauristring"),
      },
      {}
    );
    dispatch(sendFormulaire(formulaire));
    dispatch(setChildFormikValuesWhenValidate(formulaire));
    setEtape(etape + 1);
  };

  const triggerHandleRetour = () => {
    if (from) {
      dispatch(setAllInfoByRefNull());
      setUpToDateReferenceByIdList(undefined);
    } else {
      handleRetour();
    }
  };

  const afficherDessinTechnique = () => {
    return iframe ? (
      <>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS08>
            <DessinTechnique
              formik={formulaire}
              formulaire={formulaire}
              styles={styles}
              etape={etape}
              t={t}
            />
          </StyledDivDessinRecapIframeScaleS08>
        </StyledDivDessinRecapIframe>
        <StyledDivDessinRecapIframe>
          <StyledDivDessinRecapIframeScaleS08>
            <FormeFaceS08
              formik={formulaire}
              formulaire={formulaire}
              styles={styles}
              etape={etape}
              t={t}
            />
          </StyledDivDessinRecapIframeScaleS08>
        </StyledDivDessinRecapIframe>
      </>
    ) : (
      <StyledDivSectionsRowRecap>
        <StyledDivDessinRecap>
          <DessinTechnique
            formik={formulaire}
            formulaire={formulaire}
            styles={styles}
            etape={etape}
            t={t}
          />
        </StyledDivDessinRecap>
        <StyledDivDessinRecap>
          <FormeFaceS08
            formik={formulaire}
            formulaire={formulaire}
            styles={styles}
            etape={etape}
            t={t}
          />
        </StyledDivDessinRecap>
      </StyledDivSectionsRowRecap>
    );
  };

  if (
    !upToDateTypeMachineList ||
    upToDateTypeMachineList.length === 0 ||
    !upToDateMarqueMachineList ||
    upToDateMarqueMachineList.length === 0 ||
    !upToDateAgressionsList ||
    upToDateAgressionsList.length === 0
  ) {
    return <Loading t={t} />;
  }

  return (
    <div name={nom}>
      {iframe ? afficherDessinTechnique() : null}
      <StyledPaperBody elevation={0}>
        <StyledDivRecapitulatif>
          {!iframe ? (
            <StyledDivH1Resume name={t.genConfigurateurH2Recapitulatif}>
              <h1>
                {t.genConfigurateurH2Recapitulatif
                  ? t.genConfigurateurH2Recapitulatif
                  : null}
              </h1>
              <StyledDivUnderlineH1 className={underlineH1} />
            </StyledDivH1Resume>
          ) : null}
          <StyledDivRecapInt>
            <StyledDivFiligranneRecap
              style={{
                backgroundImage:
                  "url(" +
                  importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                  ")",
                zIndex: "0",
              }}
              className={styles.logoFiligrane}
            />

            <StyledDivSectionsRowRecap>
              <StyledDivSectionRecapProtecType
                className={cssTypeProtec + " " + cssCenter}
              >
                {genererFormulaire(
                  formulaireRecapitulatifTypeProtectionS08(t),
                  formulaire,
                  t
                )}
              </StyledDivSectionRecapProtecType>
              {[CONFIGURATEUR, DEMANDE_DE_PRIX].includes(isFrom) ? (
                <StyledDivSectionRecapProtecType className={cssTypeProtec}>
                  {t.recapitulatifReferenceRappeler + " "}
                  <span>
                    {[DEMANDE_DE_PRIX].includes(isFrom)
                      ? formulaire.reference
                      : reference}
                  </span>
                  {" " + t.du + " "}
                  {from
                    ? formik.date
                    : ("0" + new Date().getDate()).slice(-2) +
                      "/" +
                      ("0" + (new Date().getMonth() + 1)).slice(-2) +
                      "/" +
                      new Date().getFullYear()}
                  {!from ? " " + t.a + " " : null}
                  {from
                    ? null
                    : ("0" + new Date().getHours()).slice(-2) +
                      "h" +
                      ("0" + new Date().getMinutes()).slice(-2)}
                </StyledDivSectionRecapProtecType>
              ) : [ARTICLE].includes(isFrom) ? (
                <StyledDivSectionRecapProtecType className={cssTypeProtec}>
                  <div>
                    {t.consultationCodeArticle + " "}
                    <span>{formulaire.codeArticle}</span>
                  </div>
                  <div>
                    {t.consultationDesignationArticle + " "}
                    <span>{formulaire.designation}</span>
                  </div>
                </StyledDivSectionRecapProtecType>
              ) : null}
            </StyledDivSectionsRowRecap>

            {!iframe ? afficherDessinTechnique() : null}

            <StyledDivSectionsRowReverseRecap style={{ zIndex: "2" }}>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(6)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.genConfigurateurH2DimensionsS08}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(formulaireDimensionsS08(t), formulaire, t)}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 7, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(5)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.genConfigurateurH2FormeS08}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(
                    formulaireFormeS08(t, formulaire),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 10, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>
            </StyledDivSectionsRowReverseRecap>

            <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(4)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.titreMatiereRecap}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(
                    formulaireRecapitulatifMatiere(t),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 9, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(7)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.genConfigurateurH2ExtremitesS08}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(
                    formulaireExtremitesRecapS08(t, formulaire),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 6, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>
            </StyledDivSectionsRowRecap>

            <StyledDivSectionsRowReverseRecap>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(2)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.titreInformationsMachine}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(
                    formulaireInformationsMachine(
                      t,
                      upToDateTypeMachineList,
                      upToDateMarqueMachineList
                    ),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 1, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>

              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(8)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.titreAccessoires}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                {genererFormulaire(formulaireAccessoiresS08(t), formulaire, t)}
                {genererChampRecapitulatifHorsConfigurateur(formulaire, 11, t)}
              </StyledDivSectionRecap>

              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(3)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.titreEnvironnement}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                <StyledDivRecapGene>
                  {genererFormulaire(
                    formulaireAggressions(t, upToDateAgressionsList),
                    formulaire,
                    t
                  )}
                  {genererChampRecapitulatifHorsConfigurateur(formulaire, 2, t)}
                </StyledDivRecapGene>
              </StyledDivSectionRecap>
            </StyledDivSectionsRowReverseRecap>

            <StyledDivSectionsRowRecap>
              <StyledDivSectionRecap>
                <StyledDivSubtitlesRecap onClick={() => goTo(9)}>
                  <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                    {t.titreInfosComp}
                  </StyledSpanSubtitlesRecap>
                  {from ? null : (
                    <span className={iconeRecap}>
                      <BorderColorOutlinedIcon />
                    </span>
                  )}
                </StyledDivSubtitlesRecap>
                {genererFormulaire(formulaireQuantités(t), formulaire, t)}
                {genererFormulaire(
                  formulaireCommentaire(t, booleanIdentite),
                  formulaire,
                  t,
                  booleanIdentite
                )}
                {genererChampRecapitulatifHorsConfigurateur(formulaire, 12, t)}
              </StyledDivSectionRecap>
              {[OFFRE_DE_PRIX, ARTICLE].includes(isFrom) ? null : (
                <StyledDivSectionRecap>
                  <StyledDivSubtitlesRecap onClick={() => goTo(10)}>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreCoordonnees}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGene>
                    {genererFormulaire(
                      formulaireCoordonnees(
                        t,
                        upToDatePaysList,
                        upToDateSecteursList,
                        upToDateFonctionsList
                      ),
                      formulaire,
                      t
                    )}
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 13, t)}
                  </StyledDivRecapGene>
                </StyledDivSectionRecap>
              )}
            </StyledDivSectionsRowRecap>

            {[OFFRE_DE_PRIX, ARTICLE].includes(isFrom) ? null : (
              <StyledDivSectionsRowReverseRecap>
                <StyledDivSectionRecap>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.fichiersUploades}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <div>
                    {genererFormulaire(
                      formulaireFichiers(),
                      formulaire,
                      t,
                      booleanIdentite,
                      isFrom
                    )}
                  </div>
                </StyledDivSectionRecap>
              </StyledDivSectionsRowReverseRecap>
            )}
            {[DEMANDE_DE_PRIX].includes(isFrom) ? (
              <StyledDivSectionsRowReverseRecap>
                <StyledDivSectionRecap>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.fichierRecapitulatif}
                    </StyledSpanSubtitlesRecap>
                  </StyledDivSubtitlesRecap>
                  <div>
                    {genererFormulaire(
                      formulaireFichiersRecapitulatif(),
                      formulaire,
                      t,
                      booleanIdentite,
                      isFrom
                    )}
                  </div>
                </StyledDivSectionRecap>
              </StyledDivSectionsRowReverseRecap>
            ) : null}
            {isGroupDataAvailable(formulaire, 3) ? (
              <StyledDivSectionsRowRecap>
                <StyledDivSectionRecap>
                  <StyledDivSubtitlesRecap>
                    <StyledSpanSubtitlesRecap className={sousTitreRecap}>
                      {t.titreDimensionsCorps}
                    </StyledSpanSubtitlesRecap>
                    {from ? null : (
                      <span className={iconeRecap}>
                        <BorderColorOutlinedIcon />
                      </span>
                    )}
                  </StyledDivSubtitlesRecap>
                  <StyledDivRecapGene>
                    {genererChampRecapitulatifHorsConfigurateur(formulaire, 3, t)}
                  </StyledDivRecapGene>
                </StyledDivSectionRecap>
              </StyledDivSectionsRowRecap>
            ) : null}
          </StyledDivRecapInt>

          {[ARTICLE].includes(isFrom) ? null : (
            <div id="validDDP">
              <Grid container direction="column">
                <Grid item>
                  <StyledDivButtonToRecap>
                    <StyledButtonPrec
                      className={cssButtonPrec}
                      onClick={triggerHandleRetour}
                      variant="outlined"
                      startIcon={<StyledArrowBackOutlinedIcon />}
                    />
                    {from ? null : (
                      <StyledButtonRecap
                        variant="contained"
                        className={cssButton}
                        onClick={async () => await handleSubmit()}
                      >
                        {t.validerDdp}
                      </StyledButtonRecap>
                    )}
                  </StyledDivButtonToRecap>
                </Grid>
              </Grid>
            </div>
          )}
        </StyledDivRecapitulatif>
      </StyledPaperBody>
    </div>
  );
};
