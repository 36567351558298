import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessinMobile,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoFamille,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { formulaireMatiereS08 } from "../../ChampsFormulaires/S08/etape4/ChampsMatiereS08";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { StyledSticky } from "../../styledComponents/StyledSticky";
import { importAll } from "../../../../helper/ImportAll";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS08Matter = ({
  state: {
    styles,
    styles: { cssFontFamily, cssButton, cssButtonPrec, activeStepTitle },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const translation = useTranslations();
  const lang = translation.lang;

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurRenseignezMatiere}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(formulaireMatiereS08(styles, t), formik)}



            <StyledDivDessinMobile>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
            {/* {afficherSOSetForm()} */}
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivPhotoFamille
          style={{
            backgroundImage:
              "url(" +
              importAll()["GrandePhotoProtecteurTelescopique.jpg"] +
              ")",
          }}
        />

      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
