import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { TranslationActionType } from "../../constantes/actionType/TranslationActionType";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { defaultLang, nativeLang } from "../../config/i18nConfig";
import i18nService from "../service/i18nService";

const initializeTranslation = createAsyncThunk(
  TranslationActionType.INITIALIZE_TRANSLATION,
  async (payload, thunkAPI) => {
    try {
      const { selectedLang, translations, supportedLangs } = await i18nService.initializeTranslations();
      return { selectedLang, translations, supportedLangs };

    } catch (error) {
      console.log(error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);


const setLangAsync = createAsyncThunk(
  TranslationActionType.SET_LANG_ASYNC,
  async (lang = null, thunkAPI) => {
    try {
      const translations = await i18nService.loadLanguage(lang);
      return { lang, translations }

    } catch (error) {
      console.log(error);
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);


const initialState = {
  supportedLangs: [{ code: nativeLang, name: nativeLang }],
  lang: defaultLang,
  translations: {},
  loading: true,
  error: null
};

const i18nReducer = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // thunk «initializeTranslation»
      .addCase(initializeTranslation.pending, (state, action) => {
        state.supportedLangs = null;
        state.lang = null;
        state.translations = {};
        state.loading = true;
        state.error = null;
      })
      .addCase(initializeTranslation.fulfilled, (state, action) => {
        const payload = action.payload;
        state.supportedLangs = payload.supportedLangs;
        state.lang = payload.selectedLang;
        state.translations = payload.translations;
        state.loading = false;
        state.error = null;
      })
      .addCase(initializeTranslation.rejected, (state, action) => {
        // ne devrait jamais se produire car au minimum on charge les loanges stockées en local
        state.supportedLangs = null;
        state.lang = null;
        state.loading = false;
        state.error = action.payload;
      })
      // thunk «setLangAsync»
      .addCase(setLangAsync.pending, (state, _) => {
        // ATTENTION : ne pas remettre loading = true
        state.error = null;
      })
      .addCase(setLangAsync.fulfilled, (state, action) => {
        if (action.payload !== null) {
          const payload = action.payload
          state.translations = payload.translations;
          state.lang = payload.lang;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(setLangAsync.rejected, (state, action) => {
        // ne devrait jamais se produire car au minimum on charge les loanges stockées en local
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export {
  initializeTranslation,
  setLangAsync
};

export default i18nReducer.reducer;
