import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { BoutonRetourEC } from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentVueDetailsEC,
  StyledH1VuesEC,
  StyledTitleVueDetailsEC,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { Loading } from "../../components/Loading";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivSectionsRowRecap,
  StyledDivSectionVueDetailOrangeEC,
  StyledDivVueDetailsEC,
  StyledDivVueDetailsGeneBasicEC,
  StyledDivVueDetailsGeneBasicReverseEC,
} from "../../styledComponents/StyledDiv";
import { StyledSpanTitleVueDetailEC } from "../../styledComponents/StyledSpan";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNC, getEtatNC } from "../../../slice/EspaceClientReducer";
import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";

export const DetailsNC = ({ t, actions, state: { ncById } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useAuthentification();

  const location = useLocation();

  const translation = useTranslations();
  const lang = translation.lang;

  const { etatNCList } = useSelector((state) => state.espaceClient);

  // FIXME ce useEffect est-il utile ?
  useEffect(() => {
    if (clientId !== null) {
      dispatch(getEtatNC({ clientId }));
    }
  }, [dispatch, location]);

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    actions.setIsNCByIdSet(false);

    url.searchParams.delete("nc");
    window.history.pushState({}, "", url);

    if (clientId !== null) {
      dispatch(getAllNC({ clientId }));
    }
  };

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  const displayEtatNC = (field) => {
    const etatItem = etatNCList.find((e) => e.etatFNCCode === field);
    const backgroundColor = etatItem ? etatItem.HEX : "#FFFFFF"; // Default to white if not found

    return (
      <StyledContentVueDetailsEC>
        <p
          style={{
            color: "white",
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
            backgroundColor: backgroundColor,
            padding: "5px",
            borderRadius: "5px",
            margin: "0",
          }}
        >
          { etatItem?.[lang] ?? field }
        </p>
      </StyledContentVueDetailsEC>
    );
  };

  const navigateToArticle = (articleId) => {
    if (articleId) {
      navigate(ClientRoutes.VUE_ARTICLE + "?art=" + articleId);
    }
  };

  // D.Parisot - 18/01/2025 : en rafraîchissant une vue détails NC, on avait une erreur :
  // «ncById[0] is undefined» car ncById est un objet vide {} et pas un tableau
  let nc = null;
  if (ncById instanceof Array && ncById.length > 0)
    nc = ncById[0];

  return (
    <div style={{ padding: "2rem" }}>
      {afficherBoutonRetour(t.retourListeNC, triggerHandleRetour, "#F8F9FF")}
      {nc ? (
        <div>
          <StyledH1VuesEC>
            {t.detailsNC} : {nc["FNCCode"]}
          </StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC>
              <StyledSpanTitleVueDetailEC>
                {t.informationsGenerales}
              </StyledSpanTitleVueDetailEC>
              <StyledDivSectionsRowRecap style={{ zIndex: "2" }}>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.reference} : </span>

                      <StyledContentVueDetailsEC>
                        {nc["FNCLibelle"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {" "}
                        {t.dateDeclaration} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["FNCDate"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>{t.statut} : </span>
                      {displayEtatNC(nc["etatFNC"])}
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicEC>
                </StyledDivSectionVueDetailOrangeEC>
                <StyledDivSectionVueDetailOrangeEC>
                  <StyledDivVueDetailsGeneBasicReverseEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.articleDesignation} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["articleDesignation"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.votreCodeArticle} :{" "}
                      </span>

                      <StyledContentVueDetailsEC>
                        {nc["articleReferenceClient"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                    <StyledTitleVueDetailsEC
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ width: "200px" }}>
                        {t.codeArticleRCMoov} :{" "}
                      </span>

                      <StyledContentVueDetailsEC
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigateToArticle(nc["articleId"])
                        }
                      >
                        {nc["articleCode"]}
                      </StyledContentVueDetailsEC>
                    </StyledTitleVueDetailsEC>
                  </StyledDivVueDetailsGeneBasicReverseEC>
                </StyledDivSectionVueDetailOrangeEC>
              </StyledDivSectionsRowRecap>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
          {nc["FNCConstat"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                <StyledSpanTitleVueDetailEC>
                  {t.constat}
                </StyledSpanTitleVueDetailEC>
                <br />
                <StyledContentVueDetailsEC
                  dangerouslySetInnerHTML={{ __html: nc["FNCConstat"] }}
                />
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
          {nc["commentaireEspaceClient"] !== "" ? (
            <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
              <StyledDivVueDetailsEC>
                {/* Jansens : J'ai encapsulé les StyledSpanTitleVueDetailEC et StyledContentVueDetailsEC dans des div
                    pour résoudre rapidement le problème de retour à la ligne, et pour éviter de modifier les composants
                    concernés en risquant de changer d'autres comportements ailleurs.
                    La solution n'est pas idéale, il faudra la modifier un jour.
                */}
                <div>
                  <StyledSpanTitleVueDetailEC>
                    {t.decision}
                  </StyledSpanTitleVueDetailEC>
                </div>
                <div>
                  <StyledContentVueDetailsEC>
                    {nc["commentaireEspaceClient"]}
                  </StyledContentVueDetailsEC>
                </div>
              </StyledDivVueDetailsEC>
            </StyledPaperBodyEC>
          ) : null}
        </div>
      ) : (
        <Loading t={t} /> // ou tout autre composant pour indiquer qu'il n'y a pas d'offre
      )}
    </div>
  );
};
