import { Serveur } from "../../../../../constantes/services/Serveur";
import { equalsBooleanOrStringOrNumber } from "../../../../../constantes/symbols/HelperSymbolsServices";
import { ARTICLE, DEMANDE_DE_PRIX } from "../../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import {
  StyledDivMsgErreur,
  StyledDivRecapGene,
} from "../../../styledComponents/StyledDiv";

/**
 * If the argument is an empty string or undefined, return null, otherwise return a br element.
 * @param champ - the value of the field to be checked
 * @returns A function that returns a JSX element or null.
 */
const genererBrSiChampNonVideOuUndefined = (champ) => {
  if (champ === "" || champ === undefined) {
    return null;
  }
  return <br />;
};

/**
 * This function takes in an image and a list of images and returns an image
 * @returns A React component.
 */
const CheckImageDisplayForRadioButton = ({ img, images, style }) => {
  if (img !== undefined && images !== undefined) {
    const defaultImg = "CommingSoon.png";
    const imgToDisplay =
      images[img] === undefined ? images[defaultImg] : images[img];
    return <img src={imgToDisplay} alt="" style={style ? style : null} />;
  }
  return null;
};

/**
 * If the field has been touched and there's an error, display the error.
 * @param champ - the current field being rendered
 * @param formik - the formik object
 * @returns A function that takes two arguments, champ and formik.
 */
const displayErrorForElementsWithoutErrorHandling = (champ, formik) => {
  return formik.touched[champ.nom.description] &&
    formik.errors[champ.nom.description] ? (
    <StyledDivMsgErreur>
      {formik.errors[champ.nom.description]}
    </StyledDivMsgErreur>
  ) : null;
};

/**
 * If the field has been touched and there's an error, display the error.
 * @param champ - the current field being rendered
 * @param formik - the formik object
 * @returns A function that takes two arguments, champ and formik.
 */
const displayErrorForElementsDropzone = (champ, formik) => {
  return formik.errors[champ.nom.description] ? (
    <StyledDivMsgErreur>
      {formik.errors[champ.nom.description]}
    </StyledDivMsgErreur>
  ) : null;
};

const displayRecapitulatifFile = (listToDisplay, fieldName, isFrom) => {
  return (
    <StyledDivRecapGene>
      <div key={fieldName}>
        {isFrom === DEMANDE_DE_PRIX && listToDisplay["reference"] ? (
          <a
            href={
              Serveur.URL_UPLOADS +
              listToDisplay["reference"] +
              "/" +
              listToDisplay["reference"].slice(2) +
              "_Recapitulatif_Configurateur_" +
              listToDisplay["marque"] +
              ".pdf"
            }
            download
          >
            {listToDisplay["reference"].slice(2) +
              "_Recapitulatif_Configurateur_" +
              listToDisplay["marque"] +
              ".pdf"}
          </a>
        ) : null}
      </div>
    </StyledDivRecapGene>
  );
};

const displayFileArrayIfExist = (
  listToDisplay,
  fieldName,
  fieldDescription,
  isFrom
) => {
  return listToDisplay[fieldName.description] !== undefined &&
    listToDisplay[fieldName.description].length !== 0 ? (
    <StyledDivRecapGene>
      <div>{fieldDescription}</div>
      {listToDisplay[fieldName.description].map((fichier) => {
        return (
          <div key={fichier.name || fichier.url}>
            {isFrom === DEMANDE_DE_PRIX ? (
              <a
                href={
                  Serveur.URL_UPLOADS +
                  listToDisplay["reference"] +
                  "/" +
                  listToDisplay["reference"].slice(2) +
                  "_" +
                  (fichier.name ?? fichier.url)
                }
                download
              >
                {fichier.name}
              </a>
            ) : (
              fichier.name
            )}
          </div>
        );
      })}
    </StyledDivRecapGene>
  ) : null;
};

const displayFieldFromInternalService = (
  currentField,
  t,
  listToDisplay,
  method
) => {
  // if (listToDisplay[currentField.nom.description] === undefined) {
  //   // Don't display the field if both the value and the listToDisplay property are undefined
  //   return null;
  // }
  return (
    <div id={currentField.nom.description} key={currentField.nom.description}>
      {currentField.label + " : "}
      <span>
        {method(t).find((element) => {
          return (
            element.code ===
              parseInt(listToDisplay[currentField.nom.description]) ||
            element.code === listToDisplay[currentField.nom.description] ||
            element.label ===
              parseInt(listToDisplay[currentField.nom.description]) ||
            element.label === listToDisplay[currentField.nom.description]
          );
        })?.name ?? t.nonRenseigne}
      </span>
    </div>
  );
};

const displayFieldFromService = (currentField, listToDisplay, service, t) => {
  const value = service.find((element) => {
    return (
      element.code === parseInt(listToDisplay[currentField.nom.description]) ||
      element.code === listToDisplay[currentField.nom.description] ||
      (element.reference ===
        listToDisplay[currentField.nom.description]?.reference &&
        element.reference !== undefined)
    );
  })?.name;
  if (value === undefined && listToDisplay[currentField.nom.description] === undefined) {
    // Don't display the field if both the value and the listToDisplay property are undefined
    return null;
  }

  return (
    <div id={currentField.nom.description} key={currentField.nom.description}>
      {currentField.label + " : "}
      <span>
        {value
          ? value
          : listToDisplay[currentField.nom.description] !== undefined
          ? listToDisplay[currentField.nom.description]
          : t.masquerService}
      </span>
    </div>
  );
};

const displayFieldBoolean = (currentField, t, listToDisplay, isFrom) => {

  if(isFrom === ARTICLE && !listToDisplay?.horsConfigurateur){
    return null;
  }
  return (
    <div id={currentField.nom.description} key={currentField.nom.description}>
      {currentField.label + " : "}
      <span>
        {!!equalsBooleanOrStringOrNumber(
          listToDisplay[currentField.nom.description]
        )
          ? t.oui
          : t.non}
      </span>
    </div>
  );
};

export {
  genererBrSiChampNonVideOuUndefined,
  CheckImageDisplayForRadioButton,
  displayErrorForElementsWithoutErrorHandling,
  displayErrorForElementsDropzone,
  displayRecapitulatifFile,
  displayFileArrayIfExist,
  displayFieldFromInternalService,
  displayFieldFromService,
  displayFieldBoolean,
};
