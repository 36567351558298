// À cause de problème de dépendances circulaires, le store ne peut pas toujours être importé.
// Ce module permet d'obtenir une référence au store dans ces cas.
// La fonction «setStore» est appelée dans le fichier «store.js» une fois le store initialisé.

let storeInstance = null;

function setStore(store) {
  storeInstance = store;
}

function getStore() {
  if (!storeInstance)
    throw new Error("Le store n'a pas encore été initialisé.");
  return storeInstance;
}

export { getStore, setStore };
