import { useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  CardContent,
} from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { SeeMoreIconEC } from "../../components/SeeMoreIconEC";
import {
  StyledContentCard,
  StyledDivTitleCard,
  StyledDivTitleSubCard,
} from "../../styledComponents/StyledTextEspaceClient";
import { BoutonAccueilEC } from "../../components/espaceClient/BoutonAccueilEC";

const ArticleCard = ({ styles, upToDateArticlesList  }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleArticles = () => {
    navigate(ClientRoutes.VUE_ARTICLE);
  };

  const handleOneArticle = (articleId) => () => {
    navigate(ClientRoutes.VUE_ARTICLE + "?art=" + articleId);
  };

  return (
    <Card
      id="article-card"
      className={styles.articleCard}
      style={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        borderRadius: "8px",
      }}
    >
      <CardContent style={{ flex: 1, padding: "24px" }}>
        <StyledDivTitleCard>{t.genTitleCardArticle}</StyledDivTitleCard>
        <div
          className="articles-container"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {upToDateArticlesList.length === 0 ? (
            <StyledContentCard className={styles.aucuneCommande}>{t.aucuneCommande}</StyledContentCard>
          ) : (
            upToDateArticlesList.map((article, index) => (
              <Card
                key={index}
                className={styles.singleArticleCard}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.333% - 1rem)",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 2px rgba(87, 92, 130, 0.10)",
                  background:
                    "linear-gradient(180deg, rgba(231, 234, 255, 0.10) 0%, rgba(231, 234, 255, 0.50) 100%)",
                }}
              >
                <CardContent style={{ flex: 1 }}>
                  {article.articleReferenceClient ? (
                    <StyledContentCard>
                      {article.articleReferenceClient}
                    </StyledContentCard>
                  ) : null}
                  <StyledContentCard>
                    {article.articleCode}
                  </StyledContentCard>
                  <StyledDivTitleSubCard>
                    {article.articleDesignation}
                  </StyledDivTitleSubCard>
                  <StyledContentCard>
                    {article.modeleMachine}
                  </StyledContentCard>
                  <StyledContentCard>
                    {article.emplacementPiece}
                  </StyledContentCard>
                </CardContent>
                {article.articleEtatValidation !== "100" ||
                article.articleEtatValidation !== "900" ? (
                  <>
                    <SeeMoreIconEC
                      dark={false}
                      method={handleOneArticle(article.articleId)}
                    />
                  </>
                ) : null}
              </Card>
            ))
          )}
        </div>
        <BoutonAccueilEC
          texte={t.genAffTousArticles}
          action={handleArticles}
        />

      </CardContent>
    </Card>
  );
}

export default ArticleCard;
