import { Box, Grid, Modal, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AdminRoutes } from "../../constantes/routes/AdminRoutes";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { ListeComptes } from "./ListeCompte";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  MsgModal,
  StyledDivBackoffice,
  StyledDivButtonValiderAnnuler,
  StyledDivMsgSucces,
  StyledDivRechercherAjouter,
} from "./styledComponents/StyledDivBackoffice";
import { StyledTextfieldRose } from "./styledComponents/StyledTextfieldBackoffice";
import { StyledH1Rose } from "./styledComponents/StyledTextBackoffice";
import {
  StyledButtonAjoutUtilisateur,
  StyledButtonAnnulerRose,
  StyledButtonRose,
} from "./styledComponents/StyledButtonBackoffice";
import { setMessage } from "../slice/MessageReducer";
import Check from "@mui/icons-material/Check";
import {
  deleteOneUtilisateur,
  getAllUtilisateur,
} from "../slice/UtilisateurReducer";
import { ModalBackoffice } from "./components/ModalBackoffice";

export const GestionDesComptes = () => {
  const [styles, t, setRecapitulatif, recapitulatif, setSelectedPage] =
    useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchBarContent, setSearchBarContent] = useState();
  const [open, setOpen] = useState(false);
  const [isDeleteApproved, setIsDeleteApproved] = useState(false);
  const [userToDelete, setUserToDelete] = useState();
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    setSelectedPage(2);
  }, []);

  useEffect(() => {
    if (!isDeleteApproved || !userToDelete) {
      return;
    }
    dispatch(deleteOneUtilisateur(userToDelete))
      .unwrap()
      .then((e) => {
        if (e.code !== 400 && e.code !== 500) {
          dispatch(getAllUtilisateur());
          dispatch(setMessage(t.confirmDeletedUser));
        }
      })
      .catch((e) => {
        dispatch(setMessage(t.errorBdd));
      });
    handleDeleteUnapproved();
    setUserToDelete(undefined);
  }, [isDeleteApproved, dispatch, userToDelete]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteApproved = () => {
    setIsDeleteApproved(true);
  };

  const handleDeleteUnapproved = () => {
    setIsDeleteApproved(false);
  };

  const handleYesModale = () => {
    handleDeleteApproved();
    handleClose();
  };

  const handleNoModale = () => {
    handleDeleteUnapproved();
    handleClose();
  };

  const handleInscription = () => {
    dispatch(setMessage(null));
    navigate(StringToRouteInNavigate(AdminRoutes.INSCRIPTION));
  };

  const handleChange = (event) => {
    setSearchBarContent(event.target.value);
  };

  const resetMessage = () => {
    dispatch(setMessage(null));
  };

  return (
    <Paper onClick={() => resetMessage()}>
      {message ? (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      ) : null}
      <ModalBackoffice
        open={open}
        handleClose={handleClose}
        message={t.confirmDeleteUser}
        handleNoModale={handleNoModale}
        non={t.non}
        handleYesModale={handleYesModale}
        oui={t.oui}
      />
      <StyledDivBackoffice>
        <StyledH1Rose>Liste des comptes collaborateurs FPS</StyledH1Rose>
        <StyledDivRechercherAjouter>
          <Grid item>
            <StyledTextfieldRose
              label="Rechercher"
              variant="outlined"
              size="small"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <SearchIcon sx={{ color: "#CC007B", fontSize: 20 }} />
                ),
              }}
            />
          </Grid>
          <Grid item>
            <StyledButtonAjoutUtilisateur onClick={handleInscription}>
              <AddIcon sx={{ fontSize: 50 }} />
              Ajouter un utilisateur
            </StyledButtonAjoutUtilisateur>
          </Grid>
        </StyledDivRechercherAjouter>
        <ListeComptes
          searchBarContent={searchBarContent}
          actions={{
            handleOpen,
            setUserToDelete,
          }}
        />
      </StyledDivBackoffice>
    </Paper>
  );
};
