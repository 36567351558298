import {
  BoutonConfirmEC,
  BoutonRetourEC,
} from "../../components/espaceClient/BoutonAccueilEC";
import {
  StyledContentCard,
  StyledH1VuesEC,
  StyledLabel,
  StyledLabelNormal,
  StyledP,
} from "../../styledComponents/StyledTextEspaceClient";
import { StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { Loading } from "../../components/Loading";
import { StyledPaperBodyEC } from "../../styledComponents/StyledPaper";
import {
  StyledDivVueDetailsEC,
} from "../../styledComponents/StyledDiv";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  sendModifToFPS,
} from "../../../slice/EspaceClientReducer";
import { OUTLINED } from "../../components/generateFormulary/commonParameters/CommonParameters";
import { StyledTextfield } from "../../styledComponents/StyledTextfield";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export const DetailsContact = ({
  t,
  actions,
  state: { styles, detailsContact },
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [tableauOffres, setTableauOffres] = useState([]);
  const [titre, setTitre] = useState(detailsContact[0].titre);
  const [prenom, setPrenom] = useState(detailsContact[0].prenom);
  const [nom, setNom] = useState(detailsContact[0].nom);
  const [fonction, setFonction] = useState(detailsContact[0].fonction);
  const [email, setEmail] = useState(detailsContact[0].email);
  const [telephone, setTelephone] = useState(detailsContact[0].telephone);
  const [refusEmailing, setRefusEmailing] = useState(
    detailsContact[0].refus_emailing !== "Faux"
  );
  const [supprimerContact, setSupprimerContact] = useState(false);

  const [openWaitingModal, setOpenWaitingModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const triggerHandleRetour = () => {
    const url = new URL(window.location.href);

    actions.setIsContactByIdSet(false);

    url.searchParams.delete("contact");
    window.history.pushState({}, "", url);
  };

  const triggerSendModif = () => {
    const anciennesInfos = detailsContact[0];
    const nouvellesInfos = {
      titre,
      prenom,
      nom,
      fonction,
      email,
      telephone,
      refusEmailing,
      supprimerContact,
    };

    setOpenWaitingModal(true);

    dispatch(sendModifToFPS({ anciennesInfos, nouvellesInfos }))
      .unwrap()
      .then((response) => {
        setOpenWaitingModal(false);
        setResultMessage(
          "Le mail a bien été envoyé. Votre demande de modification va être prise en compte par l'équipe RC Modèles/Moovprotect."
        );
        setOpenResultModal(true);
      })
      .catch((error) => {
        setOpenWaitingModal(false);
        setResultMessage(
          "Une erreur est survenue lors de l'envoi du mail. Veuillez réessayer plus tard."
        );
        setOpenResultModal(true);
      });
  };

  const afficherBoutonRetour = (texte, action, backgroundColor) => {
    return (
      <BoutonRetourEC
        texte={texte}
        action={action}
        backgroundColor={backgroundColor}
      />
    );
  };

  useEffect(() => {
    if (detailsContact) {
      const offresArray = Object.values(detailsContact).filter(
        (value) => typeof value === "object" && value !== null
      );
      setTableauOffres(offresArray);
    }
  }, [detailsContact, location]);

  return (
    <div style={{ padding: "1rem" }}>
      {afficherBoutonRetour(
        t.retourListeContact,
        triggerHandleRetour,
        "#F8F9FF"
      )}
      {detailsContact ? (
        <div>
          <StyledH1VuesEC>{t.modifierContact}</StyledH1VuesEC>
          <StyledHrEspaceClient></StyledHrEspaceClient>
          <StyledP>{t.descrVueDetailContact}</StyledP>
          <StyledPaperBodyEC elevation={0} style={{ marginTop: "2rem" }}>
            <StyledDivVueDetailsEC
              style={{
                borderRadius: "8px",
                boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
              }}
            >
              <Grid container spacing={2} style={{ padding: "24px" }}>
                <Grid xs={12} md={6}>
                  {/* {genererFormulaire(
                    contactFormulaireECPart1(styles, t, detailsContact[0]),
                    detailsContact[0]
                  )} */}
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Titre"
                      variant={OUTLINED.description}
                      value={titre}
                      size="small"
                      onChange={(e) => setTitre(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Prénom"
                      variant={OUTLINED.description}
                      value={prenom}
                      size="small"
                      onChange={(e) => setPrenom(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Nom"
                      variant={OUTLINED.description}
                      value={nom}
                      size="small"
                      onChange={(e) => setNom(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={6} className={styles.cssGridItem}>
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Fonction"
                      variant={OUTLINED.description}
                      value={fonction}
                      size="small"
                      onChange={(e) => setFonction(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Téléphone"
                      variant={OUTLINED.description}
                      value={telephone}
                      size="small"
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextfield
                      className={styles.cssTextfield}
                      label="Email"
                      variant={OUTLINED.description}
                      value={email}
                      size="small"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={refusEmailing}
                      onChange={(e) => setRefusEmailing(e.target.checked)}
                    />
                  }
                  label={<StyledLabelNormal>{t.refusEmailing}</StyledLabelNormal>}
                />

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={supprimerContact}
                        onChange={(e) => setSupprimerContact(e.target.checked)}
                      />
                    }
                    label={<StyledLabel>{t.supprimerContact}</StyledLabel>}
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <Grid item>
                {/* <StyledDivButton> */}
                <BoutonConfirmEC
                  texte={t.envoiModification}
                  action={triggerSendModif}
                  backgroundColor={"#F8F9FF"}
                />
                {/* </StyledDivButton> */}
              </Grid>
            </StyledDivVueDetailsEC>
          </StyledPaperBodyEC>
        </div>
      ) : (
        <Loading t={t} /> // ou tout autre composant pour indiquer qu'il n'y a pas d'offre
      )}
      {/* Modale d'attente */}
      <Modal
        open={openWaitingModal}
        onClose={() => setOpenWaitingModal(false)}
        aria-labelledby="waiting-modal-title"
        aria-describedby="waiting-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="waiting-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Envoi du mail en cours...
          </Typography>
          <Typography
            id="waiting-modal-description"
            sx={{ mt: 2 }}
            style={{ textAlign: "center" }}
          >
            <CircularProgress />
          </Typography>
        </Box>
      </Modal>

      {/* Modale de résultat */}
      <Modal
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        aria-labelledby="result-modal-title"
        aria-describedby="result-modal-description"
      >
        <Box sx={style}>
          {/* <StyledImgHeader
            src={importAllSpec(false)["Logo.png"]}
            className={styles.logo}
          />
          <StyledImgHeader
            src={importAllSpec(true)["Logo.png"]}
            className={styles.logoMoovEC}
            // style={{ maxHeight: "3.5em!important" }}
          /> */}
          <StyledContentCard id="result-modal-description">
            {resultMessage}
          </StyledContentCard>
        </Box>
      </Modal>
    </div>
  );
};
