import React from 'react';
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { GuestRoutes } from "../../constantes/routes/GuestRoutes";
import { StringToRouteInNavigate } from "../../helper/StringParser";

/**
 * If the user is logged in and has the required permissions, then render the Outlet component.
 * Otherwise, redirect to the authentication page
 * @returns A component that will redirect to the authentication page if the user is not logged in or
 * if the user does not have the right permissions.
 */
export const AuthentificationRoute = ({...props}) => {
  const { isLoggedIn, accessToken } = useSelector((state) => state.authentification);
  const [styles, t, setUpToDateReferenceByIdList, upToDateReferenceByIdList, setSelectedPage] = useOutletContext();

  return (props.permissions?.includes(accessToken?.data?.role) && isLoggedIn)
    ? <Outlet context={[styles, t, setUpToDateReferenceByIdList, upToDateReferenceByIdList, setSelectedPage]}/>
    : <Navigate to={StringToRouteInNavigate(GuestRoutes.AUTHENTIFICATION)} />
}
