import { Serveur } from '../../constantes/services/Serveur';
import { defaultLang, nativeLang } from '../../config/i18nConfig.js';
import { fetchJsonFromServer } from '../../helper/fetchUtil.js';

// le répertoire «src/languages/» contient les fichiers de langue par défaut : «fr.json», «en.json»
const langUrl = require.context('../../languages/', false, /\.(json)$/);

// Liste des identifiants à contrôler pour valider une langue
const controlIdList = [
  'souffletEnduit',
  'souffletPlisse',
  'enrouleur',
  'protectionTelescopique',
  'compensateur',
];

/**
 * It gets the json file from the langUrl object.
 * @param lang - The language you want to translate to.
 * @returns The JSON object.
 */
const getJsonFromFileByLang = (lang) => {
  lang = lang.toLowerCase()
  return langUrl
    .keys()
    .filter((fileName) => { // filename: «./fr.json»
      return fileName.replace('./', '').split('.')[0]===lang;
    })
    .map((fileName) => {
      return langUrl(fileName);
    })
    .find((elem, index) => index === 0);
}


const getAllTraductions = async () => {
  return await fetchJsonFromServer(Serveur.URL_TRADUCTIONS);
};

const getAllTradInfoBulles = async () => {
  return await fetchJsonFromServer(Serveur.URL_TRADUCTIONS_INFOBULLES);
};


/**
 * renvoie une liste (Objet JavaScript) associant les identifiants de traduction au messages associé pour la langue demandée.
 * Les traductions dont le message est une chaîne vide sont filtrés.
 * @param {object} list : liste de traduction renvoyée par l'API où chaque entrée associe un identifiant de traduction à
 * la liste des traductions pour les différents languages supportés.
 * @param {string} lang : code de la langue pour extraire les traductions.
 * @returns {object} : liste associant un identifiant de traduction à son message associé.
 */
const createTranslationMap = (list, lang) => {
  return list.reduce((map, item) => {
    const message = item[lang];
    if (message.trim().length > 0)
      map[item['Code site']] = message;
    return map;
  }, {});
};


/**
 * provoque le chargement des messages de traduction en fonction de la langue demandée. Si aucune langue n'est demandée
 * alors la ou les langues configurées dans le navigateur sont utilisées.
 * Les traductions sont extraites de différentes sources et fusionnées dans l'ordre de priorité suivant :
 * - API de traduction pour les messages
 * - API de traduction pour les info-bulles
 * - fichier « en.json » local
 * - fichier « fr.json » local
 * Les deux fichiers locaux sont les moins prioritaires.
 *
 * @param {lang} : Code langue sur deux lettres en majscule. Si ce paramètre n'est pas fourni alors la première supportée
 *                 parami les langues configurées dans le navigateur est utilisée.
 *
 * @returns {[string, string[]]} : Un tableau où le premier élément est le code de la langue sélectionnée et
 *                                 le deuxième est un tableau des codes langues supportés par la traduction.
 *
 */
const loadTranslations = async (acceptedLangs) => {
  if (! (acceptedLangs instanceof Array))
    throw new Error("Argument «acceptedLangs» invalide");

  // récupérer les traductions stockées dans les fichiers de traduction stockés en local
  const nativeLocalTranslations = getJsonFromFileByLang(nativeLang);
  if (! nativeLocalTranslations)
    throw new Error("Traductions locales introuvables");

  const defaultLocalTranslations = getJsonFromFileByLang(defaultLang);
  if (! defaultLocalTranslations)
    throw new Error("Traductions locales introuvables");

  let translations = null;
  let supportedLangs = null;
  let selectedLang = null;

  // récupérer les traductions fournies par l'API
  try {
    let translationsFromService = await getAllTraductions();

    // extraire la liste des langues supportées (entrée «Code site» = «languageName»)
    const ENTREE_CODE_SITE = 'Code site';
    const languageNameList = translationsFromService.find( obj => obj[ENTREE_CODE_SITE] === "languageName" );
    if (languageNameList === undefined)
      throw new Error(`Liste des langues introuvable (entrée «${ENTREE_CODE_SITE}»)`);

    // dans cette liste, ne conserver que les entrées dont le code est sur 2 caractères (code langue)
    const existingCodeLang = Object.entries(languageNameList)
      .filter((el) => el[0].length === 2)
      .map((el) => {
        return { code: el[0], name: el[1] };
      });

    // ne garder que les langues qui ont une traduction pour les entrées de contrôle
    const fieldsDeterminingLangOk = translationsFromService.filter(
      (obj) => controlIdList.includes(obj['Code site'])
    );
    supportedLangs = existingCodeLang.filter(
      (el) => fieldsDeterminingLangOk.filter((obj) => obj[el.code]).length === controlIdList.length
    );

    // parmi les langues supportées dans l'API de traduction, choisir la première langue demandée
    selectedLang = acceptedLangs.find(navigatorLang =>
      supportedLangs.find( lang => lang.code === navigatorLang )
    ) || defaultLang;

    // créer une liste de traductions à partir des traductions renvoyées par l'API
    translationsFromService = createTranslationMap(translationsFromService, selectedLang);

    // créer une liste des traductions des info-bulles
    let translationsInfoBulleFromService = await getAllTradInfoBulles();
    if (! translationsInfoBulleFromService )
      throw new Error("Traductions des info-bulles introuvables");
    translationsInfoBulleFromService = createTranslationMap(translationsInfoBulleFromService, selectedLang);

    // priorité pour la fusion des langues locales : français si langue demandée, sinon anglais
    let primaryLocalTranslations, secondaryLocalTranslations
    if (selectedLang === nativeLang) {
      // français prioritaire
      primaryLocalTranslations = nativeLocalTranslations
      secondaryLocalTranslations = defaultLocalTranslations
    }
    else {
      // anglais prioritaire
      primaryLocalTranslations = defaultLocalTranslations
      secondaryLocalTranslations = nativeLocalTranslations
    }

    // fusionner les listes
    translations = { ...secondaryLocalTranslations, ...primaryLocalTranslations, ...translationsInfoBulleFromService, ...translationsFromService };
  }
  catch (error) {
    console.log(error);
    // en cas d'erreur, se rabattre sur les langues stockées en local
    translations = { ...nativeLocalTranslations, ...defaultLocalTranslations};
    supportedLangs = [
      { code: defaultLang, name: defaultLang },
      { code: nativeLang, name: nativeLang }
    ];
    // FIXME faut-il se rabatte sur le français ou l'anglais si l'API de traduction n'est pas dispo ?
    selectedLang = defaultLang;
  }

  return [
    selectedLang,
    translations,
    supportedLangs
  ]
}

const initializeTranslations = async () => {
  // au chargement charger la première langue supportée parmi celle du navigateur
  let navigatorLanguages = navigator.languages.map(lang => lang.slice(0, 2).toUpperCase());
  const acceptedLangs = [...new Set(navigatorLanguages)]; // retirer les doublons
  const [ selectedLang, translations, supportedLangs ] = await loadTranslations(acceptedLangs);
  return { selectedLang, translations, supportedLangs }
}

const loadLanguage = async (lang) => {
  if (typeof(lang) !== 'string')
    throw new Error("Argument lang invalide");
  if (lang.length !== 2)
    throw new Error(`Invalid code lang «${lang}»`);
  const [ selectedLang, translations, supportedLangs ] = await loadTranslations([lang]);
  return translations;
}

const i18nService = {
  initializeTranslations,
  loadLanguage
}

export default i18nService;
