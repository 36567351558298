//Librairies de fonctionnement
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslations } from "../../components/hook/useTranslations";
import {
  StyledH1VuesEC,
  StyledP,
} from "../../styledComponents/StyledTextEspaceClient";
import {
  StyledDivWrapperVues,
  StyledHrEspaceClient,
  StyledHrEspaceClientRose,
} from "../../styledComponents/StyledDivEspaceClient";

export const VueService = () => {
  const { t } = useTranslations();

  return (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarServices}</StyledH1VuesEC>
      <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
      <StyledP>{t.descrVueService}</StyledP>
    </StyledDivWrapperVues>
  );
};
