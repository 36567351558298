import { useSelector } from 'react-redux';

const selectAuthentification = (state) => ({
  isLoggedIn : state.authentification.isLoggedIn,
  clientId: state.authentification.clientId,
  accesTokenEC : state.authentification.accesTokenEC
});

const useAuthentification = () => {
  // ATTENTION : laisser l'argument de useSelector sous forme de fonction nommée !
  // (pour que la référence reçue par useSelector ne change pas à chaque fois avec une fonction anonyme)
  return useSelector(selectAuthentification);
};

export { useAuthentification };
