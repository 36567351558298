import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Paper } from "@mui/material";
import { addOneUtilisateur } from "../../slice/UtilisateurReducer";
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import { Formulaire } from "../components/Formulaire";
import { AdminRoutes } from "../../../constantes/routes/AdminRoutes";
import { formAjouterUtilisateur } from "../../../constantes/champsFormulaires/FormAjouterUtilisateur";
import { validAjouterUtilisateur } from "../ChampsFormulaires/validationsFormulaires/ValidAjouterUtilisateur";
import {
  StyledButtonAnnulerOrange,
  StyledButtonOrange,
} from "../styledComponents/StyledButtonBackoffice";
import {
  StyledDivBackoffice,
  StyledDivButtonValiderAnnuler,
  StyledDivMsgEchec,
} from "../styledComponents/StyledDivBackoffice";
import {
  StyledH1Orange,
  StyledP,
} from "../styledComponents/StyledTextBackoffice";
import { setMessage } from "../../slice/MessageReducer";
import CloseIcon from "@mui/icons-material/Close";

export const AjouterUtilisateur = () => {
  const [styles, t, setRecapitulatif, recapitulatif, setSelectedPage] =
    useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message } = useSelector((state) => state.message);

  const utilisateur = {
    nom: "",
    prenom: "",
    email: "",
    motDePasse: "",
    role: "",
  };

  const handleInscription = (formData) => {
    dispatch(addOneUtilisateur(formData))
      .unwrap()
      .then((e) => {
        if (e.code !== 400) {
          dispatch(setMessage(e.information));
          if (e.code !== 206) {
            navigate(StringToRouteInNavigate(AdminRoutes.GESTION_COMPTES));
          }
        }
      })
      .catch((e) => {});
  };

  const handleAnnuler = () => {
    dispatch(setMessage(null));
    navigate(StringToRouteInNavigate(AdminRoutes.GESTION_COMPTES));
  };

  const outletContext = {
    t,
    styles,
  };

  return (
    <Paper>
      {message ? (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red" }} />
          {message}
        </StyledDivMsgEchec>
      ) : null}
      <StyledDivBackoffice>
        <StyledH1Orange>Ajout d’un utilisateur</StyledH1Orange>
        <StyledP>
          Tous les champs sont obligatoires pour ajouter un utilisateur.
        </StyledP>
        {utilisateur !== null ? (
          <Formulaire
            handleInteractionWithBdd={handleInscription}
            initialValues={utilisateur}
            formType={formAjouterUtilisateur()}
            schemaDeValidation={validAjouterUtilisateur}
            outletContext={outletContext}
            from="Créer"
          />
        ) : null}
        <StyledDivButtonValiderAnnuler>
          <StyledButtonAnnulerOrange onClick={handleAnnuler}>
            Annuler
          </StyledButtonAnnulerOrange>
          <StyledButtonOrange form="utilisateurFormulaire" type="submit">
            Ajouter utilisateur
          </StyledButtonOrange>
        </StyledDivButtonValiderAnnuler>
      </StyledDivBackoffice>
    </Paper>
  );
};
