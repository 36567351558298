import React, { useEffect, useState } from "react";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import {
  StyledDivWrapperVues,
  StyledHrEspaceClientRose,
} from "../../styledComponents/StyledDivEspaceClient";
import { TooltipEC } from "../../components/espaceClient/TooltipEC";
import {
  StyledDivMsgEchec,
  StyledDivMsgSucces,
} from "../../styledComponents/StyledDivBackoffice";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  getAllCommandesEC,
  getAllContacts,
  getAllFamilleArticle,
  getOneCommandeEC,
  getOneContact,
} from "../../../slice/EspaceClientReducer";
import { ListeCommande } from "./ListeCommande";
import ErrorBoundary from "../../components/ErrorBoundary";
import { DetailsCommande } from "./DetailsCommande";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PersonIcon } from "../../components/espaceClient/PersonIcon";
import { getAllSecteurs } from "../../../slice/SecteurReducer";
import { getAllFonctions } from "../../../slice/FonctionReducer";
import { getAllPays } from "../../../slice/PaysReducer";
import { EC_COLORS } from "../common/EcColor";

export const VueCommande = () => {
  const dispatch = useDispatch();
  const { clientId } = useAuthentification();
  const [params] = useSearchParams();

  const { message } = useSelector((state) => state.message);

  //SERVICES
  const [checked, setChecked] = useState(
    !!(localStorage.getItem("selectedValue") &&
      localStorage.getItem("selectedValue") !== "all")
  );
  const [isOrderByIdSet, setIsOrderByIdSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue") || "all"
  );

  const { commandeById, familleArticleList, contactList, detailsContact } =
    useSelector((state) => state.espaceClient);
  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect(() => {
    const commandeId = params.get("commande");

    if (!commandeId) {
      setIsOrderByIdSet(false);
      return;
    }

    dispatch(getOneCommandeEC({ clientId, commandeId }));
  }, [dispatch, params]);

  useEffect(() => {
    if (!commandeById) {
      setIsOrderByIdSet(false);
      return;
    }
    setIsOrderByIdSet(true);
  }, [commandeById]);

  useEffect(() => {
    if (clientId !== null) {
      const contactId =
        localStorage.getItem("selectedValue") !== "all"
          ? localStorage.getItem("selectedValue")
          : "%25";

      dispatch(getAllContacts({ clientId }));
      dispatch(getOneContact({ clientId, contactId }));
      dispatch(getAllFamilleArticle({ clientId }));
      dispatch(getAllSecteurs());
      dispatch(getAllFonctions());
      dispatch(getAllPays());
    }
  }, [dispatch, selectedValue]);

  useEffect(() => {
    if (clientId !== null) {
      const contactId =
        localStorage.getItem("selectedValue") &&
        localStorage.getItem("selectedValue") !== "all"
          ? localStorage.getItem("selectedValue")
          : "%25";

      dispatch(getAllCommandesEC({ clientId, contactId }));
      dispatch(getAllFamilleArticle({ clientId }));
    }
  }, [dispatch, params, selectedValue]);

  useEffect(() => {
    const paramCommande = params.get("commande");

    if (!paramCommande) {
      setIsOrderByIdSet(false);
    }
  }, []);

  // Fonction de gestion du changement de sélection
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem("selectedValue", event.target.value);
  };

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    commandeById,
    isOrderByIdSet,
    familleArticleList,
    secteursList,
    fonctionsList,
    paysList,
    detailsContact,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsOrderByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isOrderByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarCommandes}</StyledH1VuesEC>
      <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
      <StyledP>{t.descrVueCommande}</StyledP>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <FormControl style={{ marginBottom: "0.875rem", width: "300px" }}>
          <InputLabel id="avatar-select-label">{t.jeSuis}</InputLabel>
          <Select
            labelId="avatar-select-label"
            id="avatar-select"
            label={t.jeSuis}
            value={selectedValue}
            onChange={handleSelectChange}
            startAdornment={<PersonIcon />}
          >
            <MenuItem value="all">{t.toutLeMonde}</MenuItem>
            {contactList
              ? contactList.map((contact, index) => (
                  <MenuItem key={index} value={contact.contactId}>
                    {contact.contact}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </div>
      <div>
        {displayError()}
        <ListeCommande
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
        <TooltipEC texte={t.infoBulleCommande} color={EC_COLORS.VIOLET} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <DetailsCommande
          t={t}
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </ErrorBoundary>
  );
};
