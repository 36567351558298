import axios from "axios";
import { Serveur } from "../../constantes/services/Serveur";
import { Formulary } from "../../constantes/services/Formulary";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";

const sendFormulary = async (data) => {
  const SEND_FORMULARY_ENDPOINT = Serveur.URL_API + Formulary.SEND_FORMULARY;
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout'));
    }, 180000); // Délai de 180 secondes (3 minutes)
  });

  try {
    const response = await Promise.race([
      axios.post(SEND_FORMULARY_ENDPOINT, data),
      timeoutPromise
    ]);

    return response;
  } catch (error) {
    // Si l'erreur est une erreur de timeout, renvoyer une page d'erreur
    if (error.message === 'Timeout') {
      console.log("Temps dépassé");
    }
    return error;
  }
};

const sendFormularyContact = async (data) => {
  const SEND_FORMULARY_ENDPOINT =
    Serveur.URL_API + Formulary.SEND_FORMULARY_CONTACT;
  return await axios.post(SEND_FORMULARY_ENDPOINT, data);
};

const sendFormularyContactSOS = async (data) => {
  const SEND_FORMULARY_ENDPOINT =
    Serveur.URL_API + Formulary.SEND_FORMULARY_CONTACT_SOS;
  return await axios.post(SEND_FORMULARY_ENDPOINT, data);
};

const sendFormularyRechercher = async (data) => {
  const SEND_FORMULARY_ENDPOINT =
    Serveur.URL_API + Formulary.SEND_FORMULARY_RECHERCHER;
  return await axios.post(SEND_FORMULARY_ENDPOINT, data);
};

const getAllReference = async (token) => {
  const GET_ALL_ENDPOINT = Serveur.URL_API + Formulary.GET_ALL_REF;
  const reponse = await axios.post(GET_ALL_ENDPOINT, token);

  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
  return reponse;
};

const getAllInfoByRef = async (reference, token) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...token,
    id: reference,
  };

  const reponse = await axios.post(
    Serveur.URL_API + Formulary.SEND_ALL_INFO_BY_REF + reference,
    config
  );

  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }

  return reponse;
};

const deleteDDPByRef = async (marque, reference, token) => {
  const ref = marque + reference;
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...token,
    reference: ref,
  };

  const reponse = await axios.post(
    Serveur.URL_API + Formulary.DELETE_DDP_BY_REF + ref,
    config
  );

  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }

  return reponse;
};

const getOdpById = async (id, token) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...token,
    id: id,
  };
  const reponse = await axios.post(Serveur.URL_ODP_BY_ID, config);
  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }

  return reponse;
};

const getArtByIdFps = async (id, token) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...token,
    id: id,
  };
  const reponse = await axios.post(Serveur.URL_ART_BY_ID, config);

  return reponse;
};

const getArtByIdRole = async (id, aff) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    id: id,
    role: aff,
  };
  const reponse = await axios.post(Serveur.URL_ART_BY_ID, config);

  return reponse;
};

const forceSendMailAndFilesByDDP = async (reference, token) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    ...token,
    id: reference,
  };

  const reponse = await axios.post(
    Serveur.URL_API + Formulary.FORCE_SEND_MAIL_AND_FILES_BY_DDP,
    config
  );

  if (reponse.data.accessToken) {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, reponse.data.accessToken);
  } else {
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }

  return reponse;
};

const formularyService = {
  sendFormulary,
  sendFormularyContact,
  sendFormularyRechercher,
  getAllReference,
  getAllInfoByRef,
  deleteDDPByRef,
  getOdpById,
  getArtByIdFps,
  getArtByIdRole,
  forceSendMailAndFilesByDDP,
  sendFormularyContactSOS,
};

export default formularyService;
