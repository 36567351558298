import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { DashboardIcon } from "../../redux/vue/components/espaceClient/DashboardIcon";

import { ArticleIcon } from "../../redux/vue/components/espaceClient/ArticleIcon";
import { ODPIcon } from "../../redux/vue/components/espaceClient/ODPIcon";
import { CommandeIcon } from "../../redux/vue/components/espaceClient/CommandeIcon";
import { NCIcon } from "../../redux/vue/components/espaceClient/NCIcon";
import { ContactIcon } from "../../redux/vue/components/espaceClient/ContactIcon";
import { ServiceIcon } from "../../redux/vue/components/espaceClient/ServiceIcon";
import { LogoutIcon } from "../../redux/vue/components/espaceClient/LogoutIcon";

import { useDispatch } from "react-redux";
import { deconnexionEC } from "../../redux/slice/AuthentificationReducer";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

export const Sidebar = ({
  t,
  setUpToDateArticleByIdList,
  setIsOffreByIdSet,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState("/");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  const listItemStyle = {
    fontFamily: "Dosis",
    fontSize: "1rem",
    fontWeight: "600",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    listStyleType: "none",
    marginBottom: "16px",
    width: "100%",
    color: "#2A375C",
    lineHeight: "120%",
  };

  const activeListItemStyle = {
    ...listItemStyle,
    boxShadow: "0 -5px 5px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1)",
    paddingTop: "0.5rem",
    color: "#1C243C",
    fontWeight: "800",
  };

  const disabledListItemStyle = {
    ...listItemStyle,
    // backgroundColor: "#e0e0e0",
    cursor: "not-allowed",
    fontWeight: "0",
  };

  const spanStyle = {
    marginTop: "8px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  };

  const disabledLinkStyle = {
    ...linkStyle,
    pointerEvents: "none",
    color: "#a0a0a0",
  };

  const handleLogout = () => {
    dispatch(deconnexionEC())
      .unwrap()
      .catch(() => {});
  };

  const actionSidebar = (to) => {
    setActivePage(to);
    setUpToDateArticleByIdList(null);
    setIsOffreByIdSet(false);
  };

  const renderLink = (to, IconComponent, text, index) => {
    const iconColor = index % 2 === 0 ? "#CC007B" : "#EC8812";

    const IconWithColor = () => (
      <IconComponent style={{ color: iconColor }} activePage={activePage} />
    );

    // Condition to render the "Service" link as disabled
    if (to === ClientRoutes.VUE_SERVICE) {
      return (
        <li key={to} style={disabledListItemStyle}>
          <div style={disabledLinkStyle}>
            <IconWithColor />
            <span style={spanStyle}>{text}</span>
          </div>
        </li>
      );
    }

    if (to === "/Logout") {
      return (
        <li
          key={to}
          style={{
            ...listItemStyle,
            fontWeight: "600",
          }}
        >
          <div style={linkStyle} onClick={handleLogout}>
            <IconWithColor />
            <span style={spanStyle}>{text}</span>
          </div>
        </li>
      );
    }

    return (
      <li
        key={to}
        style={activePage === to ? activeListItemStyle : listItemStyle}
      >
        <Link to={to} style={linkStyle} onClick={() => actionSidebar(to)}>
          <IconWithColor />
          <span
            style={{
              fontWeight: activePage === to ? "800" : "600",
              fontSize: "1rem",
              margin: "8px",
            }}
          >
            {text}
          </span>
        </Link>
      </li>
    );
  };

  const links = [
    {
      to: ClientRoutes.ACCUEIL_EC,
      icon: DashboardIcon,
      text: t.titleSideBarDashboard,
    },
    {
      to: ClientRoutes.VUE_ARTICLE,
      icon: ArticleIcon,
      text: t.titleSideBarArticles,
    },
    {
      to: ClientRoutes.VUE_OFFRE,
      icon: ODPIcon,
      text: t.titleSideBarOffres,
    },
    {
      to: ClientRoutes.VUE_COMMANDE,
      icon: CommandeIcon,
      text: t.titleSideBarCommandes,
    },
    {
      to: ClientRoutes.VUE_NC,
      icon: NCIcon,
      text: t.titleSideBarNC },
    {
      to: ClientRoutes.VUE_SERVICE,
      icon: ServiceIcon,
      text: t.titleSideBarServices,
    },
    {
      to: ClientRoutes.VUE_CONTACT,
      icon: ContactIcon,
      text: t.titleSideBarContact,
    },
    { to: "/Logout", icon: LogoutIcon, text: t.titleSideBarLogout },
  ];

  return (
    <aside
      id="default-sidebar"
      style={{ backgroundColor: "white", width: "112px" }}
    >
      <div>
        <ul style={{ padding: "0", marginTop: "2rem" }}>
          {links.map((link, index) =>
            renderLink(link.to, link.icon, link.text, index)
          )}
        </ul>
      </div>
    </aside>
  );
};
