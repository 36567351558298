import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Pagination } from "@mui/material";
import { StyledGridLignesTabOrangeEC } from "../../../styledComponents/StyledGridBackoffice";
import { StyledDivPagination } from "../../../styledComponents/StyledDivBackoffice";
import { OffreByArtItem } from "./OffreByArtItem";

export const ListeOffreByArticle = ({ actions, state }) => {
  const [searchBarContentCodeOffre, setSearchBarContentCodeOffre] = useState();
  const [searchBarContentReferenceOffre, setSearchBarContentReferenceOffre] = useState();
  const [searchBarContentNumeroLigne, setSearchBarContentNumeroLigne] =
    useState();
  const [searchBarContentMarque, setSearchBarContentMarque] = useState();
  const [searchBarContentQuantite, setSearchBarContentQuantite] = useState();
  const [searchBarContentPrixUnitaire, setSearchBarContentPrixUnitaire] =
    useState();
  const [searchBarContentTotalHT, setSearchBarContentTotalHT] = useState();
  const [searchBarContentDelais, setSearchBarContentDelais] = useState();
  const [searchBarContentStatut, setSearchBarContentStatut] = useState();
  const [searchBarContentCodeCommande, setSearchBarContentCodeCommande] =
    useState();
  const [searchBarContentDesignation, setSearchBarContentDesignation] =
    useState();
  const [searchBarContentReponse, setSearchBarContentReponse] = useState();

  const [sortedCodeOffre, setSortedCodeOffre] = useState();
  const [sortedReferenceOffre, setSortedReferenceOffre] = useState();
  const [sortedNumeroLigne, setSortedNumeroLigne] = useState();
  const [sortedMarque, setSortedMarque] = useState();
  const [sortedQuantite, setSortedQuantite] = useState();
  const [sortedTotalHT, setSortedTotalHT] = useState();
  const [sortedPrixUnitaire, setSortedPrixUnitaire] = useState();
  const [sortedDelais, setSortedDelais] = useState();
  const [sortedStatut, setSortedStatut] = useState();
  const [sortedCodeCommande, setSortedCodeCommande] = useState();
  const [sortedDesignation, setSortedDesignation] = useState();
  const [sortedReponse, setSortedReponse] = useState();

  const { offreByArtIdList } = useSelector((state) => state.espaceClient);
  const [filteredList, setFilteredList] = useState();
  const [pagination, setPagination] = useState({
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  const defineFilteredList = (searchBarContent, key) => {
    if (!offreByArtIdList) {
      return;
    }
    return offreByArtIdList.filter((item) => {
      if (searchBarContent === undefined) {
        return item;
      } else {
        const itemValue = item[key];
        if (Array.isArray(itemValue)) {
          // Vérifiez si l'une des valeurs du tableau correspond au contenu de la barre de recherche
          return itemValue.some((value) =>
            value
              .toString()
              .toLowerCase()
              .includes(searchBarContent.toLowerCase())
          )
            ? item
            : null;
        } else {
          // Vérifiez directement la valeur
          return itemValue
            .toString()
            .toLowerCase()
            .includes(searchBarContent.toLowerCase())
            ? item
            : null;
        }
      }
    });
  };

  const defineSortedList = (sortedDownOrUp, key) => {
    if (sortedDownOrUp === "up") {
      setFilteredList(
        [...offreByArtIdList].sort((a, b) => (a[key] > b[key] ? 1 : -1))
      );
    }
    if (sortedDownOrUp === "down") {
      setFilteredList(
        [...offreByArtIdList].sort((a, b) => (b[key] > a[key] ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentCodeOffre, "offreCode"));
  }, [searchBarContentCodeOffre, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentReferenceOffre, "offreReference"));
  }, [searchBarContentReferenceOffre, offreByArtIdList]);


  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentNumeroLigne, "ligne"));
  }, [searchBarContentNumeroLigne, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentMarque, "marque"));
  }, [searchBarContentMarque, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentQuantite, "quantite"));
  }, [searchBarContentQuantite, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentTotalHT, "totalHT"));
  }, [searchBarContentTotalHT, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentPrixUnitaire, "prixUnitaireHT")
    );
  }, [searchBarContentPrixUnitaire, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentDelais, "miseADisposition")
    );
  }, [searchBarContentDelais, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(defineFilteredList(searchBarContentStatut, "statut"));
  }, [searchBarContentStatut, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentCodeCommande, "codeCommande")
    );
  }, [searchBarContentCodeCommande, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentDesignation, "articleDesignation")
    );
  }, [searchBarContentDesignation, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    setFilteredList(
      defineFilteredList(searchBarContentReponse, "reponseLigne")
    );
  }, [searchBarContentReponse, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedCodeOffre, "offreCode");
  }, [sortedCodeOffre, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedReferenceOffre, "offreReference");
  }, [sortedReferenceOffre, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedNumeroLigne, "ligne");
  }, [sortedNumeroLigne, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedMarque, "marque");
  }, [sortedMarque, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedQuantite, "quantite");
  }, [sortedQuantite, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedTotalHT, "totalHT");
  }, [sortedTotalHT, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedPrixUnitaire, "prixUnitaireHT");
  }, [sortedPrixUnitaire, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedDelais, "miseADisposition");
  }, [sortedDelais, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedStatut, "statut");
  }, [sortedStatut, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedCodeCommande, "codeCommande");
  }, [sortedCodeCommande, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedDesignation, "articleDesignation");
  }, [sortedDesignation, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList(sortedReponse, "reponseLigne");
  }, [sortedReponse, offreByArtIdList]);

  useEffect(() => {
    if (!offreByArtIdList) {
      return;
    }
    defineSortedList("up", "ligne");
  }, [offreByArtIdList]);

  useEffect(() => {
    if (!filteredList) {
      return;
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageCount: Math.ceil(filteredList.length / prevPagination.numberPerPage),
      currentData: filteredList.slice(
        pagination.offset,
        pagination.offset + prevPagination.numberPerPage
      ),
    }));
  }, [pagination.offset, filteredList]);

  const handlePageClick = (event, page) => {
    const offset = (page - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const afficherListeArticle = pagination.currentData.map((item) => {
    return (
      <OffreByArtItem
        key={item.ligneOffreId}
        id={item.ligneOffreId}
        state={state}
        {...item}
        searchBarAction={{
          setSearchBarContentCodeOffre,
          setSearchBarContentReferenceOffre,
          setSearchBarContentNumeroLigne,
          setSearchBarContentMarque,
          setSearchBarContentQuantite,
          setSearchBarContentTotalHT,
          setSearchBarContentPrixUnitaire,
          setSearchBarContentDelais,
          setSearchBarContentStatut,
          setSearchBarContentCodeCommande,
          setSearchBarContentDesignation,
          setSearchBarContentReponse,
        }}
        sortAction={{
          setSortedCodeOffre,
          setSortedReferenceOffre,
          setSortedNumeroLigne,
          setSortedMarque,
          setSortedQuantite,
          setSortedTotalHT,
          setSortedPrixUnitaire,
          setSortedDelais,
          setSortedStatut,
          setSortedCodeCommande,
          setSortedDesignation,
          setSortedReponse,
        }}
        modale={actions}
      />
    );
  });

  return (
    <div>
      <div>
        <StyledGridLignesTabOrangeEC
          container
          style={{
            boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
          }}
        >
          <OffreByArtItem
            key={0}
            id={0}
            offreCode={state.t.titleTabCodeOffre}
            offreReference={state.t.titleTabReferenceOffre}
            ligne={state.t.titleTabLigne}
            marque={state.t.titleTabMarque}
            quantite={state.t.titleTabQuantite}
            prixUnitaireHT={state.t.titleTabPrixUnitaireHT}
            totalHT={state.t.titleTabTotalHT}
            miseADisposition={state.t.titleTabDelaisProduction}
            statut={state.t.titleTabStatut}
            codeCommande={state.t.titleTabCodeCommande}
            articleDesignation={state.t.titleTabDesignation}
            reponseLigne={state.t.reponse}
            offreId={"offreId"}
            state={state}
            searchBarAction={{
              setSearchBarContentCodeOffre,
              setSearchBarContentReferenceOffre,
              setSearchBarContentNumeroLigne,
              setSearchBarContentMarque,
              setSearchBarContentQuantite,
              setSearchBarContentTotalHT,
              setSearchBarContentPrixUnitaire,
              setSearchBarContentDelais,
              setSearchBarContentStatut,
              setSearchBarContentCodeCommande,
              setSearchBarContentDesignation,
              setSearchBarContentReponse,
            }}
            sortAction={{
              setSortedCodeOffre,
              setSortedReferenceOffre,
              setSortedNumeroLigne,
              setSortedMarque,
              setSortedQuantite,
              setSortedTotalHT,
              setSortedPrixUnitaire,
              setSortedDelais,
              setSortedStatut,
              setSortedCodeCommande,
              setSortedDesignation,
              setSortedReponse,
            }}
            modale={actions}
          />
          {!offreByArtIdList || offreByArtIdList.length === 0 ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1rem",
              }}
            >
              <Grid container item direction="row" className={"row"}>
                {state.t.aucuneOffre}
              </Grid>
            </div>
          ) : (
            afficherListeArticle
          )}
        </StyledGridLignesTabOrangeEC>
      </div>
      <StyledDivPagination>
        <Pagination
          size="large"
          count={pagination.pageCount}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={0}
          onChange={handlePageClick}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </StyledDivPagination>
    </div>
  );
};
