import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useAuthentification } from '../components/hook/useAuthentification.js';
import { useTranslations } from "../components/hook/useTranslations";
import { useStyles } from "../css/General";
import { clearMessage } from "../../slice/MessageReducer";
import { connexionEC, rafraichissementEC } from "../../slice/AuthentificationReducer";
import { ClientRoutes } from "../../../constantes/routes/ClientRoutes";
import { useFormik } from "formik";
import {
  StyledDivButtonConnexionEC,
  StyledDivConnexion,
  StyledDivConnexionParent,
  StyledDivLogos,
} from "../styledComponents/StyledDivBackoffice";
import { importAll } from "../../../helper/ImportAll";
import {
  StyledImgLogoMoov,
  StyledImgLogoRC,
} from "../styledComponents/StyledImgBackoffice";
import { StyledH1Connexion } from "../styledComponents/StyledTextBackoffice";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { StyledButtonConnexion } from "../styledComponents/StyledButtonBackoffice";
import { ValidAuthentificationEC } from "../ChampsFormulaires/validationsFormulaires/ValidAuthentificationEC";

export const AuthentificationFormEC = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useAuthentification();

  // FIXME passage d'un mot de passe dans l'URL
  const { login, password } = useParams(); // Extraction des paramètres de l'URL
  const { t } = useTranslations();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.authentification);
  const { message } = useSelector((state) => state.message);

  const css = useStyles();

  useEffect(() => {
    if (clientId === null)
      return;
    dispatch(clearMessage(null));
    dispatch(rafraichissementEC())
      .unwrap()
      .then(() => {
        navigate(ClientRoutes.ACCUEIL_EC + "/");
      })
      .catch(() => {});
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isLoggedIn) {
      const previousPage = history.at(-2);
      if (
        previousPage !== undefined &&
        // TODO route codée en dur
        previousPage.pathname !==
          "/EspaceClient" + ClientRoutes.AUTHENTIFICATION
      ) {
        const previousUrl = previousPage.pathname + previousPage.search;
        navigate(previousUrl);
      } else {
        navigate(ClientRoutes.ACCUEIL_EC + "/");
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const login = queryParams.get("login");
    const password = queryParams.get("password");

    if (login && password) {
      handleConnexion({ clientCode: login, clientPassword: password });
    }
  }, [location.search]);

  const handleConnexion = (formData) => {
    setLoading(true);
    dispatch(connexionEC(formData))
      .unwrap()
      .then(() => {
        setLoading(true);
        navigate(ClientRoutes.ACCUEIL_EC + "/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const TogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //Initialisation des champs formulaire
  const valeursInitiales = {
    clientCode: login || "", // Initialisation avec le login de l'URL si présent
    clientPassword: password || "", // Initialisation avec le password de l'URL si présent
  };

  //Initialisation du formulaire
  const formik = useFormik({
    initialValues: { ...valeursInitiales },
    validationSchema: ValidAuthentificationEC(t),
    onSubmit: (values) => {
      handleConnexion(values);
    },
  });

  return (
    <StyledDivConnexionParent
      style={{
        background:
          "linear-gradient(0deg, rgba(72 92 150 / 50%), rgba(72 92 150 / 50%)), url(" +
          importAll()["LandingPageImage.jpg"] +
          ")",
      }}
    >
      <StyledDivConnexion>
        <StyledDivLogos>
          {/* TODO logo codé en cur */}
          <StyledImgLogoRC src={importAll()["LogoRCCouleur.png"]} />
          <StyledImgLogoMoov src={importAll()["LogoMoovCouleur.png"]} />
        </StyledDivLogos>
        <StyledH1Connexion>Connexion</StyledH1Connexion>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className={css.textfield}
                  id="clientCode"
                  name="clientCode"
                  label={t.authLblClientCode}
                  value={formik.values.clientCode}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  error={Boolean(formik.errors.clientCode)}
                  helperText={formik.errors.clientCode}
                  InputProps={{
                    className: css.textfieldProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon
                          className={css.iconConnexion}
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  className={css.textfield}
                  id="clientPassword"
                  name="clientPassword"
                  label={t.authLblPassword}
                  value={formik.values.clientPassword}
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type={showPassword ? "text" : "password"}
                  error={Boolean(formik.errors.clientPassword)}
                  helperText={formik.errors.clientPassword}
                  InputProps={{
                    className: css.textfieldProps,
                    endAdornment: (
                      <IconButton onClick={TogglePassword}>
                        {showPassword ? (
                          <RemoveRedEyeOutlinedIcon sx={{ fontSize: 24 }} />
                        ) : (
                          <VisibilityOffOutlinedIcon sx={{ fontSize: 24 }} />
                        )}
                      </IconButton>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className={css.iconConnexion} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ className: css.textfieldLabelProps }}
                />
              </Box>
            </Grid>
            {/* <Grid item>
                <StyledFormControlLabelSouvenir control={<Checkbox size="small" />} label='Se souvenir de moi'/>
                </Grid> */}
          </Grid>
          <StyledDivButtonConnexionEC>
            {/* <StyledButtonMdp variant="text" onClick={handleForgetPassword}>
                Mot de passe oublié ?
                </StyledButtonMdp> */}

            <StyledButtonConnexion
              type="submit"
              disabled={loading}
              style={{ marginTop: "1rem" }}
            >
              {t.authBtConnexion}
            </StyledButtonConnexion>
          </StyledDivButtonConnexionEC>
        </form>
        <div>{message}</div>
      </StyledDivConnexion>
    </StyledDivConnexionParent>
  );
};
