import { Grid } from "@mui/material";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivSubtitleExtremites,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { DessinTechnique } from "../../components/DessinTechnique";
import { importAllSpec } from "../../../../helper/ImportAll";
import {
  formulaireDimensionsExtremites,
  formulaireFixationExtremitesIt1,
  formulaireFixationExtremitesIt2,
  formulairePlanExtremiteIt1,
  formulairePlanExtremiteIt2,
} from "../../ChampsFormulaires/S04/etape6/ChampsDimensionsExtremitesS04";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import { S04_EXTREMITE_IDENTIQUE } from "../../../../constantes/symbols/SymbolsS04";
import { S04_STEP6_arrayFixationExtremites } from "../../../../constantes/symbols/SymbolsServicesS04";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS04BodyEnds = ({
  state: {
    booleanIdentite,
    etape,
    styles,
    styles: {
      subtitleExtremites,
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
    formik,
  },
  action: { handleRetour },
  nom,
}) => {
  const translation = useTranslations();
  const lang = translation.lang;

  const afficherLesChampsTypeExtremites = () => {
    switch (formik.values[S04_EXTREMITE_IDENTIQUE.description]) {
      case 1:
        return (
          <>
            {genererFormulaire(
              formulaireFixationExtremitesIt1(
                styles,
                t,
                S04_STEP6_arrayFixationExtremites(t)
              ),
              formik
            )}
            {genererFormulaire(
              formulairePlanExtremiteIt1(
                styles,
                t,
                formik
              ),
              formik
            )}
          </>
        );
      case 0:
        return (
          <>
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteGauche}
            </StyledDivSubtitleExtremites>
            {genererFormulaire(
              formulaireFixationExtremitesIt2(
                styles,
                t,
                S04_STEP6_arrayFixationExtremites(t)
              ),
              formik
            )}
            {genererFormulaire(
              formulairePlanExtremiteIt2(
                styles,
                t,
                formik
              ),
              formik
            )}
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteDroite}
            </StyledDivSubtitleExtremites>
            {genererFormulaire(
              formulaireFixationExtremitesIt1(
                styles,
                t,
                S04_STEP6_arrayFixationExtremites(t)
              ),
              formik
            )}
            {genererFormulaire(
              formulairePlanExtremiteIt1(
                styles,
                t,
                formik
              ),
              formik
            )}
          </>
        );
      default:
        return null;
    }
  };

  const afficherDessinTechnique = () => {
      return (
        <DessinTechnique styles={styles} formik={formik.values} etape={etape} t={t}/>
      );
  };

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyDimensionsARenseignerSouffletPlis}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireDimensionsExtremites(styles, t),
              formik
            )}
            {afficherLesChampsTypeExtremites()}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
