import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Formulaire } from "../components/Formulaire";
import { useNavigate, useOutletContext } from "react-router-dom";
import { StringToRouteInNavigate } from "../../../helper/StringParser";
import { CollaborateurRoutes } from "../../../constantes/routes/CollaborateurRoutes";
import { formModifierSonProfil } from "../../../constantes/champsFormulaires/FormModifierSonProfil";
import { validModifierSonProfil } from "../ChampsFormulaires/validationsFormulaires/ValidModifierSonProfil";
import {
  StyledButtonAnnulerOrange,
  StyledButtonOrange,
} from "../styledComponents/StyledButtonBackoffice";
import {
  StyledH1Orange,
  StyledP,
} from "../styledComponents/StyledTextBackoffice";
import {
  StyledDivBackoffice,
  StyledDivButtonValiderAnnuler,
} from "../styledComponents/StyledDivBackoffice";
import { modifyOneUtilisateur } from "../../slice/UtilisateurReducer";

export const ModifierSonProfil = () => {
  const [styles, t, setRecapitulatif, recapitulatif, setSelectedPage] =
    useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUtilisateur } = useSelector((state) => state.utilisateur);
  const [utdCurrentUtilisateur, setUtdCurrentUtilisateur] = useState();

  useEffect(() => {
    if (!currentUtilisateur) {
      return;
    }
    setUtdCurrentUtilisateur(currentUtilisateur);
  }, [currentUtilisateur]);

  const handleModifierSonProfil = (formData) => {
    dispatch(modifyOneUtilisateur(formData))
      .unwrap()
      .then((e) => {
        if (e.code !== 400 && e.code !== 500) {
          navigate(StringToRouteInNavigate(CollaborateurRoutes.PROFIL));
        }
      })
      .catch((e) => {});
  };

  const handleAnnuler = () => {
    navigate(StringToRouteInNavigate(CollaborateurRoutes.PROFIL));
  };

  if (!utdCurrentUtilisateur) {
    return null;
  }

  const outletContext = {
    t,
    styles,
  };

  return (
    <Paper>
      <StyledDivBackoffice>
        <StyledH1Orange>Modification du profil</StyledH1Orange>
        <StyledP>
          Modifiez les champs que vous souhaitez corriger puis validez. Les
          informations des champs non modifiés seront conservées.
        </StyledP>
        <Formulaire
          handleInteractionWithBdd={handleModifierSonProfil}
          initialValues={utdCurrentUtilisateur}
          formType={formModifierSonProfil()}
          schemaDeValidation={validModifierSonProfil}
          outletContext={outletContext}
        />
        <StyledDivButtonValiderAnnuler>
          <StyledButtonAnnulerOrange onClick={handleAnnuler}>
            Annuler
          </StyledButtonAnnulerOrange>
          <StyledButtonOrange form="utilisateurFormulaire" type="submit">
            Valider les modifications
          </StyledButtonOrange>
        </StyledDivButtonValiderAnnuler>
      </StyledDivBackoffice>
    </Paper>
  );
};
