import { getStore } from '../../app/storeProvider';

class Serveur {

  static #urlBackend = null;
  static #urlTranslation = null;

  static #initializeUrl = () => {
    if (Serveur.#urlBackend !== null)
      return;
    const store = getStore();
    const state = store.getState();
    const parameters = state.configuration.parameters;

    let paramName = 'URL_BACKEND';
    let paramValue = parameters[paramName];
    if (paramValue === undefined)
      throw new Error(`Paramètre «${paramName}» non défini dans config.json`);
    if (paramValue.endsWith('/'))
      paramValue = paramValue.slice(0, -1);
    Serveur.#urlBackend = paramValue;

    paramName = 'URL_TRANSLATION';
    paramValue = parameters[paramName];
    if (paramValue === undefined)
      throw new Error(`Paramètre «${paramName}» non défini dans config.json`);
    if (paramValue.endsWith('/'))
      paramValue = paramValue.slice(0, -1);
    Serveur.#urlTranslation = paramValue;
  }

  static get URL_BASE() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/`;
  }

  static get URL_API() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/`;
  }

  static get URL_UPLOADS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/uploads/`;
  }

  static get URL_SECTEURS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobActivites/`;
  }

  static get URL_FONCTIONS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobFonctions/`;
  }

  static get URL_TYPE_MACHINE() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobTypeMachines/`;
  }

  static get URL_MARQUE_MACHINE() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobMarqueMachine/`;
  }

  static get URL_PAYS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobPays/`;
  }

  static get URL_AGRESSIONS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/SylobAgressions/`;
  }

  static get URL_ODP_BY_ID() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/getOffreDePrixById/`;
  }

  static get URL_ART_BY_ID() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/getCaracArtById/`;
  }

  static get URL_GET_ALL_ARTICLES_EC() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlBackend}/api/json/getArticlesEC/`;
  }

  static get URL_TRADUCTIONS() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlTranslation}/api/json/SylobTradText/`;
  }

  static get URL_TRADUCTIONS_INFOBULLES() {
    Serveur.#initializeUrl();
    return `${Serveur.#urlTranslation}/api/json/SylobTradInfoB/`;
  }


}


export { Serveur};
