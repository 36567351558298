import React from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { StringToRouteInNavigate } from "../../helper/StringParser";
import { ClientRoutes } from "../../constantes/routes/ClientRoutes";

/**
 * If the user is logged in and has the required permissions, then render the Outlet component.
 * Otherwise, redirect to the authentication page
 * @returns A component that will redirect to the authentication page if the user is not logged in or
 * if the user does not have the right permissions.
 */
export const AuthentificationECRoute = () => {
  const { isLoggedIn } = useSelector(
    (state) => state.authentification
  );
  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();


  return isLoggedIn ? (
    <Outlet
      context={[
        styles,
        t,
        booleanIdentite,
        upToDateArticleByIdList,
        setUpToDateArticleByIdList,
        upToDateOffreByIdList,
        setUpToDateOffreByIdList,
      ]}
    />
  ) : (
    <Navigate
      to={
        "EspaceClient" + StringToRouteInNavigate(ClientRoutes.AUTHENTIFICATION)
      }
    />
  );
};
