import { useDispatch } from "react-redux";
import { Card, CardContent, CardMedia } from "@mui/material";

import { useTranslations } from '../../components/hook/useTranslations.js';
import {
  StyledContentCard,
  StyledDivTitleCard,
  StyledDivTitleSubCard,
  StyledDivBoutonCialProximite
} from "../../styledComponents/StyledTextEspaceClient";
import { sendMailEtreRappeler, sendMailProgrammerVisite } from "../../../slice/EspaceClientReducer";
import { BoutonCialProx, } from "./BoutonAccueilEC";


const CommercialCard = ({ styles, detailsClient, detailsContact, setOpenWaitingModal,
          imageSrc,
          setResultMessage,
          setResultTitle,
          setOpenResultModal,
          setActionToConfirm,
          setMessageToConfirm,
          setOpenConfirmationModal,
          isMobile
        }) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const handleProgrammerVisite = () => {
    const obj = {
      contactById: detailsContact,
    };

    setOpenWaitingModal(true);

    dispatch(sendMailProgrammerVisite(obj))
      .unwrap()
      .then((response) => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEnvoiMailVisite);
        setResultTitle(t.titleMessageResultat);
        setOpenResultModal(true);
      })
      .catch((error) => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setOpenResultModal(true);
      });
  };

  const handleEtreRappele = () => {
    const obj = {
      contactById: detailsContact,
    };

    setOpenWaitingModal(true);

    dispatch(sendMailEtreRappeler(obj))
      .unwrap()
      .then((response) => {
        setOpenWaitingModal(false);
        setResultMessage(t.messageResultatEtreRappele);
        setResultTitle(t.titleMessageResultatEtreRappele);
        setOpenResultModal(true);
      })
      .catch((error) => {
        setOpenWaitingModal(false);
        setResultMessage(t.erreurEnvoiMail);
        setOpenResultModal(true);
      });
  };

  const handleConfirmationModal = (action) => {
    setActionToConfirm(() => {
      if (action === "visite") {
        setMessageToConfirm(t.messageToConfirmVisite);
        return handleProgrammerVisite;
      }
      if (action === "rappel") {
        setMessageToConfirm(t.messageToConfirmRappel);
        return handleEtreRappele;
      }
      return null;
    });
    setOpenConfirmationModal(true);
  };

  const styleSpecial = isMobile ? {} : {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Card
      id="commercial-card"
      className={`${styles.cardContainer}`}
      style={{
        boxShadow: "0px 5px 15px 5px rgba(87, 92, 130, 0.10)",
        order: 5,
        borderRadius: "8px",
      }}
    >
      {/* Contenu textuel */}
      <CardContent
        className={styles.textContainer}
        style={{ padding: "24px" }}
      >
        <StyledDivTitleCard style={styleSpecial} >
          {t.genTitleCardCialProximp}
        </StyledDivTitleCard>
        <StyledDivTitleSubCard>
          {detailsClient.commercialPrenom +
            " " +
            detailsClient.commercialNom}
        </StyledDivTitleSubCard>
        <StyledContentCard>
          {t.telCommercial} : {detailsClient.commercialTelephone}
        </StyledContentCard>
        <StyledContentCard style={styleSpecial} >
          {t.emailCommercial} : {detailsClient.commercialEmail}
        </StyledContentCard>
        <StyledDivBoutonCialProximite
          style={{
            display: "flex", // Flexbox pour gérer la mise en page
            flexDirection: "column", // Les boutons sont empilés verticalement
            width: "100%", // Le conteneur prend toute la largeur
            gap: "10px", // Un espace entre les boutons (facultatif)
          }}
        >
          <BoutonCialProx texte={t.programmerVisite} action={() => handleConfirmationModal("visite")} isFirstButton={true} />
          <BoutonCialProx texte={t.etreRappele} action={() => handleConfirmationModal("rappel")} isFirstButton={false} />
        </StyledDivBoutonCialProximite>
      </CardContent>

      {/* Image pour Desktop */}
      <CardMedia
        component="img"
        alt={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        src={imageSrc}
        title={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        className={styles.desktopImage}
      />

      {/* Image pour Mobile */}
      <CardMedia
        component="img"
        alt={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        src={imageSrc}
        title={`${detailsClient.commercialPrenom} ${detailsClient.commercialNom}`}
        className={styles.mobileImage}
      />
    </Card>
  )
}

export default CommercialCard;
