import { Grid } from "@mui/material";
import { importAll, importAllSpec } from "../../../../helper/ImportAll";
import {
  StyledDivActiveStepTitle,
  StyledDivButton,
  StyledDivDessin,
  StyledDivDessinMobile,
  StyledDivFiligranne,
  StyledDivFormAndImageContainer,
  StyledDivFormAndSOS,
  StyledDivFormContainer,
  StyledDivPhotoPasDessin,
  StyledDivSubtitleExtremites,
} from "../../styledComponents/StyledDiv";
import {
  StyledButtonContinuer,
  StyledButtonPrec,
} from "../../styledComponents/StyledButton";
import { StyledArrowBackOutlinedIcon } from "../../styledComponents/StyledIcon";
import { StyledP } from "../../styledComponents/StyledP";
import { StyledSticky } from "../../styledComponents/StyledSticky";

import { DessinTechnique } from "../../components/DessinTechnique";
import {
  formulaireDimensionsExtremites,
  formulaireTypeExtremitesIt1,
  formulaireTypeExtremitesIt2,
  formulaireSectionDeLaManchetteIt1,
  formulaireSectionDeLaManchetteRondeIt1,
  formulaireSectionDeLaManchetteRectangulaireIt1,
  formulaireSectionDeLaBrideIt1,
  formulaireSectionDeLaBrideRondeIt1,
  formulaireSectionDeLaBrideRectangulaireIt1,
  formulaireBridePerceeIt1,
  formulaireBridePerceeOuiIt1,
  formulaireSectionDeLaManchetteRondeIt2,
  formulaireSectionDeLaManchetteRectangulaireIt2,
  formulaireSectionDeLaBrideRondeIt2,
  formulaireSectionDeLaBrideRectangulaireIt2,
  formulaireBridePerceeIt2,
  formulaireBridePerceeOuiIt2,
  formulaireFixationExtremitesIt1,
  formulaireSectionDeLaManchetteIt2,
  formulaireFixationExtremitesIt2,
  formulaireSectionDeLaBrideIt2,
  fixationExtremitesManchette,
  fixationExtremitesBride,
  fixationExtremitesManchetteEtBride,
} from "../../ChampsFormulaires/S01/etape5/ChampsDimensionsExtremites";
import {
  arraySectionExtremite,
  arraySectionSoufflet,
  arrayTypeCorps,
  arrayTypeExtremites,
} from "../../../../constantes/symbols/SymbolsServicesS01";
import { genererFormulaire } from "../../components/generateFormulary/CasesGeneration";
import {
  EXTREMITE_IDENTIQUE,
  BRIDE_IT2,
  BRIDE_PERCEE_IT2,
  MANCHETTE_IT2,
  MANCHETTE_LARGEUR_IT2,
  TYPE_EXTREMITES_IT1,
  MANCHETTE_IT1,
  MANCHETTE_LARGEUR_IT1,
  BRIDE_IT1,
  BRIDE_PERCEE_IT1,
  TYPE_EXTREMITES_IT2,
  SECTION_SOUFFLET,
  TYPE_CORPS,
} from "../../../../constantes/symbols/SymbolsS01";
import {
  equalsBooleanOrStringOrNumber,
  equalsCodeOrLabel,
} from "../../../../constantes/symbols/HelperSymbolsServices";
import { SOSComponent } from "../../components/SOSComponent";
import { QuestionnaireComponent } from "../../components/QuestionnaireComponent";
import { useTranslations } from "../../components/hook/useTranslations";
import { nativeLang } from '../../../../config/i18nConfig.js';

export const StepS01BodyEnds = ({
  state: {
    booleanIdentite,
    formik,
    styles,
    styles: {
      subtitleExtremites,
      cssFontFamily,
      cssButton,
      cssButtonPrec,
      activeStepTitle,
      logoFiligraneDessin,
    },
    t,
  },
  action: { handleRetour },
  nom,
}) => {
  const lang = useTranslations();

  const afficherLesChampsTypeExtremites = (t) => {
    switch (
      equalsBooleanOrStringOrNumber(
        formik.values[EXTREMITE_IDENTIQUE.description]
      )
    ) {
      case 1:
        return afficherFormulaireExtremiteIdentiqueIt1();
      case 0:
        return (
          <>
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteGauche}
            </StyledDivSubtitleExtremites>
            {afficherFormulaireExtremiteIdentiqueIt2()}
            <StyledDivSubtitleExtremites className={subtitleExtremites}>
              {t.extremiteDroite}
            </StyledDivSubtitleExtremites>
            {afficherFormulaireExtremiteIdentiqueIt1()}
          </>
        );
      default:
        return null;
    }
  };

  //-------------------------IT1--------------------------//
  const afficherFormulaireExtremiteIdentiqueIt1 = () => {
    const typeExtremiteIt1 = formik.values[TYPE_EXTREMITES_IT1.description];
    if (equalsCodeOrLabel(typeExtremiteIt1, arrayTypeExtremites(), 0)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt1(styles, t), formik)}
          {genererFormulaire(
            formulaireSectionDeLaManchetteIt1(styles, t),
            formik
          )}
          {afficherFormulaireSectionManchetteIt1()}
          {genererFormulaire(
            formulaireFixationExtremitesIt1(
              fixationExtremitesManchette(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(typeExtremiteIt1, arrayTypeExtremites(), 1)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt1(styles, t), formik)}
          {genererFormulaire(formulaireSectionDeLaBrideIt1(styles, t), formik)}
          {afficherFormulaireSectionBrideIt1()}
          {afficherFormulaireBridePerceeIt1()}
          {genererFormulaire(
            formulaireFixationExtremitesIt1(
              fixationExtremitesBride(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(typeExtremiteIt1, arrayTypeExtremites(), 2)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt1(styles, t), formik)}
          {genererFormulaire(
            formulaireSectionDeLaManchetteIt1(styles, t),
            formik
          )}
          {afficherFormulaireSectionManchetteIt1()}
          {genererFormulaire(formulaireSectionDeLaBrideIt1(styles, t), formik)}
          {afficherFormulaireSectionBrideIt1()}
          {afficherFormulaireBridePerceeIt1()}
          {genererFormulaire(
            formulaireFixationExtremitesIt1(
              fixationExtremitesManchetteEtBride(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    return genererFormulaire(formulaireTypeExtremitesIt1(styles, t), formik);
  };

  const afficherFormulaireBridePerceeIt1 = () => {
    const bridePercee = equalsBooleanOrStringOrNumber(
      formik.values[BRIDE_PERCEE_IT1.description]
    );
    if (!!bridePercee) {
      return (
        <>
          {genererFormulaire(formulaireBridePerceeIt1(styles, t), formik)}
          {genererFormulaire(formulaireBridePerceeOuiIt1(styles, t), formik)}
        </>
      );
    } else {
      return (
        <>{genererFormulaire(formulaireBridePerceeIt1(styles, t), formik)}</>
      );
    }
  };

  const afficherFormulaireSectionManchetteIt1 = () => {
    const sectionManchette = formik.values[MANCHETTE_IT1.description];
    if (equalsCodeOrLabel(sectionManchette, arraySectionExtremite(t), 0)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaManchetteRondeIt1(styles, t),
            formik
          )}
        </>
      );
    }
    if (!!equalsCodeOrLabel(sectionManchette, arraySectionExtremite(t), 1)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaManchetteRectangulaireIt1(styles, t),
            formik
          )}
        </>
      );
    }
    return null;
  };

  const afficherFormulaireSectionBrideIt1 = () => {
    const sectionBride = formik.values[BRIDE_IT1.description];
    if (equalsCodeOrLabel(sectionBride, arraySectionExtremite(t), 0)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaBrideRondeIt1(styles, t),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(sectionBride, arraySectionExtremite(t), 1)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaBrideRectangulaireIt1(styles, t),
            formik
          )}
        </>
      );
    }
    return null;
  };

  //-------------------------IT2--------------------------//
  const afficherFormulaireExtremiteIdentiqueIt2 = () => {
    const typeExtremiteIt2 = formik.values[TYPE_EXTREMITES_IT2.description];
    if (equalsCodeOrLabel(typeExtremiteIt2, arrayTypeExtremites(t), 0)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt2(styles, t), formik)}
          {genererFormulaire(
            formulaireSectionDeLaManchetteIt2(styles, t),
            formik
          )}
          {afficherFormulaireSectionManchetteIt2()}
          {genererFormulaire(
            formulaireFixationExtremitesIt2(
              fixationExtremitesManchette(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(typeExtremiteIt2, arrayTypeExtremites(t), 1)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt2(styles, t), formik)}
          {genererFormulaire(formulaireSectionDeLaBrideIt2(styles, t), formik)}
          {afficherFormulaireSectionBrideIt2()}
          {afficherFormulaireBridePerceeIt2()}
          {genererFormulaire(
            formulaireFixationExtremitesIt2(
              fixationExtremitesBride(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(typeExtremiteIt2, arrayTypeExtremites(t), 2)) {
      return (
        <>
          {genererFormulaire(formulaireTypeExtremitesIt2(styles, t), formik)}
          {genererFormulaire(
            formulaireSectionDeLaManchetteIt2(styles, t),
            formik
          )}
          {afficherFormulaireSectionManchetteIt2()}
          {genererFormulaire(formulaireSectionDeLaBrideIt2(styles, t), formik)}
          {afficherFormulaireSectionBrideIt2()}
          {afficherFormulaireBridePerceeIt2()}
          {genererFormulaire(
            formulaireFixationExtremitesIt2(
              fixationExtremitesManchetteEtBride(t),
              styles,
              t
            ),
            formik
          )}
        </>
      );
    }
    return genererFormulaire(formulaireTypeExtremitesIt2(styles, t), formik);
  };

  const afficherFormulaireBridePerceeIt2 = () => {
    const bridePercee = equalsBooleanOrStringOrNumber(
      formik.values[BRIDE_PERCEE_IT2.description]
    );
    if (!!bridePercee) {
      return (
        <>
          {genererFormulaire(formulaireBridePerceeIt2(styles, t), formik)}
          {genererFormulaire(formulaireBridePerceeOuiIt2(styles, t), formik)}
        </>
      );
    } else {
      return (
        <>{genererFormulaire(formulaireBridePerceeIt2(styles, t), formik)}</>
      );
    }
  };

  const afficherFormulaireSectionManchetteIt2 = () => {
    const sectionManchette = formik.values[MANCHETTE_IT2.description];
    if (equalsCodeOrLabel(sectionManchette, arraySectionExtremite(t), 0)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaManchetteRondeIt2(styles, t),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(sectionManchette, arraySectionExtremite(t), 1)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaManchetteRectangulaireIt2(styles, t),
            formik
          )}
        </>
      );
    }
    return null;
  };

  const afficherFormulaireSectionBrideIt2 = () => {
    const sectionBride = formik.values[BRIDE_IT2.description];
    if (equalsCodeOrLabel(sectionBride, arraySectionExtremite(t), 0)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaBrideRondeIt2(styles, t),
            formik
          )}
        </>
      );
    }
    if (equalsCodeOrLabel(sectionBride, arraySectionExtremite(t), 1)) {
      return (
        <>
          {genererFormulaire(
            formulaireSectionDeLaBrideRectangulaireIt2(styles, t),
            formik
          )}
        </>
      );
    }
    return null;
  };

  const afficherDessinTechnique = () => {
    if (
      equalsCodeOrLabel(
        formik.values[SECTION_SOUFFLET.description],
        arraySectionSoufflet(t),
        1
      ) &&
      equalsCodeOrLabel(
        formik.values[TYPE_CORPS.description],
        arrayTypeCorps(t),
        1
      )
    ) {
      return (
        <StyledDivPhotoPasDessin
          style={{
            backgroundImage:
              "url(" + importAll()["SouffletRectangulaireConique.jpg"] + ")",
          }}
        />
      );
    } else {
      return <DessinTechnique formik={formik.values} styles={styles} t={t} />;
    }
  };

  const afficherSOSetForm = () => {
    if (lang !== nativeLang)
      return <></>;
    return (
      <>
        <StyledDivFormAndSOS>
          <SOSComponent styles={styles} />
          <QuestionnaireComponent styles={styles} />
        </StyledDivFormAndSOS>
      </>
    );
  };

  return (
    <StyledDivFormAndImageContainer>
      <StyledDivFormContainer>
        <StyledDivActiveStepTitle className={activeStepTitle} name={nom}>
          <h2>{nom}</h2>
        </StyledDivActiveStepTitle>
        <StyledP className={cssFontFamily}>
          {t.genConfigurateurBodyDimensionsARenseignerSoufflet}
        </StyledP>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            {genererFormulaire(
              formulaireDimensionsExtremites(styles, t),
              formik
            )}
            {afficherLesChampsTypeExtremites(t)}

            <StyledDivDessinMobile>
              <StyledDivDessin>
                {afficherDessinTechnique()}
                <StyledDivFiligranne
                  style={{
                    backgroundImage:
                      "url(" +
                      importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                      ")",
                  }}
                  className={logoFiligraneDessin}
                />
              </StyledDivDessin>
              {afficherSOSetForm()}
            </StyledDivDessinMobile>

            <Grid item>
              <StyledDivButton>
                <StyledButtonPrec
                  className={cssButtonPrec}
                  onClick={handleRetour}
                  variant="outlined"
                  startIcon={<StyledArrowBackOutlinedIcon />}
                />
                <StyledButtonContinuer
                  variant="contained"
                  className={cssButton}
                  type="submit"
                >
                  {t.bouttonContinuer}
                </StyledButtonContinuer>
              </StyledDivButton>
            </Grid>
          </Grid>
        </form>
      </StyledDivFormContainer>

      <StyledSticky>
        <StyledDivDessin>
          {afficherDessinTechnique()}
          <StyledDivFiligranne
            style={{
              backgroundImage:
                "url(" +
                importAllSpec(booleanIdentite)["LogoFiligrane.jpg"] +
                ")",
            }}
            className={logoFiligraneDessin}
          />
        </StyledDivDessin>
        {afficherSOSetForm()}
      </StyledSticky>
    </StyledDivFormAndImageContainer>
  );
};
