import { setMessage } from "../redux/slice/MessageReducer";

export const errorHandlerMessageProvider = (error, thunkAPI) => {
  const message =
    error.payload ||
    error?.response?.data?.message ||
    error.message ||
    error.toString();
  thunkAPI.dispatch(setMessage(message));
  return thunkAPI.rejectWithValue(message);
};

export const errorHandlerMessageProviderByCode = ({code}, thunkAPI) => {
  const message = "Une erreur est survenue dans le processus d'envoi des fichiers : erreur "+code;
  thunkAPI.dispatch(setMessage({message: message, code: code}));
  return thunkAPI.rejectWithValue();
};
