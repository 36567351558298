import { Badge, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Fragment } from "react";
import { useTranslations } from "../../components/hook/useTranslations";
import { ClientRoutes } from "../../../../constantes/routes/ClientRoutes";
import { useNavigate } from "react-router-dom";

import { getBgColorFromStatus } from "../common/EcColor";

export const ArtByCommandeItem = ({
  id,
  articleReferenceClient,
  article,
  designation,
  familleCode,
  marque,
  quantiteCde,
  quantiteLivr,
  prixUnitaireHT,
  totalHT,
  livraisonPrevue,
  etatLivraison,
  ligne,
  articleId,
  state: { familleArticleList },
}) => {
  const navigate = useNavigate();
  const translation = useTranslations();
  const lang = translation.lang;

  const defaultColumnSize = 1;

  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));

  const noClassNameMobileAndTablette = "retirerFormatTablette retirerFormatMobile hasBorderRight";
  const noClassNameMobile = "retirerFormatMobile hasBorderRight flex-end";
  const alwaysDisplayed = "hasBorderRight";

  /**
   * Différentes options CSS possibles
   * @typedef {Object} GridCssOptions
   * @property {number} [md=defaultColumnSize] - Nombre de colonnes pour le breakpoint md
   * @property {number} [sm=defaultColumnSize] - Nombre de colonnes pour le breakpoint sm
   * @property {number} [xs=defaultColumnSize] - Nombre de colonnes pour le breakpoint xs
   */

  /**
   * TODO Méthode à documenter
   * @param {string} name
   * @param {?never} searchBarStateSetter
   * @param {?never} sortStateSetter
   * @param {?GridCssOptions} cssOptions
   * @param {?string} style
   */
  const displayItemHeader = (
    name,
    searchBarStateSetter,
    sortStateSetter,
    cssOptions,
    style
  ) => {
    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : defaultColumnSize}
        className={`${style} columnHeaderEC ${
          searchBarStateSetter ? "" : "hasSearch"
        }`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          lineHeight: "150%",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
        >
          <Stack
            direction="row"
            flex="1"
            style={
              downSM || !sortStateSetter
                ? { justifyContent: "center" }
                : { justifyContent: "space-between" }
            }
            paddingBottom="0.5em"
          >
            <Stack justifyContent="center" alignItems="center">
              <div dangerouslySetInnerHTML={{ __html: name }} />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  /**
   * TODO Méthode à documenter
   * @param {string} name
   * @param {GridCssOptions} cssOptions
   * @param {string} classList
   * @param {string} [texte]
   */
  const displayItem = (
    name,
    cssOptions,
    classList,
    texte) => {
    let displayName = name;

    // Vérifier et remplacer les valeurs spécifiques
    if (name === "RC") {
      displayName = "RC Modèles";
    } else if (name === "MP") {
      displayName = "Moovprotect";
    }

    if (texte) {
      displayName = displayName + " " + texte;
    }

    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : 8}
        className={classList + " itemDdp"}
        style={{ wordBreak: "break-all" }}

        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        dangerouslySetInnerHTML={{ __html: displayName }}
      >

      </Grid>
    );
  };

  /**
   * TODO Documenter
   * @param {array} array   ???
   * @param {GridCssOptions} cssOptions Options CSS Grid Material UI
   * @param {string} classList Classes à ajouter
   * @param {string} texte ???
   */
  const displayItemArray = (array, cssOptions, classList, texte = "") => {
    if (array.length === 1) {
      return displayItem(array[0] + " " + texte, cssOptions, classList);
    }

    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : 8}
        className={classList + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <div key={index + item}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.toString() + " " + texte,
                }}
              />
              {index < array.length - 1 && <hr className={"hrDdp"} />}
            </div>
          ))}
        </div>
      </Grid>
    );
  };

  /**
   * TODO Documenter
   * @param {array} array ???
   * @param {*} field ???
   * @param {GridCssOptions} cssOptions
   * @param {string} style
   */
  const displayFamilleArticle = (
    array,
    field,
    cssOptions,
    style
  ) => {
    const getTranslation = (code) => {
      const entry = array.find((e) => e.code === code);
      return entry?.[lang] ?? code;
    };
    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : 0}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div
          style={{
            wordBreak: "break-word",
            justify: "center",
            alignItems: "center",
          }}
        >
          { getTranslation(field) }
        </div>
      </Grid>
    );
  };

  /**
   * TODO Documenter
   * @param {array} array ???
   * @param {GridCssOptions} cssOptions
   * @param {string} classList
   * @param {string} texte
   */
  const displayBadgeItemArray = (array, cssOptions, classList, texte = "") => {
    if (array.length === 1) {
      return displayBadgeResponse(array[0], cssOptions, classList);
    }

    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : defaultColumnSize}
        className={classList + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>
          {array.map((item, index) => (
            <Fragment key={index + item}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <Badge
                  style={{
                    wordBreak: "break-all",
                    backgroundColor: getBgColorFromStatus(item.toString()),
                    color: "white",
                    marginTop: "0.25rem",
                    marginBottom: "0.25rem",
                    paddingLeft: "0.4rem",
                    paddingRight: "0.4rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  {item.toString() + " " + texte}
                </Badge>
              </div>
              {index < array.length - 1 && <hr className={"hrDdp"} />}
            </Fragment>
          ))}
        </div>
      </Grid>
    );
  };

  /**
   * TODO Documenter
   * @param {*} response
   * @param {GridCssOptions} cssOptions
   * @param {string} style
   */
  const displayBadgeResponse = (response, cssOptions, style) => {
    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : defaultColumnSize}
        className={style + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Badge
          style={{
            wordBreak: "break-all",
            backgroundColor: getBgColorFromStatus(response),
            color: "white",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            paddingLeft: "0.4rem",
            paddingRight: "0.4rem",
            borderRadius: "0.25rem",
            fontSize: "0.75rem",
          }}
        >
          {response}
        </Badge>
      </Grid>
    );
  };

  /**
   * TODO Documenter
   * @param {string} articleId
   * @param {string} name
   * @param {GridCssOptions} cssOptions
   * @param {string} classList
   */
  const displayArticleCode = (
    articleId,
    name,
    cssOptions,
    classList
  ) => {
    // Définir l'URL de redirection pour la commande
    const navigateToArticle = () => {
      if (articleId) {
        navigate(ClientRoutes.VUE_ARTICLE + "?art=" + articleId);
      }
    };

    return (
      <Grid
        item
        md={cssOptions.md ? cssOptions.md : defaultColumnSize}
        sm={cssOptions.sm ? cssOptions.sm : defaultColumnSize}
        xs={cssOptions.xs ? cssOptions.xs : 8}
        className={classList + " itemDdp"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        {articleId ? (
          // Si articleId n'est pas vide, rendre le champ cliquable
          <div
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            dangerouslySetInnerHTML={{ __html: name }}
            onClick={navigateToArticle} // Ne pas invoquer directement la fonction
          />
        ) : (
          // Si articleId est vide, afficher simplement le texte sans lien
          <div
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      direction="row"
      className={id === 0 ? "row0" : "row"}
      style={id === 0 ? { borderRadius: "8px 8px 0 0" } : null}
    >
      {id === 0
        ? displayItemHeader(
          ligne,
          null,
          null,
          {md: 0.5, sm: 0.5, xs: 4},
          noClassNameMobile
        )
        : displayItem(ligne, {md: 0.5, sm: 0.5}, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            articleReferenceClient,
            null,
            null,
            {md: 1, sm: 1, xs: 4},
            noClassNameMobile
          )
        : displayItem(articleReferenceClient, {md: 1, sm: 1}, noClassNameMobile)}

      {id === 0
        ? displayItemHeader(
          article,
          null,
          null,
          {md: 1, sm: 1, xs: 8},
          alwaysDisplayed
        )
        : displayArticleCode(articleId, article, {md: 1, sm: 1}, alwaysDisplayed)}
      {id === 0
        ? displayItemHeader(
            designation,
            null,
            null,
            {md: 1.25, sm: 1.25, xs: 4},
            noClassNameMobile
          )
        : displayItem(designation, {md: 1.25, sm: 1.25}, noClassNameMobile)}
      {id === 0
        ? displayItemHeader(
            familleCode,
            null,
            null,
            {md: 1.25, sm: 1.25},
            noClassNameMobile
          )
        : displayFamilleArticle(
            familleArticleList,
            familleCode,
            {md: 1.25, sm: 1.25},
            noClassNameMobile
          )}
      {id === 0
        ? displayItemHeader(
            marque,
            null,
            null,
            {md: 1, sm: 1, xs: 8},
            noClassNameMobileAndTablette
          )
        : displayItem(marque, {md: 1, sm: 1}, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            quantiteCde,
            null,
            null,
            {md: 1, sm: 1, xs: 8},
            noClassNameMobileAndTablette
          )
        : displayItemArray(quantiteCde, {md: 1, sm: 1}, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            quantiteLivr,
            null,
            null,
            {md: 1, sm: 1, xs: 8},
            noClassNameMobileAndTablette
          )
        : displayItemArray(quantiteLivr, {md: 1, sm: 1}, noClassNameMobileAndTablette)}
      {id === 0
        ? displayItemHeader(
            prixUnitaireHT,
            null,
            null,
            {md: 1, sm: 1, xs: 8},
            noClassNameMobileAndTablette
          )
        : displayItemArray(
            prixUnitaireHT,
            {md: 1, sm: 1},
            noClassNameMobileAndTablette,
            "€"
          )}
      {id === 0
        ? displayItemHeader(
            totalHT,
            null,
            null,
            {md: 1, sm: 1, xs: 8},
            noClassNameMobileAndTablette
          )
        : displayItemArray(totalHT, {md: 1, sm: 1}, noClassNameMobileAndTablette, "€")}
      {id === 0
        ? displayItemHeader(
            livraisonPrevue,
            null,
            null,
            {md: 1, sm: 1, xs: 4},
            noClassNameMobileAndTablette
          )
        : displayItemArray(livraisonPrevue, {md: 1, sm: 1}, noClassNameMobileAndTablette)}

      {id === 0
        ? displayItemHeader(
          etatLivraison,
          null,
          null,
          {md: 1, sm: 1, xs: 4},
          ""
        )
        : displayBadgeItemArray(etatLivraison, {md: 1, sm: 1, xs: 4}, "")}
    </Grid>
  );
};
