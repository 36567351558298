const fetchJsonFromServer = async (url) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try  {
    const response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });
    if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);

    // Vérifier que le contenu ne contient pas "<!DOCTYPE html>"
    // (en effet, si le fichier «public/config.json» n'existe pas,
    // React (ou Vite) ne renvoient pas une erreur 404 mais une page HTML)
    const textContent = await response.text();
    if (textContent.includes('<!DOCTYPE html>'))
        throw new Error(`Récupération du fichier «${url}» impossible`);

    // Convertir le texte en JSON
    return JSON.parse(textContent);
  }
  catch (error) {
    console.log(`Erreur récupération «${url}» :`, error.message);
    throw error;
  }
};


const jsonFull = async (url, httpMethod, options={}, parameters=null) => {
  const lang = options.lang ?? 'en';
  const fetchParam = {
    method : httpMethod,
    headers:  {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': lang
    }
  };
  if (parameters !== null) {
    const methodsWithBody = ['POST', 'PUT', 'PATCH'];
    if (methodsWithBody.includes(httpMethod)) {
      fetchParam.body = JSON.stringify(parameters);
    }
    else {
      const queryString = new URLSearchParams(parameters).toString();
      url += `?${queryString}`;
    }
  }
  const response = await fetch(url, fetchParam);
  if (! response.ok)
    throw new Error(`Erreur requête «${url}» : Status=${response.status} (${response.statusText})`);

  // Interpréter la réponse en fonction du type MIME de l'entête HTTP
  const contentType = response.headers.get("content-type") || '';
  if (! contentType.includes("application/json")) {
    const textResponse = await response.text();
    throw new Error(`Erreur requête «${url}» : réponse JSON attendue (${textResponse})`);
  }

  return await response.json();
}

const jsonGet = async (url, options = {}, parameters = null) => {
    return await jsonFull(url, 'GET', options, parameters);
}

const jsonPost = async (url, parameters, options={}) => {
    return await jsonFull(url, 'POST', options, parameters);
}

export { fetchJsonFromServer, jsonGet, jsonPost };
