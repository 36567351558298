import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams, } from "react-router-dom";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";
import { ListeArticle } from "./ListeArticle";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  ARTICLE,
} from "../../../../constantes/symbols/SymbolsResumeDisplayedFrom";
import { getAllAgressions } from "../../../slice/AgressionsReducer";
import {
  getAllMarqueMachine,
  getAllTypeMachine,
} from "../../../slice/MachinesReducer";
import { getAllSecteurs } from "../../../slice/SecteurReducer";
import { getAllFonctions } from "../../../slice/FonctionReducer";
import { getAllPays } from "../../../slice/PaysReducer";
import {
  getAllArticlesEC,
  getAllFamilleArticle,
  getOneArticleEC,
} from "../../../slice/EspaceClientReducer";
import {
  StyledDivWrapperVues,
  StyledHrEspaceClientRose,
} from "../../styledComponents/StyledDivEspaceClient";
import ErrorBoundary from "../../components/ErrorBoundary";
import { controllerDisplayResumeArticleEC } from "./controllerResume/controllerDisplayResumeArticleEC";

export const VueArticle = ({ actions, state }) => {
  const { clientId } = useAuthentification();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const [articleId, setArticleId] = useState(null);
  const [referenceToDelete, setReferenceToDelete] = useState();
  const { message } = useSelector((state) => state.message);

  //SERVICES
  const [upToDateAgressionsList, setUpToDateAgressionsList] = useState([]);
  const [upToDateSecteursList, setUpToDateSecteursList] = useState([]);
  const [upToDateFonctionsList, setUpToDateFonctionsList] = useState([]);
  const [upToDatePaysList, setUpToDatePaysList] = useState([]);
  const [upToDateTypeMachineList, setUpToDateTypeMachineList] = useState([]);
  const [upToDateMarqueMachineList, setUpToDateMarqueMachineList] = useState(
    []
  );
  const [isArticleByIdSet, setIsArticleByIdSet] = useState(false);

  const { secteursList } = useSelector((state) => state.secteurs);
  const { fonctionsList } = useSelector((state) => state.fonctions);
  const { paysList } = useSelector((state) => state.pays);
  const { agressionsList } = useSelector((state) => state.agressions);
  const { typeMachineList, marqueMachineList } = useSelector(
    (state) => state.machines
  );

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
    isMobileFormat,
  ] = useOutletContext();

  const { articleById, familleArticleList } = useSelector(
    (state) => state.espaceClient
  );

  useEffect(() => {
    if (!articleById) {
      setIsArticleByIdSet(false);
      return;
    }
    setIsArticleByIdSet(true);
  }, [articleById]);

  useEffect(() => {

    if (clientId !== null) {
      dispatch(getAllArticlesEC({ clientId }));
      dispatch(getAllFamilleArticle({ clientId }));
    }
  }, []);

  useEffect(() => {
    const art = params.get("art");
    if (!art) {
      setIsArticleByIdSet(false);
      return;
    }

    if (clientId !== null) {
      dispatch(getOneArticleEC({ clientId, articleId: art }));
      dispatch(getAllAgressions());
      dispatch(getAllTypeMachine());
      dispatch(getAllMarqueMachine());
      dispatch(getAllSecteurs());
      dispatch(getAllFonctions());
      dispatch(getAllPays());
      dispatch(getAllArticlesEC({ clientId }));
      dispatch(getAllFamilleArticle({ clientId }));
    }
  }, [dispatch]);

  useEffect(() => {
    const paramArt = params.get("art");

    if (!paramArt) {
      setIsArticleByIdSet(false);
    }
  }, []);

  useEffect(() => {
    if (!agressionsList) {
      return;
    }
    setUpToDateAgressionsList(agressionsList);
  }, [agressionsList]);

  useEffect(() => {
    if (!typeMachineList) {
      return;
    }
    setUpToDateTypeMachineList(typeMachineList);
  }, [typeMachineList]);

  useEffect(() => {
    if (!marqueMachineList) {
      return;
    }
    setUpToDateMarqueMachineList(marqueMachineList);
  }, [marqueMachineList]);

  useEffect(() => {
    if (!secteursList) {
      return;
    }
    setUpToDateSecteursList(secteursList);
  }, [secteursList]);

  useEffect(() => {
    if (!fonctionsList) {
      return;
    }
    setUpToDateFonctionsList(fonctionsList);
  }, [fonctionsList]);

  useEffect(() => {
    if (!paysList) {
      return;
    }
    setUpToDatePaysList(paysList);
  }, [paysList]);

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: articleById,
    styles,
    t,
    upToDateAgressionsList,
    upToDateTypeMachineList,
    upToDateMarqueMachineList,
    upToDateFonctionsList,
    upToDateSecteursList,
    upToDatePaysList,
    upToDateArticleByIdList,
    articleId,
    familleArticleList,
    isMobileFormat,
    isArticleByIdSet,
    articleById,
  };

  const actionUsedForRender = {
    dispatch,
    setArticleId,
    setUpToDateArticleByIdList,
    setReferenceToDelete,
    setIsArticleByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isArticleByIdSet ? (
    <StyledDivWrapperVues>
      <div>
        <StyledH1VuesEC>{t.titleSideBarArticles}</StyledH1VuesEC>
        <StyledHrEspaceClientRose></StyledHrEspaceClientRose>
        <StyledP>{t.descrVueArticle}</StyledP>
      </div>
      <div>
        {displayError()}
        <ListeArticle
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        {controllerDisplayResumeArticleEC(
          stateUsedForRender,
          actionUsedForRender,
          ARTICLE
        )}
      </div>
    </ErrorBoundary>
  );
};
