import React, { useEffect, useState } from "react";

import { useAuthentification } from '../../../../redux/vue/components/hook/useAuthentification.js';
import { StyledH1VuesEC, StyledP } from "../../styledComponents/StyledTextEspaceClient";
import { StyledDivWrapperVues, StyledHrEspaceClient } from "../../styledComponents/StyledDivEspaceClient";
import { StyledDivMsgEchec, StyledDivMsgSucces } from "../../styledComponents/StyledDivBackoffice";
import Check from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  getAllContacts,
  getOneContact,
} from "../../../slice/EspaceClientReducer";
import ErrorBoundary from "../../components/ErrorBoundary";
import { ListeContact } from "./ListeContact";
import { DetailsContact } from "./DetailsContact";

export const VueContact = () => {
  const dispatch = useDispatch();
  const { clientId } = useAuthentification();
  const [params] = useSearchParams();

  const { message } = useSelector((state) => state.message);

  //SERVICES
  const [isContactByIdSet, setIsContactByIdSet] = useState(false);

  const { detailsContact } = useSelector((state) => state.espaceClient);

  const [
    styles,
    t,
    booleanIdentite,
    upToDateArticleByIdList,
    setUpToDateArticleByIdList,
    upToDateOffreByIdList,
    setUpToDateOffreByIdList,
  ] = useOutletContext();

  useEffect(() => {
    const contactId = params.get("contact");

    if (!contactId) {
      setIsContactByIdSet(false);
      return;
    }

    dispatch(getOneContact({ clientId, contactId }));
  }, [dispatch, params]);

  useEffect(() => {
    if (!detailsContact) {
      setIsContactByIdSet(false);
      return;
    }
    setIsContactByIdSet(true);
  }, [detailsContact]);

  useEffect(() => {
    if (clientId !== null) {
      dispatch(getAllContacts({ clientId }));
    }
  }, [dispatch]);

  useEffect(() => {
    const paramCommande = params.get("NC");

    if (!paramCommande) {
      setIsContactByIdSet(false);
    }
  }, []);

  const stateUsedForRender = {
    booleanIdentite: false,
    formik: upToDateOffreByIdList,
    styles,
    t,
    detailsContact,
    isContactByIdSet,
  };

  const actionUsedForRender = {
    dispatch,
    setUpToDateOffreByIdList,
    setIsContactByIdSet,
  };

  const displayError = () => {
    if (message && !message.code) {
      return (
        <StyledDivMsgSucces>
          <Check sx={{ color: "#5FA36E", marginRight: "10px" }} />
          {message}
        </StyledDivMsgSucces>
      );
    }
    if (message && message?.code !== 200) {
      return (
        <StyledDivMsgEchec>
          <CloseIcon sx={{ color: "red", marginRight: "10px" }} />
          {message.message}
        </StyledDivMsgEchec>
      );
    }
  };

  return !isContactByIdSet ? (
    <StyledDivWrapperVues>
      <StyledH1VuesEC>{t.titleSideBarContact}</StyledH1VuesEC>
      <StyledHrEspaceClient></StyledHrEspaceClient>
      <StyledP>{t.descrVueContact}</StyledP>

      <div>
        {displayError()}
        <ListeContact state={stateUsedForRender} actions={actionUsedForRender} />
      </div>
    </StyledDivWrapperVues>
  ) : (
    <ErrorBoundary>
      <div style={{ width: "100%" }}>
        <DetailsContact
          t={t}
          state={stateUsedForRender}
          actions={actionUsedForRender}
        />
      </div>
    </ErrorBoundary>
  );
};
