import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { LocalStorage } from "../../constantes/globalName/LocalStorage";
import { AuthentificationActionType } from "../../constantes/actionType/AuthentificationActionType";
import authentificationService from "../service/AuthentificationService";

const accessToken = authentificationService.getAccessToken();
const accessTokenEC = authentificationService.getAccessTokenEC();
const clientId = authentificationService.getClientId();

const connexionEC = createAsyncThunk(
  AuthentificationActionType.CONNEXION_EC,
  async ({ clientCode, clientPassword }, thunkAPI) => {
    try {
      const { clientId, token } = await authentificationService.connexionEC(clientCode, clientPassword);
      thunkAPI.dispatch(setMessage(null));
      return { clientId, token };
    } catch (error) {
      setMessage({ message: "Accès non autorisé", type: "error" })
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const connexion = createAsyncThunk(
  AuthentificationActionType.CONNEXION,
  async ({ email, motDePasse }, thunkAPI) => {
    try {
      const response = await authentificationService.connexion( email, motDePasse);
      const token = JSON.parse(atob(response.data.accessToken.split(".")[1]));
      thunkAPI.dispatch(setMessage(null));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const rafraichissement = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT,
  async (thunkAPI) => {
    try {
      const accessToken = { [LocalStorage.ACCESS_TOKEN]: localStorage.getItem( LocalStorage.ACCESS_TOKEN) };
      const response = await authentificationService.rafraichissement( accessToken);
      const token = JSON.parse(atob(response.data.accessToken.split(".")[1]));
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const rafraichissementEC = createAsyncThunk(
  AuthentificationActionType.RAFRAICHISSEMENT_EC,
  async (thunkAPI) => {
    try {
      const { token } = await authentificationService.rafraichissementEC();
      return { token };
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

const deconnexion = createAsyncThunk(
  AuthentificationActionType.DECONNEXION,
  async () => {
    authentificationService.deconnexion();
  }
);

const deconnexionEC = createAsyncThunk(
  AuthentificationActionType.DECONNEXION_EC,
  async () => {
    authentificationService.deconnexionEC();
  }
);

const updateTokenEC = createAsyncThunk(
  AuthentificationActionType.UPDATE_TOKEN_EC,
  async ({ accessTokenEC }) => {
    const { token } = authentificationService.updateTokenEC(accessTokenEC);
    return { token };
  }
);


const initialState = {
  isLoggedIn: !!(accessToken || accessTokenEC),
  accessToken: accessToken,
  accessTokenEC: accessTokenEC,
  clientId : clientId,
};

const authentificationSlice = createSlice({
  name: AuthentificationActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      // thunk connexion
      .addCase(connexion.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(connexion.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      // thunk connexionEC
      .addCase(connexionEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.clientId = action.payload.clientId;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(connexionEC.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk rafraichissement
      .addCase(rafraichissement.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessToken = action.payload.token;
      })
      .addCase(rafraichissement.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.accessToken = null;
      })
      // thunk rafraichissementEC
      .addCase(rafraichissementEC.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.accessTokenEC = action.payload.token;
      })
      .addCase(rafraichissementEC.rejected, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk deconnexion
      .addCase(deconnexion.fulfilled, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessToken = null;
      })
      // thunk deconnexionEC
      .addCase(deconnexionEC.fulfilled, (state, _) => {
        state.isLoggedIn = false;
        state.clientId = null;
        state.accessTokenEC = null;
      })
      // thunk updateTokenEC
      .addCase(updateTokenEC.fulfilled, (state, action) => {
        state.accessTokenEC = action.payload.token;
      });
  },
});

const { reducer } = authentificationSlice;

export {
  connexion, deconnexion, rafraichissement,
  connexionEC, deconnexionEC, rafraichissementEC, updateTokenEC
};
export default reducer;
