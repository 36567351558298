import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./MessageReducer";
import { errorHandlerMessageProvider } from "../../helper/ErrorHandlerMessageProvider";
import { EspaceClientActionType } from "../../constantes/actionType/EspaceClientActionType";
import espaceClientService from "../service/EspaceClientService";

export const getAllArticlesEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_ARTICLES,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllArticles({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOneArticleEC = createAsyncThunk(
  EspaceClientActionType.GET_ONE_ARTICLE,
  async ({ clientId, articleId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getOneArticleEC({
        clientId,
        articleId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllCommandesEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_COMMANDES,
  async ({ clientId, contactId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllCommandes({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllCommandesByArticleIdEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_COMMANDES_BY_ARTICLE_ID,
  async ({ clientId, articleId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllCommandesByArticleId({
        clientId,
        articleId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getTypesCommandeEC = createAsyncThunk(
  EspaceClientActionType.GET_TYPES_COMMANDE,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getTypesCommande({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOneCommandeEC = createAsyncThunk(
  EspaceClientActionType.GET_ONE_COMMANDE,
  async ({ clientId, commandeId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getOneCommande({
        clientId,
        commandeId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllOffresEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_OFFRES,
  async ({ clientId, contactId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllOffres({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllOffresByArticleIdEC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_OFFRES_BY_ARTICLE_ID,
  async ({ clientId, articleId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllOffresByArticleId({
        clientId,
        articleId ,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOneOffreIdEC = createAsyncThunk(
  EspaceClientActionType.GET_ONE_OFFRE,
  async ({ clientId, offrePrixId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      // TODO erreur offreId attendu
      const json = await espaceClientService.getOneOffreId({
        clientId,
        offreId: offrePrixId,
        accessTokenEC
      });
      return json
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllContacts = createAsyncThunk(
  EspaceClientActionType.GET_ALL_CLIENTS,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllContacts({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getDetailsClient = createAsyncThunk(
  EspaceClientActionType.GET_DETAILS_CLIENT,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      // FIXME : renvoie message «Pas de numéro client»
      const json = await espaceClientService.getDetailsClient({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOneContact = createAsyncThunk(
  EspaceClientActionType.GET_ONE_CONTACT,
  async ({ clientId, contactId }, thunkAPI) => {
    if (contactId === null)
      return {};
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getOneContact({
        clientId,
        contactId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllNC = createAsyncThunk(
  EspaceClientActionType.GET_ALL_NC,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllNC({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getOneNC = createAsyncThunk(
  EspaceClientActionType.GET_ONE_NC,
  async ({ clientId, ncId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllNC({
        clientId,
        ncId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getEtatNC = createAsyncThunk(
  EspaceClientActionType.GET_ETAT_NC,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getEtatNC({
        clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const getAllFamilleArticle = createAsyncThunk(
  EspaceClientActionType.GET_ALL_FAMILLE_ARTICLE,
  async ({ clientId }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.getAllFamilleArticle({
        clientId: clientId,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendMailProgrammerVisite = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_PROGRAMMER_VISITE,
  async ({ contactById }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailProgrammerVisite({
        contactById,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendMailEtreRappeler = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_ETRE_RAPPELER,
  async ({ contactById }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailEtreRappeler({
        contactById: contactById,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendModifToFPS = createAsyncThunk(
  EspaceClientActionType.SEND_MAIL_MODIF_CONTACT,
  async ({ anciennesInfos, nouvellesInfos }, thunkAPI) => {
    try {
      const { authentification } = thunkAPI.getState();
      const accessTokenEC = authentification.accessTokenEC;
      if (accessTokenEC === null)
        throw new Error('Authentification nécessaire');
      const json = await espaceClientService.sendMailModifContact({
        anciennesInfos,
        nouvellesInfos,
        accessTokenEC
      });
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);

export const sendStatsToFPS = createAsyncThunk(
  EspaceClientActionType.SEND_STATS_TO_FPS,
  async (thunkAPI) => {
    try {
      const json = await espaceClientService.sendStatsToFPS();
      return json;
    } catch (error) {
      return errorHandlerMessageProvider(error, thunkAPI);
    }
  }
);


const initialState = {
  articleList: [],
  articleById: {},
  commandeList: [],
  commandesByArtIdList: [],
  offreList: [],
  offreById: {},
  offreByArtIdList: [],
  contactList: [],
  detailsClient: {},
  detailsContact: {},
  typeCommandeList: [],
  commandeById: {},
  ncList: [],
  etatNCList: [],
  ncById: {},
  familleArticleList: [],
};

const espaceClientSlice = createSlice({
  name: EspaceClientActionType.END_POINT,
  initialState,
  extraReducers: (builder) => {
    builder
      // thunk getAllArticlesEC
      .addCase(getAllArticlesEC.fulfilled, (state, action) => {
        state.articleList = action.payload;
      })
      .addCase(getAllArticlesEC.rejected, (state, _) => {
        state.articleList = [];
      })
      // thunk getOneArticleEC
      .addCase(getOneArticleEC.fulfilled, (state, action) => {
        state.articleById = action.payload;
      })
      .addCase(getOneArticleEC.rejected, (state, _) => {
        state.articleById = {};
      })
      // thunk getAllCommandesEC
      .addCase(getAllCommandesEC.fulfilled, (state, action) => {
        state.commandeList = action.payload;
      })
      .addCase(getAllCommandesEC.rejected, (state, _) => {
        state.commandeList = [];
      })
      // thunk getAllCommandesByArticleIdEC
      .addCase(getAllCommandesByArticleIdEC.fulfilled, (state, action) => {
        state.commandesByArtIdList = action.payload;
      })
      .addCase(getAllCommandesByArticleIdEC.rejected, (state, _) => {
        state.commandesByArtIdList = [];
      })
      // thunk getTypesCommandeEC
      .addCase(getTypesCommandeEC.fulfilled, (state, action) => {
        state.typeCommandeList = action.payload;
      })
      .addCase(getTypesCommandeEC.rejected, (state, _) => {
        state.typeCommandeList = [];
      })
      // thunk getOneCommandeEC
      .addCase(getOneCommandeEC.fulfilled, (state, action) => {
        state.commandeById = action.payload;
      })
      .addCase(getOneCommandeEC.rejected, (state, _) => {
        state.commandeById = {};
      })
      // thunk getAllOffresEC
      .addCase(getAllOffresEC.fulfilled, (state, action) => {
        state.offreList = action.payload;
      })
      .addCase(getAllOffresEC.rejected, (state, _) => {
        state.offreList = [];
      })
      // thunk getOneOffreIdEC
      .addCase(getOneOffreIdEC.fulfilled, (state, action) => {
        state.offreById = action.payload;
      })
      .addCase(getOneOffreIdEC.rejected, (state, _) => {
        state.offreById = {};
      })
      // thunk getAllOffresByArticleIdEC
      .addCase(getAllOffresByArticleIdEC.fulfilled, (state, action) => {
        state.offreByArtIdList = action.payload;
      })
      .addCase(getAllOffresByArticleIdEC.rejected, (state, _) => {
        state.offreByArtIdList = [];
      })
      // thunk getAllContacts
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.contactList = action.payload;
      })
      .addCase(getAllContacts.rejected, (state, _) => {
        state.contactList = [];
      })
      // thunk getDetailsClient
      .addCase(getDetailsClient.fulfilled, (state, action) => {
        state.detailsClient = action.payload;
      })
      .addCase(getDetailsClient.rejected, (state, _) => {
        state.detailsClient = {};
      })
      // thunk getOneContact
      .addCase(getOneContact.fulfilled, (state, action) => {
        // FIXME bizarre : stocke aussi dans state.detailsClient comme getDetailsClient
        state.detailsContact = action.payload;
      })
      .addCase(getOneContact.rejected, (state, _) => {
        state.detailsContact = {};
      })
      // thunk getAllNC
      .addCase(getAllNC.fulfilled, (state, action) => {
        state.ncList = action.payload;
      })
      .addCase(getAllNC.rejected, (state, _) => {
        state.ncList = [];
      })
      // thunk getEtatNC
      .addCase(getEtatNC.fulfilled, (state, action) => {
        state.etatNCList = action.payload;
      })
      .addCase(getEtatNC.rejected, (state, _) => {
        state.etatNCList = [];
      })
      // thunk getAllFamilleArticle
      .addCase(getAllFamilleArticle.fulfilled, (state, action) => {
        state.familleArticleList = action.payload;
      })
      .addCase(getAllFamilleArticle.rejected, (state, _) => {
        state.familleArticleList = [];
      })
      // thunk getOneNC
      .addCase(getOneNC.fulfilled, (state, action) => {
        state.ncById = action.payload;
      })
      .addCase(getOneNC.rejected, (state, _) => {
        state.ncById = {};
      })
      // thunk sendMailProgrammerVisite
      .addCase(sendMailProgrammerVisite.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendMailProgrammerVisite.rejected, (_, action) => {
        setMessage(action.payload);
      })
      // thunk sendMailEtreRappeler
      .addCase(sendMailEtreRappeler.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendMailEtreRappeler.rejected, (_, action) => {
        setMessage(action.payload);
      })
      // thunk sendModifToFPS
      .addCase(sendModifToFPS.fulfilled, (_, action) => {
        setMessage(action.payload);
      })
      .addCase(sendModifToFPS.rejected, (_, action) => {
        setMessage(action.payload);
      })
  }
});

const { reducer } = espaceClientSlice;
export default reducer;
